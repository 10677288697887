export default {
    mount: '.icon-five-hearted-enjoyment',
    attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 127.41 28.24',
    },
    path: `
    <path fill="#909190" d="M113.29,28.24H14.12A14.16,14.16,0,0,1,0,14.12H0A14.16,14.16,0,0,1,14.12,0h99.17a14.16,14.16,0,0,1,14.12,14.12h0A14.16,14.16,0,0,1,113.29,28.24Z"/>
    <path fill="#fff" d="M28.32,7.24v.84H21.07l-.92,4.62h5.93v8h3.16v.84H12.88v-.84H17.6L19,13.54H14.93V12.7h4.25l.92-4.62H13.8V7.24ZM25.09,20.71V13.54H20l-1.4,7.17Z"/>
    <path fill="#fff" d="M44.59,12.29a20.1,20.1,0,0,1-2.52,6.59l-.77-.49a21.77,21.77,0,0,0,2.44-6.52Zm2.46-2.18v9.68c0,.52.09.81.3.85a15.28,15.28,0,0,0,4.26.29,1.42,1.42,0,0,0,1.4-.67,17.3,17.3,0,0,0,.48-4l.85.34a13.51,13.51,0,0,1-.67,4.22c-.25.6-1,.9-2.06.9-2.86,0-4.49-.12-4.91-.38a1.06,1.06,0,0,1-.57-1.09V10.11Zm5.19.49-.63.77a31.25,31.25,0,0,0-4.42-4.69L47.75,6A25.88,25.88,0,0,1,52.24,10.6Zm5.6,7.86-1,.42a33.81,33.81,0,0,0-2.53-6.52l.77-.49A38.38,38.38,0,0,1,57.84,18.46Z"/>
    <path fill="#fff" d="M83.65,14.53v.7a26.5,26.5,0,0,1-5.47,2.21,3.12,3.12,0,0,0,.1.32H86v.78H78.52a8.32,8.32,0,0,1,.21,1.81c0,1.31-.5,2-1.47,2A10.74,10.74,0,0,1,74.67,22l-.14-1a9.22,9.22,0,0,0,2.73.5c.43,0,.65-.38.65-1.13a7.59,7.59,0,0,0-.22-1.81H69.9v-.78h7.56a11.54,11.54,0,0,0-.61-1.27l.7-.27c.11.18.21.36.3.52A24.55,24.55,0,0,0,82,15.23H72.14v-.7ZM79,7.58,78.52,8h7.24v.77H70.18V8H78A16.85,16.85,0,0,0,76.29,6.4l.7-.56A19.14,19.14,0,0,1,79,7.58ZM83.1,9.83v3.52H72.84V9.83Zm-.85,2.82V10.53H73.69v2.12Z"/>
    <path fill="#fff" d="M111,14.6A14.6,14.6,0,0,1,107.3,19s0,.05.07.05a22.41,22.41,0,0,0,7.16,2.48l-.5.92a22.38,22.38,0,0,1-7-2.68,1.87,1.87,0,0,1-.39-.25c-.15.11-.29.22-.44.31a22.71,22.71,0,0,1-7.7,2.62l-.36-.85a22.82,22.82,0,0,0,7.06-2.22c.21-.13.45-.27.66-.42a12,12,0,0,1-3.25-3.53h-1.38V14.6Zm2.75.34h-.84V12.43H99.52v2.51h-.84V11.66h15.08ZM112.7,6.61A83.49,83.49,0,0,1,99.52,7.87L99.3,7.1a68.38,68.38,0,0,0,13-1.26Zm-9.61,4.28-.7.48a11.25,11.25,0,0,0-1.76-2.66l.63-.43A12.77,12.77,0,0,1,103.09,10.89Zm3.4,7.56a15.21,15.21,0,0,0,3-3.07h-6A10.06,10.06,0,0,0,106.49,18.45ZM107,11l-.78.2a14.15,14.15,0,0,0-.91-3L106,8A16.21,16.21,0,0,1,107,11Zm5.19-3.16a12.6,12.6,0,0,1-2.12,3.43l-.77-.41a12.24,12.24,0,0,0,2-3.31Z"/>
    `
}