export default {
    mount: '.icon-dog-ball',
    attrs: {
        viewBox: '0 0 39.01 30.72'
    },
    path: `
    <path fill="#231f20" opacity="0.2" d="M36.16,27.23c0,1.44-5,2.62-11.26,2.62s-11.25-1.18-11.25-2.62,5-2.62,11.25-2.62S36.16,25.78,36.16,27.23Z"/>
    <path fill="#231f20" opacity="0.2" d="M12.16,29.49c0,.68-2.32,1.23-5.19,1.23s-5.19-.55-5.19-1.23S4.1,28.25,7,28.25,12.16,28.8,12.16,29.49Z"/>
    <path fill="#c93c21" d="M11.45,8.58a15.37,15.37,0,0,1,9.39-8A14.45,14.45,0,0,1,33.9,3.17a14.27,14.27,0,0,1,5,12.77C37.39,26.1,29.45,29.56,20.45,27.61A13.6,13.6,0,0,1,11,19.53,13.5,13.5,0,0,1,11.45,8.58Z"/>
    <path fill="#ee5b34" d="M37.87,8.55a14.11,14.11,0,0,0-4-5.38A14.45,14.45,0,0,0,20.84.56a15.37,15.37,0,0,0-9.39,8,13.61,13.61,0,0,0-1.09,9,18.51,18.51,0,0,0,5,1.56C26.15,20.9,35.72,17.74,37.87,8.55Z"/>
    <path fill="#fff" d="M22.82,3.64c.31.87-.47,2-1.76,2.42s-2.58.13-2.9-.75.48-2,1.76-2.42S22.5,2.76,22.82,3.64Z"/>
    <path fill="#fff" d="M10.11,17.28c-4-1.82-8-1-9.71,3.54a6.7,6.7,0,0,0,.15,4.94,9.41,9.41,0,0,0,7.4.95,8.91,8.91,0,0,0,5.43-4.34q.15-.3.27-.6A6.44,6.44,0,0,0,10.11,17.28Z"/>
    <path fill="#c8c0bf" d="M12.35,27A7.27,7.27,0,0,1,7.2,29.76a6.87,6.87,0,0,1-5.79-2.53,6.78,6.78,0,0,1-1-6.41c1.71-4.55,5.73-5.36,9.71-3.54A6.3,6.3,0,0,1,12.35,27Z"/>
    <path fill="#45484c" d="M13.25,20.51c-6.11,2.65-6.69,7.24-6.61,9.26H7.1c-.07-1.89.45-6.26,6.32-8.82A3.2,3.2,0,0,0,13.25,20.51Z"/>
    <path fill="#45484c" d="M4.78,23.69A8.09,8.09,0,0,1,.57,25.81c.07.16.15.32.23.47a8.44,8.44,0,0,0,4.32-2.23,11,11,0,0,0,3-7.46l-.49-.1C7.41,19.63,6.46,22.05,4.78,23.69Z"/>
    `
}