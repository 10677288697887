export default {
  mount: '.icon-healthy-things',
  attrs: {
  viewBox: '0 0 139.79 139.79' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="69.9" cy="69.9" r="69.9"/>
    <path fill="#ffffff" d="M106.34,67.4c-.18.88-.35,1.78-.54,2.66A25.9,25.9,0,0,1,101,80.41a73.62,73.62,0,0,1-11,11.4,144.6,144.6,0,0,1-15.47,11.51,2.28,2.28,0,0,1-2.76,0c-3-2.12-6-4.2-9-6.37-2.07-1.53-4.05-3.19-6.06-4.8a2.18,2.18,0,1,1,2.71-3.4c2.71,2.13,5.39,4.3,8.13,6.39,1.72,1.3,3.53,2.46,5.29,3.7.31.22.51.12.76-.06,3.48-2.56,7-5.08,10.43-7.69a81.53,81.53,0,0,0,9.08-8.15,38.29,38.29,0,0,0,6.38-8.24,27.22,27.22,0,0,0,1.64-4,14.58,14.58,0,0,0,.72-6.65,15,15,0,0,0-4-8.76,14.43,14.43,0,0,0-8.36-4.55,15.57,15.57,0,0,0-12.24,3,12.67,12.67,0,0,0-2.07,1.94,2.4,2.4,0,0,1-3.89,0,15.18,15.18,0,0,0-11.58-5.2,14.19,14.19,0,0,0-8.54,2.58,15.09,15.09,0,0,0-6.66,11,17.89,17.89,0,0,0,2.17,10.2.71.71,0,0,0,.75.46c3.86,0,7.72,0,11.58,0a.76.76,0,0,0,.81-.55c1.31-3.06,2.65-6.12,4-9.17a2.27,2.27,0,0,1,1.83-1.59A2.18,2.18,0,0,1,68,65.32L71,77.77c.06.23.13.45.24.8L73,75.75c.75-1.22,1.51-2.44,2.24-3.67a2.43,2.43,0,0,1,2.3-1.29c2.46,0,4.93,0,7.39,0a2.15,2.15,0,0,1,2.25,2.77,2.18,2.18,0,0,1-2.21,1.54c-1.31,0-2.63,0-4,0a7.08,7.08,0,0,1-1.22,0,1.81,1.81,0,0,0-2.06,1.13c-1.73,3-3.55,5.91-5.34,8.86-.85,1.42-2.29,1.81-3.4.78a3.73,3.73,0,0,1-.89-1.76c-.94-3.82-1.82-7.65-2.76-11.64-.17.33-.29.53-.39.75-.6,1.38-1.23,2.76-1.79,4.16a2.47,2.47,0,0,1-2.57,1.67c-5.61,0-11.21,0-16.82,0-2.46,0-4.93-.05-7.39,0a2.66,2.66,0,0,1-2.88-1.65v-1a2.62,2.62,0,0,1,2.88-1.64c1.65.07,3.3,0,4.94,0l.67,0c-.07-.22-.11-.37-.16-.51-.41-1.35-.9-2.68-1.23-4.06A19.61,19.61,0,0,1,47.84,50a18.72,18.72,0,0,1,10.77-3.87,19.82,19.82,0,0,1,12.64,3.68c.66.46,1.29,1,2,1.48a3.39,3.39,0,0,0,.28-.2A19.67,19.67,0,0,1,105.8,61.29c.23.93.36,1.9.54,2.85Z"/>
  `  
}