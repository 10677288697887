export default {
  mount: '.icon-bar-line',
  attrs: {
  viewBox: '0 0 109.45 109.45' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="54.72" cy="54.72" r="54.72"/>
    <path fill="#fff" d="M87.48,53.18c0-14.66-14.69-26.58-32.75-26.58S22,38.53,22,53.18C22,66.32,33.62,77.32,49.36,79.4c1.07.23,2.52.7,2.89,1.62a6.73,6.73,0,0,1,.11,3l-.47,2.8c-.14.83-.66,3.24,2.84,1.77s18.86-11.11,25.73-19h0c4.75-5.21,7-10.49,7-16.35"/>
    <path d="M48.07,46.1h-2.3a.64.64,0,0,0-.64.64V61a.64.64,0,0,0,.64.64h2.3a.64.64,0,0,0,.64-.64V46.74a.64.64,0,0,0-.64-.64"/>
    <path d="M63.88,46.1h-2.3a.64.64,0,0,0-.64.64v8.48l-6.54-8.83,0-.06h0l0,0h0l0,0h0l0,0h0l0,0h0l0,0H51.58a.64.64,0,0,0-.64.64V61a.64.64,0,0,0,.64.64h2.3a.64.64,0,0,0,.64-.64V52.53l6.55,8.84a.63.63,0,0,0,.16.16h0l0,0h.15l.16,0h2.3a.64.64,0,0,0,.64-.64V46.74a.64.64,0,0,0-.64-.64"/>
    <path d="M42.53,58.07H36.29V46.74a.64.64,0,0,0-.64-.64h-2.3a.64.64,0,0,0-.64.64V61h0a.63.63,0,0,0,.18.44h0a.63.63,0,0,0,.44.18h9.18a.64.64,0,0,0,.64-.64v-2.3a.64.64,0,0,0-.64-.64"/>
    <path d="M76.57,49.67a.64.64,0,0,0,.64-.64v-2.3a.64.64,0,0,0-.64-.64H67.39a.63.63,0,0,0-.44.18h0a.63.63,0,0,0-.18.44h0V61h0a.63.63,0,0,0,.18.44h0a.63.63,0,0,0,.44.18h9.18a.64.64,0,0,0,.64-.64v-2.3a.64.64,0,0,0-.64-.64H70.33V55.66h6.24a.64.64,0,0,0,.64-.64v-2.3a.64.64,0,0,0-.64-.64H70.33V49.67Z"/>
  `  
}