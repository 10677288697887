export default {
  mount: '.icon-package-send',
  attrs: {
  viewBox: '0 0 88.98 88' ,
  fill: 'currentColor'
  },
  path: `
    <path d="M86.43,22.48Q72.16,16.27,57.92,10a3.17,3.17,0,0,0-2.74,0Q41,16.23,26.83,22.4c-2.56,1.12-2.82.76-2.8,4.24q.07,17.78,0,35.53a2.84,2.84,0,0,0,2,3c9.88,4.27,19.73,8.61,29.58,12.94a2.31,2.31,0,0,0,2-.07C68,73.3,78.71,69.12,89,64.06V23.79A18.59,18.59,0,0,0,86.43,22.48ZM46.88,17.81q4.5-2,9-3.95a1.38,1.38,0,0,1,1.19-.06q12.27,5.4,24.56,10.77a5.71,5.71,0,0,1,.55.31l-1,.49c-2.92,1.28-5.85,2.55-8.76,3.84a1.42,1.42,0,0,1-1.27.07L46.83,18.55a5.39,5.39,0,0,1-.52-.3C46.41,17.93,46.67,17.9,46.88,17.81Zm7.74,38.63q0,8,0,16c0,1,0,1-.9.63q-12.52-5.49-25.07-11A1.05,1.05,0,0,1,27.89,61q0-16.11,0-32.22c0-1,0-1,.95-.57q12.49,5.46,25,10.92a1.12,1.12,0,0,1,.77,1.21C54.61,45.71,54.62,51.07,54.62,56.44ZM57.2,35.86a1.42,1.42,0,0,1-1.27,0Q43.83,30.57,31.7,25.28c-.21-.1-.42-.22-.63-.33.11-.42.49-.44.76-.56,2.87-1.26,5.74-2.5,8.6-3.77a1.79,1.79,0,0,1,1.61,0Q54,25.89,65.91,31.15c.25.12.57.16.72.45-.12.26-.38.29-.59.38ZM84.33,62.18Q71.86,67.6,59.42,73.06c-1,.42-1,.41-1-.62q0-16,0-32.11a1.07,1.07,0,0,1,.72-1.16q5-2.13,9.91-4.34c.76-.34.85-.07.84.6q0,4.11,0,8.23a1.92,1.92,0,0,0,1.78,2.17,1.88,1.88,0,0,0,2-2.06c0-2.75.17-5.5,0-8.23-.15-2.1.48-3.18,2.52-3.89,2.75-.95,5.37-2.29,8-3.45.9-.4.93-.38.94.61V44.92c0,5.3,0,10.61,0,15.92A1.22,1.22,0,0,1,84.33,62.18Z"/>
    <path d="M18.88,53.55Q11.5,50.13,4.11,46.74a1.92,1.92,0,0,0-2.75.79,2,2,0,0,0,1.17,2.68Q9.87,53.61,17.21,57a2.24,2.24,0,0,0,1.06.3,1.9,1.9,0,0,0,1.93-1.48A2,2,0,0,0,18.88,53.55Z"/>
    <path d="M5.08,38q6.15,2.87,12.32,5.7a2.09,2.09,0,0,0,1.21.22,1.89,1.89,0,0,0,1.6-1.58,1.77,1.77,0,0,0-1-2c-4.23-2-8.48-3.92-12.72-5.87A2.06,2.06,0,0,0,6,34.35a1.91,1.91,0,0,0-2.05,1.41A1.87,1.87,0,0,0,5.08,38Z"/>
    <path d="M18.93,26.86c-3.21-1.51-6.44-3-9.66-4.47a2.06,2.06,0,0,0-.9-.24,1.87,1.87,0,0,0-1.91,1.38,1.8,1.8,0,0,0,1,2.19c3.4,1.6,6.81,3.16,10.23,4.72a1.74,1.74,0,0,0,2.11-.51A1.89,1.89,0,0,0,18.93,26.86Z"/>
  `  
}