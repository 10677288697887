export default {
    mount: '.icon-dog-type',
    attrs: {
        viewBox: '0 0 70.01 58.12',
        fill: 'currentColor'
    },
    path: `
    <path d="M28.33,48.35l0-.49.68-.67a10.75,10.75,0,0,1-1.18-1.69c-1.24-2-2.93-4.78-6.78-5.11l-.6,0c-9.41-.77-11.55-1.59-12-2-1.93-1.84-7.4-8.87-6.05-11.74.59-.5,3.47-1.26,5.22-1.72a35.41,35.41,0,0,0,4.95-1.54A7.11,7.11,0,0,0,16.13,20a3,3,0,0,1,.24-.41l.23-.34A8.83,8.83,0,0,1,23,14.89a16.28,16.28,0,0,1,5.42.08l-.54.95a1.11,1.11,0,0,0,.41,1.52A1.13,1.13,0,0,0,29.83,17C31,15,35.26,7.59,37.1,4.41c.93-1.6,1.74-2.18,2.2-2.18h0c.44,0,1,.61,1.39,1.57C42.19,7.14,45.61,18,42.15,24a1.1,1.1,0,0,0,.41,1.52,1.08,1.08,0,0,0,.55.15,1.1,1.1,0,0,0,1-.56,12.78,12.78,0,0,0,1.31-3.3c2.87,3.42,5.81,8.62,5.81,10.53a1.12,1.12,0,1,0,2.23,0c0-3.2-4.59-10.3-7.57-13.33,0,0,0,0,0,0,.62-7.61-3-16-3.06-16.09C41.74.6,40.4.06,39.45,0c-1.53-.11-3,1-4.28,3.28L29.6,12.93a18.21,18.21,0,0,0-6.89-.25,11.05,11.05,0,0,0-8,5.41l-.14.21c-.13.18-.26.4-.41.66a4.8,4.8,0,0,1-2.52,2.34,34.23,34.23,0,0,1-4.57,1.41c-4.36,1.15-6.23,1.76-6.69,3-2.32,5.06,6.45,14.18,6.54,14.27,1.24,1.19,5.11,2,13.36,2.62l.59,0c2.72.24,3.92,2.18,5.07,4.06a12.29,12.29,0,0,0,1.49,2.09,1.13,1.13,0,0,0,.79.33,1.27,1.27,0,0,0,.28,0Z"/>
    <path d="M21.32,10.83c.17-3,1.94-7.32,3.47-7.44.63-.08,2.31,1.92,3.4,4.06a1.11,1.11,0,1,0,2-1c-.65-1.3-3-5.49-5.58-5.29-3.49.29-5.34,6.46-5.51,9.54a1.12,1.12,0,0,0,1.05,1.18h.06A1.13,1.13,0,0,0,21.32,10.83Z"/>
    <path d="M51.66,52.94A4.83,4.83,0,0,0,50.38,50c2-2,8.74-8.82,11.5-11.52a4.83,4.83,0,0,0,6.72-.12,4.83,4.83,0,0,0-3.33-8.25,4.84,4.84,0,1,0-8.39,3.37C54,36.41,48.29,42.13,45.37,45A4.83,4.83,0,1,0,42,53.37a4.84,4.84,0,1,0,9.66-.43Zm-7.2,2.7a3.36,3.36,0,0,1-.93-2.94l.19-1.05-1,.19a3.36,3.36,0,0,1-3.93-3.3,3.3,3.3,0,0,1,1-2.37,3.33,3.33,0,0,1,2.59-1,3.29,3.29,0,0,1,2.43,1.31l.51.66.6-.58c2.86-2.78,9.83-9.74,12.63-12.63l.58-.6-.66-.51a3.24,3.24,0,0,1-1.31-2.43,3.35,3.35,0,1,1,6.63.36l-.18,1,1-.18a3.36,3.36,0,0,1,2.94.92,3.32,3.32,0,0,1,1,2.37,3.34,3.34,0,0,1-3.58,3.34A3.25,3.25,0,0,1,62.51,37L62,36.28l-.6.59c-2.63,2.54-12.53,12.52-12.63,12.62l-.6.6.68.52A3.25,3.25,0,0,1,50.17,53a3.34,3.34,0,0,1-5.71,2.6Z"/>
    `
}