export default {
    mount: '.icon-atm',
    attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 84.77 55.48',
        fill: 'currentColor',
    },
    path: `
    <path d="M79.44,0H5.33A5.35,5.35,0,0,0,0,5.33V50.15a5.35,5.35,0,0,0,5.33,5.33H79.44a5.35,5.35,0,0,0,5.33-5.33V5.33A5.35,5.35,0,0,0,79.44,0ZM47.68,30.28a5.42,5.42,0,0,1-5.42,5.42H11.87a5.42,5.42,0,0,1-5.42-5.42V11a5.42,5.42,0,0,1,5.42-5.42H42.26A5.42,5.42,0,0,1,47.68,11ZM59.58,41a.67.67,0,0,1-.66.66H55.14a.67.67,0,0,1-.66-.66V37.78a.67.67,0,0,1,.66-.67h3.78a.67.67,0,0,1,.66.67Zm0-9.24a.67.67,0,0,1-.66.67H55.14a.67.67,0,0,1-.66-.67V28.54a.67.67,0,0,1,.66-.66h3.78a.67.67,0,0,1,.66.66Zm0-9.23a.67.67,0,0,1-.66.67H55.14a.67.67,0,0,1-.66-.67V19.31a.67.67,0,0,1,.66-.67h3.78a.67.67,0,0,1,.66.67ZM69,41a.67.67,0,0,1-.67.66H64.59a.67.67,0,0,1-.67-.66V37.78a.67.67,0,0,1,.67-.67h3.77a.67.67,0,0,1,.67.67Zm0-9.24a.67.67,0,0,1-.67.67H64.59a.67.67,0,0,1-.67-.67V28.54a.67.67,0,0,1,.67-.66h3.77a.67.67,0,0,1,.67.66Zm0-9.23a.67.67,0,0,1-.67.67H64.59a.67.67,0,0,1-.67-.67V19.31a.67.67,0,0,1,.67-.67h3.77a.67.67,0,0,1,.67.67ZM78.57,41a.67.67,0,0,1-.66.66H74.13a.67.67,0,0,1-.66-.66V37.78a.67.67,0,0,1,.66-.67h3.78a.67.67,0,0,1,.66.67Zm0-9.24a.67.67,0,0,1-.66.67H74.13a.67.67,0,0,1-.66-.67V28.54a.67.67,0,0,1,.66-.66h3.78a.67.67,0,0,1,.66.66Zm0-9.23a.67.67,0,0,1-.66.67H74.13a.67.67,0,0,1-.66-.67V19.31a.67.67,0,0,1,.66-.67h3.78a.67.67,0,0,1,.66.67Zm.07-11.68a.67.67,0,0,1-.66.67H55a.67.67,0,0,1-.67-.67V6.28A.67.67,0,0,1,55,5.62H78a.67.67,0,0,1,.66.66ZM44,47a.67.67,0,0,1-.67.67H10.68A.67.67,0,0,1,10,47V42.37a.67.67,0,0,1,.66-.67H43.33a.67.67,0,0,1,.67.67Z"/>
    <path d="M13.34,23.34l-.85,3.07H9.69l3.65-11.94h3.54l3.7,11.94h-2.9l-.93-3.07Zm3-2-.74-2.53c-.21-.71-.42-1.59-.6-2.3h0c-.17.71-.35,1.61-.55,2.3l-.71,2.53Z"/>
    <path d="M23,16.74H19.8V14.47H29v2.27H25.71v9.67H23Z"/>
    <path d="M40.43,21.84c-.06-1.44-.11-3.17-.11-4.91h0c-.38,1.52-.87,3.22-1.33,4.62l-1.45,4.66H35.38L34.1,21.59c-.39-1.4-.79-3.1-1.08-4.66h0c-.07,1.61-.13,3.45-.22,4.94l-.21,4.54H30.07l.76-11.94h3.59l1.17,4c.37,1.39.74,2.87,1,4.27h0c.34-1.38.75-3,1.14-4.28l1.27-4h3.53l.65,11.94H40.6Z"/>
    `
}