export default {
  mount: '.icon-new-shopping',
  attrs: {
  viewBox: '0 0 139.79 139.79' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="69.9" cy="69.9" r="69.9"/>
    <path fill="#ffffff" d="M47.53,89.2a3,3,0,0,1-.94,2.27,3.12,3.12,0,0,1-2.28.91,3.55,3.55,0,0,1-2.71-1.67L26.91,71.81V89.19A3.1,3.1,0,0,1,26,91.46a3.15,3.15,0,0,1-5.4-2.26V62.69a3.07,3.07,0,0,1,.94-2.27,3.15,3.15,0,0,1,2.3-.91,3.56,3.56,0,0,1,2.71,1.63L41.24,80V62.69a3.15,3.15,0,1,1,6.29,0Z"/>
    <path fill="#ffffff" d="M74.43,62.69a3,3,0,0,1-3.14,3.14H59.7v7h8.64a3.15,3.15,0,1,1,0,6.29H59.7v7H71.29a3.14,3.14,0,1,1,0,6.28l-14.71,0a3.21,3.21,0,0,1-3.14-3.14V62.69a3.21,3.21,0,0,1,3.14-3.14H71.29a3,3,0,0,1,3.14,3.14Z"/>
    <path fill="#ffffff" d="M122.54,62.59a3.41,3.41,0,0,1-.4,1.51l-13,26c-.77,1.55-1.8,2.32-3.08,2.32s-2.29-.77-3.06-2.32L99.47,83l-3.56,7.08c-.78,1.55-1.8,2.32-3.08,2.32s-2.29-.77-3.07-2.32l-13-26a3.3,3.3,0,0,1-.37-1.51,2.83,2.83,0,0,1,1-2.19,3.37,3.37,0,0,1,2.3-.89,2.86,2.86,0,0,1,2.69,1.77L92.83,82.16l3.11-6.23L90,64.1a3.3,3.3,0,0,1-.37-1.51,2.83,2.83,0,0,1,1-2.19,3.35,3.35,0,0,1,2.29-.89,2.85,2.85,0,0,1,2.69,1.77l3.84,7.61,3.8-7.61A2.87,2.87,0,0,1,106,59.51a3.37,3.37,0,0,1,2.3.89,2.83,2.83,0,0,1,1,2.19,3.28,3.28,0,0,1-.4,1.51L103,75.91l3.11,6.25,10.44-20.88a2.87,2.87,0,0,1,2.71-1.77,3.39,3.39,0,0,1,2.3.89A2.86,2.86,0,0,1,122.54,62.59Z"/>
  `  
}