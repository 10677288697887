export default {
    mount: '.icon-copy-round',
    attrs: {
        viewBox: '0 0 39.36 40.51',
        fill: 'currentColor'
    },
    path: `
    <path d="M38.34,13.78c-.19-.55-.4-1.11-.63-1.66A21.52,21.52,0,0,0,36.17,9.2,19.56,19.56,0,0,0,20.91,0l-.38,0-.77,0c-.38,0-.77,0-1.15,0a18,18,0,0,0-1.93.2,18.78,18.78,0,0,0-1.92.41c-.5.13-1,.28-1.48.46s-.95.36-1.42.56A20.22,20.22,0,0,0,9.19,3.11l-.32.21A22.15,22.15,0,0,0,7.06,4.7L6.78,5A20.59,20.59,0,0,0,4,8a21,21,0,0,0-2,3.3A20.58,20.58,0,0,0,.07,18.58L0,19c0,.57,0,1.14,0,1.7A20.33,20.33,0,0,0,3.22,31.36a19.47,19.47,0,0,0,5,5.41A20,20,0,0,0,12.1,39l.74.3.76.27c.5.17,1,.31,1.53.44l.79.17a18.3,18.3,0,0,0,4,.38,18.91,18.91,0,0,0,6-1,19.28,19.28,0,0,0,5.16-2.72l.31-.24c.31-.23.61-.48.9-.73a19.13,19.13,0,0,0,1.91-1.91q.63-.73,1.2-1.53c.08-.1.15-.21.22-.31a21,21,0,0,0,3.24-7.68c.11-.54.2-1.09.28-1.64a24.16,24.16,0,0,0,.19-3A19.43,19.43,0,0,0,38.34,13.78Zm-7,18.31a1.62,1.62,0,0,1-1.16.39H14.56A1.33,1.33,0,0,1,13,31V28.08H9.26a1.51,1.51,0,0,1-1.65-1.68v-1.2c0-.85,0-1.73,0-2.59V9.51A1.52,1.52,0,0,1,8,8.42,1.47,1.47,0,0,1,9.08,8h15.8A1.48,1.48,0,0,1,26,8.41a1.48,1.48,0,0,1,.37,1.1v1.11c0,.75,0,1.52,0,2.29a2.51,2.51,0,0,1,.44.38l.49.5c1.26,1.28,2.56,2.62,3.83,3.94a2.31,2.31,0,0,1,.52,1.1,5.83,5.83,0,0,1,.12,1.23c0,3.15,0,6.35,0,9.45v1.56A1.42,1.42,0,0,1,31.39,32.09Z"/><path class="cls-1" d="M29.22,23.3c0-1.1,0-2.2-.07-3.3,0-.31-.13-.48-.45-.47l-3.94.1a.74.74,0,0,1-.84-.82c0-1.08,0-2.15,0-3.23,0-.6-.21-.8-.83-.8l-7.18.05-.37,0v15H29.33v-.45Q29.29,26.37,29.22,23.3Z"/>
    <path d="M13,14.24c0-.76,1-1.33,1.7-1.33h9.15V10.64H10.07v15h3Z"/>
    `
}