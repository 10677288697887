export default {
    mount: '.icon-messenger',
    attrs: {
        viewBox: '0 0 109.45 109.45',
        fill: 'currentColor',
    },
    path: `
    <circle fill="#fff" cx="56.44" cy="54.23" r="39.85" transform="translate(-16.46 25.73) rotate(-22.5)"/>
    <path d="M54.73,0C23.89,0,0,22.58,0,53.08A51.92,51.92,0,0,0,17.19,92.35a4.4,4.4,0,0,1,1.47,3.12l.3,9.73a4.39,4.39,0,0,0,6.15,3.88L36,104.29a4.32,4.32,0,0,1,2.92-.22,59.82,59.82,0,0,0,15.85,2.1c30.83,0,54.72-22.59,54.72-53.09S85.56,0,54.73,0ZM87.58,40.84,71.51,66.34a8.21,8.21,0,0,1-11.87,2.19L46.85,58.94a3.29,3.29,0,0,0-4,0L25.63,72.06a2.6,2.6,0,0,1-3.76-3.46L37.94,43.1a8.21,8.21,0,0,1,11.87-2.19L62.6,50.5a3.29,3.29,0,0,0,3.95,0L83.82,37.38A2.6,2.6,0,0,1,87.58,40.84Z"/>
    `
}