export default {
  mount: '.icon-bar-cart',
  attrs: {
  viewBox: '0 0 109.45 109.45' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="54.72" cy="54.72" r="54.72"/>
    <path fill="#fff" d="M49.12,65.74l1,4.15c.09.35.21.7.27,1.05a.46.46,0,0,0,.47.42c.43,0,.86.06,1.3.06H79.74a2.89,2.89,0,0,1,.9.11,1.88,1.88,0,0,1,.89,3.06,1.76,1.76,0,0,1-1.22.62,4.77,4.77,0,0,1-.6,0H49.79a11.41,11.41,0,0,1-1.19-.08,1.44,1.44,0,0,1-1.29-1c-.26-.82-.48-1.66-.69-2.5q-.76-3-1.5-5.94Q44,61.31,43,56.93c-.49-2-1-3.92-1.47-5.88-.56-2.27-1.11-4.54-1.68-6.8q-1-4-2-8.06c-.08-.34-.11-.78-.33-1s-.65-.07-1-.07c-2,0-4,0-5.94,0a4.88,4.88,0,0,1-1.5-.29,1.52,1.52,0,0,1-1-1.5,1.58,1.58,0,0,1,.81-1.63,3,3,0,0,1,1.3-.36c3,0,5.91,0,8.86,0a1.72,1.72,0,0,1,1.8,1.29c.26.86.48,1.73.7,2.61s.49,2,.73,3a.73.73,0,0,0,.78.67c.92,0,1.85,0,2.77,0l11.35,0H70.7q8,0,16,0a4,4,0,0,1,1.34.21,1.7,1.7,0,0,1,1.1,2c-.29,1.32-.62,2.64-.95,4Q87,49.93,85.79,54.71t-2.4,9.48a1.86,1.86,0,0,1-1.93,1.55H49.12ZM84.8,42.89l-.14,0H77.49c-.21,0-.27.09-.29.29-.09.9-.2,1.79-.31,2.68-.19,1.52-.38,3-.58,4.57l.18,0h5.87a.63.63,0,0,0,.67-.53ZM51.91,50.41,51,42.9H43.42l.3,1.2,1.41,5.6c.16.62.26.7.88.71h5.89ZM62.15,42.9H54.82l.93,7.45h6.4Zm11.25,0H66.08v7.43h6.37Zm1.47,19h4.41c.68,0,.78-.06.95-.72.47-1.85.93-3.71,1.39-5.57.1-.41.18-.83.27-1.26H75.8Zm-22.48-7.6H46.57c-.24,0-.24.15-.19.34.13.5.25,1,.38,1.49.42,1.72.84,3.45,1.27,5.17.12.46.25.58.66.58h4.39l.27,0Zm4.8,7.55h4.95V54.33H56.25Zm8.9,0h4.13c.86,0,.84,0,.94-.87.19-1.66.4-3.32.61-5,.07-.55.13-1.1.19-1.65H66.09Z"/>
    <path fill="#fff" d="M27.21,58.64H16.91A2.16,2.16,0,0,1,15.07,58a1.89,1.89,0,0,1-.31-2,1.74,1.74,0,0,1,1.5-1.16,7.4,7.4,0,0,1,.91,0H37.55a2.32,2.32,0,0,1,1.52.42,2,2,0,0,1,.62,2.12,1.82,1.82,0,0,1-1.7,1.3H27.21Z"/>
    <path fill="#fff" d="M32.28,61.45h7a2,2,0,0,1,1.92,1.32,1.93,1.93,0,0,1-1.93,2.55c-2,0-4,0-6,0H25.35a1.93,1.93,0,1,1-.2-3.86c1.65,0,3.3,0,5,0h2.18Z"/>
    <path fill="#fff" d="M29.06,48.15c2.34,0,4.69,0,7,0a1.86,1.86,0,0,1,1.72.9,1.93,1.93,0,0,1,0,2,1.7,1.7,0,0,1-1.45.87c-1,.05-2.08.05-3.13.05H23.64c-.6,0-1.2,0-1.79,0a1.91,1.91,0,0,1,.07-3.8c2.38,0,4.76,0,7.14,0Z"/>
    <path fill="#fff" d="M52.41,84.81a3.82,3.82,0,1,1,3.83-4A3.84,3.84,0,0,1,52.41,84.81Z"/>
    <path fill="#fff" d="M72,81a3.84,3.84,0,0,1,7.68,0,3.86,3.86,0,0,1-3.84,3.79A3.81,3.81,0,0,1,72,81Z"/>
  `  
}