export default {
    mount: '.icon-line-round',
    attrs: {
        viewBox: '0 0 39.36 40.51',
        fill: 'currentColor'
    },
    path: `
    <path d="M23.47,16.43h-.86c-.22,0-.3.09-.3.31v3.47h0l-.05-.07-.92-1.23-1.72-2.33a.34.34,0,0,0-.26-.16h-.94a.28.28,0,0,0-.29.3v5.89c0,.19.1.28.29.28s.59,0,.88,0,.33-.08.33-.33V19.14h0l.06.08c.42.56.83,1.12,1.25,1.67l1.39,1.89a.32.32,0,0,0,.28.14h.87a.29.29,0,0,0,.33-.33v-5.8C23.8,16.54,23.69,16.43,23.47,16.43Z"/>
    <path d="M16.87,16.43H16c-.23,0-.31.09-.31.32v5.86a.27.27,0,0,0,.24.29H16c.28,0,.57,0,.85,0s.31-.09.31-.3V16.73A.27.27,0,0,0,16.87,16.43Z"/>
    <path d="M38.34,13.78c-.18-.56-.4-1.11-.63-1.66A21.52,21.52,0,0,0,36.17,9.2,19.56,19.56,0,0,0,20.91,0l-.38,0-.77,0c-.38,0-.77,0-1.15,0a18,18,0,0,0-1.93.2,18.78,18.78,0,0,0-1.92.41c-.5.13-1,.28-1.48.46s-.95.36-1.42.56A20.22,20.22,0,0,0,9.19,3.11l-.32.21A22.15,22.15,0,0,0,7.06,4.7L6.78,5A20.59,20.59,0,0,0,4,8a21,21,0,0,0-2,3.3A20.58,20.58,0,0,0,.07,18.58L0,19c0,.57,0,1.14,0,1.7A20.33,20.33,0,0,0,3.22,31.36a19.47,19.47,0,0,0,5,5.41A20,20,0,0,0,12.1,39l.74.3.76.27c.5.17,1,.31,1.53.44l.78.17a18.45,18.45,0,0,0,4,.38,18.91,18.91,0,0,0,6-1,19.28,19.28,0,0,0,5.16-2.72l.31-.24c.31-.23.61-.48.9-.73a19.13,19.13,0,0,0,1.91-1.91q.63-.73,1.2-1.53a3.62,3.62,0,0,0,.22-.32,20.92,20.92,0,0,0,3.24-7.67c.11-.54.2-1.09.28-1.64,0-.14,0-.28,0-.43a1.11,1.11,0,0,1,0-.17,23,23,0,0,0,.13-2.37A19.43,19.43,0,0,0,38.34,13.78ZM31.68,24.14a19.51,19.51,0,0,1-4.43,4.68,49,49,0,0,1-7,4.74,4.62,4.62,0,0,1-.94.41c-.59.19-.85,0-.75-.66s.14-1,.22-1.44c.23-1.36-.41-1.63-1.41-1.79a14.79,14.79,0,0,1-7-2.92,10.06,10.06,0,0,1-3.85-6,9,9,0,0,1,1.8-7.45A13.2,13.2,0,0,1,15.68,9a12.46,12.46,0,0,1,1.5-.31c.83-.1,1.67-.15,2.13-.19a15.23,15.23,0,0,1,9.76,3.14,9.92,9.92,0,0,1,3.85,6.09A8.67,8.67,0,0,1,31.68,24.14Z"/>
    <path d="M26.34,17.89h2.48a.25.25,0,0,0,.29-.29v-.85c0-.23-.09-.32-.31-.32H25a.26.26,0,0,0-.26.17,1,1,0,0,0,0,.17c0,1,0,1.93,0,2.89h0V22.6c0,.21.09.3.31.3h3.77c.2,0,.3-.09.3-.29v-.83c0-.24-.1-.34-.34-.34H26.33c-.08,0-.1,0-.1-.1V20.5c0-.08,0-.1.11-.1H28.8c.22,0,.31-.09.31-.32v-.8c0-.24-.1-.34-.34-.34H26.33c-.08,0-.1,0-.1-.11V18C26.23,17.92,26.25,17.89,26.34,17.89Z"/>
    <path d="M14.54,21.44H12.09c-.13,0-.13,0-.13-.12v-4.6c0-.2-.09-.29-.29-.29h-.89c-.22,0-.31.09-.31.31V22.6a.27.27,0,0,0,.3.3h3.76c.22,0,.31-.08.31-.3v-.86C14.84,21.53,14.75,21.44,14.54,21.44Z"/>
    `
}