export default {
    mount: '.icon-logo2',
    attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 83.51 77.11'
    },
    path: `
    <path fill="#909190" d="M70.67,22.42C62.5,17.77,58.37,13.11,53.6,6.14a13.28,13.28,0,0,0-9.45-6,19.64,19.64,0,0,0-2-.09H40.61a13.74,13.74,0,0,0-3,.32A12.49,12.49,0,0,0,32,3.25,16.64,16.64,0,0,0,29.45,6.3c-4.67,7-8.74,11.75-16.85,16.5C3.46,28.16-1.16,35.71.25,43,1.79,51,10,56.83,22.17,58.66A130.68,130.68,0,0,0,41,60h1.57a130.05,130.05,0,0,0,19-1.62c12.17-2,20.28-8,21.71-16C84.6,35.13,79.88,27.65,70.67,22.42Zm8.75,19.31C78.31,47.92,71.23,52.82,61,54.51a125.61,125.61,0,0,1-18.53,1.57H41a125.12,125.12,0,0,1-18.2-1.34C12.45,53.21,5.3,48.41,4.11,42.23,3,36.67,7,30.68,14.6,26.2,23.37,21.05,27.74,16,32.73,8.48a13.68,13.68,0,0,1,2-2.34,8.69,8.69,0,0,1,3.83-2A9.59,9.59,0,0,1,40.66,4h1.46a14.22,14.22,0,0,1,1.6.07,9.44,9.44,0,0,1,6.64,4.35c5.09,7.46,9.53,12.45,18.36,17.48C76.42,30.22,80.42,36.16,79.42,41.73Z"/>
    <path fill="#4cc2b1" d="M50.4,33.2A1.51,1.51,0,0,0,49.27,35a7.28,7.28,0,0,1-.77,4,7.71,7.71,0,0,1-6,4.17,7.17,7.17,0,0,1-6.42-2.47c-.72-.84-1.44-1.71-2.1-2.62,3.74-2.09,7-4.33,7.24-4.48l.1-.08a6.2,6.2,0,0,0,2.32-2.89,5.66,5.66,0,0,0-3-6.13,4.81,4.81,0,0,0-5,.76,4.83,4.83,0,0,0-5-.76,5.66,5.66,0,0,0-3.05,5.38,8.11,8.11,0,0,0,.19,1.69,7.11,7.11,0,0,0,.38,1.67c0,.15.09.3.13.44A15.36,15.36,0,0,0,29.19,36c.15.32.31.63.48.94a21.5,21.5,0,0,1-4,1.58,1.5,1.5,0,0,0,.75,2.91,26.7,26.7,0,0,0,4.89-1.94c.81,1.14,1.68,2.2,2.49,3.15a10.05,10.05,0,0,0,7.76,3.58,11.66,11.66,0,0,0,1.31-.08,10.68,10.68,0,0,0,8.33-5.79,10.14,10.14,0,0,0,1-6A1.51,1.51,0,0,0,50.4,33.2ZM32.33,35.55c-.15-.27-.3-.55-.43-.84a18.79,18.79,0,0,1-.78-2.05,9.12,9.12,0,0,1-.52-2.78,2.55,2.55,0,0,1,1.22-2.45c.81-.36,1.85,0,2.77,1a1.44,1.44,0,0,0,1.06.46,1.42,1.42,0,0,0,1.05-.46c.93-1,2-1.38,2.78-1a2.32,2.32,0,0,1,1.21,1.65,1.82,1.82,0,0,1-.14,1.13,2.47,2.47,0,0,1-1,1L36.72,33C35.28,33.85,33.82,34.73,32.33,35.55Z"/>
    <path fill="#909190" d="M10.66,11.49a2.94,2.94,0,0,0,1.94.75,3.83,3.83,0,0,0,2.41-1,3.06,3.06,0,0,0,1.21-3.17A2.87,2.87,0,0,0,16,7.52a1.69,1.69,0,0,0-.05-.7c-.13-.53-.63-1.42-2.37-1.42h0a3,3,0,0,0-1,.15,2.47,2.47,0,0,0-2.72,1A4.12,4.12,0,0,0,10.66,11.49Z"/>
    <path fill="#909190" d="M72.75,11.75a3.27,3.27,0,0,0,1.31.85,2.63,2.63,0,0,0,.91.15,3.19,3.19,0,0,0,2.55-1.4,3.37,3.37,0,0,0,.39-3.82,4.23,4.23,0,0,0-4-2A3.32,3.32,0,0,0,71.05,8,3,3,0,0,0,71.72,11,3.18,3.18,0,0,0,72.75,11.75Z"/>
    <path fill="#909190" d="M31.15,67.48c.09-.47-.08-.71-.51-.71a.76.76,0,0,0-.78.71l-1.12,6.17a2.33,2.33,0,0,1-1.09,1.7,4.29,4.29,0,0,1-2.21.58,3.36,3.36,0,0,1-2.25-.66,1.6,1.6,0,0,1-.41-1.63l1.13-6.17c.08-.47-.09-.71-.52-.71a.75.75,0,0,0-.77.71l-1.13,6.16a2.61,2.61,0,0,0,.56,2.48,4.34,4.34,0,0,0,3.18,1,5.72,5.72,0,0,0,3.23-.89A3.67,3.67,0,0,0,30,73.65Z"/>
    <path fill="#909190" d="M16.55,66.75a5.91,5.91,0,0,0-3.54,1,3.73,3.73,0,0,0-1.45,2.48l-1.12,6.17c-.08.47.09.7.52.71s.68-.24.77-.71l1.12-6.17a2.28,2.28,0,0,1,1-1.63,4.44,4.44,0,0,1,2.49-.66,3.34,3.34,0,0,1,2,.59,1.62,1.62,0,0,1,.47,1.71l-1.12,6.17c-.08.47.09.71.51.71a.75.75,0,0,0,.78-.71l1.12-6.17a2.55,2.55,0,0,0-.65-2.56A4.2,4.2,0,0,0,16.55,66.75Z"/>
    <path fill="#909190" d="M50.78,69.7a2,2,0,0,0-.66-2A3.65,3.65,0,0,0,47.81,67H43.76c-.91,0-1.44.38-1.58,1.16l-1.47,8.09c-.09.5.09.75.55.76s.68-.26.78-.76l.65-3.58h4.09a4.84,4.84,0,0,0,2.42-.57A3.21,3.21,0,0,0,50.78,69.7Zm-2.26,1.45a3.17,3.17,0,0,1-1.53.36H42.9l.61-3.37H47.6a2.26,2.26,0,0,1,1.47.41,1.14,1.14,0,0,1,.39,1.15A1.92,1.92,0,0,1,48.52,71.15Z"/>
    <path fill="#909190" d="M60.77,66.91h-6.4a1.32,1.32,0,0,0-1.48,1.15L51.45,76c-.14.77.22,1.15,1.07,1.15h6.4q.8,0,.9-.6t-.69-.6H52.74l.62-3.43H59.3c.51,0,.79-.19.87-.58s-.15-.57-.66-.57H53.57l.59-3.27h6.4c.55,0,.86-.19.93-.58S61.33,66.86,60.77,66.91Z"/>
    <path fill="#909190" d="M72.41,66.94H64.14c-.53,0-.82.19-.89.58s.15.57.67.58h3.64l-1.51,8.31c-.08.45.09.69.52.69a.74.74,0,0,0,.77-.69l1.51-8.31H72.2c.51,0,.8-.19.88-.58S72.92,67,72.41,66.94Z"/>
    <path fill="#4cc2b1" d="M38.84,73.57H37.71l.83-6c.07-.5-.09-.75-.5-.75-.2,0-.51.25-.94.75l-5.27,6a.78.78,0,0,0-.22.81.64.64,0,0,0,.62.37h4.15l-.22,1.57q-.1.75.45.75t.72-.75l.22-1.57h1.13c.46,0,.72-.2.78-.59S39.31,73.58,38.84,73.57Zm-2.3,0H33.33l3.83-4.45Z"/>
    `
}
