export default {
    mount: '.icon-arrow-left-round',
    attrs: {
        viewBox: '46.38 46.38',
        fill: 'currentColor',
    },
    path: `
    <circle cx="23.19" cy="23.19" r="23.19"/>
    <polyline fill="#fff" points="26.65 33.32 16.55 23.21 16.55 23.17 26.65 13.06"/>
    `
}