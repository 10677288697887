export default {
  mount: '.icon-staple-food',
  attrs: {
  viewBox: '0 0 139.79 139.79' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="69.9" cy="69.9" r="69.9"/>
    <path fill="#ffffff" d="M69.9,53.42A58.7,58.7,0,0,1,52.3,51c-6.77-2.23-8.2-5.13-8.2-7.17s1.43-4.94,8.2-7.16a58.38,58.38,0,0,1,17.6-2.41,58.31,58.31,0,0,1,17.59,2.41c6.78,2.22,8.2,5.12,8.2,7.16s-1.42,4.94-8.2,7.17A58.63,58.63,0,0,1,69.9,53.42Zm0-15.9a55.2,55.2,0,0,0-16.58,2.25c-4.4,1.45-6,3.1-6,4.07s1.56,2.63,6,4.07A55.2,55.2,0,0,0,69.9,50.16a55.17,55.17,0,0,0,16.57-2.25c4.4-1.44,6-3.09,6-4.07s-1.56-2.62-6-4.07A55.17,55.17,0,0,0,69.9,37.52Z"/>
    <path fill="#ffffff" d="M69.9,60.41A58.7,58.7,0,0,1,52.3,58c-6.77-2.23-8.2-5.13-8.2-7.17a1.63,1.63,0,0,1,3.26,0c0,1,1.56,2.63,6,4.07A55.2,55.2,0,0,0,69.9,57.15,55.17,55.17,0,0,0,86.47,54.9c4.4-1.44,6-3.09,6-4.07a1.63,1.63,0,1,1,3.26,0c0,2-1.42,4.94-8.2,7.17A58.63,58.63,0,0,1,69.9,60.41Z"/>
    <path fill="#ffffff" d="M69.9,98.71A58.7,58.7,0,0,1,52.3,96.3c-6.77-2.23-8.2-5.13-8.2-7.17a1.63,1.63,0,0,1,3.26,0c0,1,1.56,2.63,6,4.07A55.2,55.2,0,0,0,69.9,95.45,55.17,55.17,0,0,0,86.47,93.2c4.4-1.44,6-3.09,6-4.07a1.63,1.63,0,0,1,3.26,0c0,2-1.42,4.94-8.2,7.17A58.63,58.63,0,0,1,69.9,98.71Z"/>
    <path fill="#ffffff" d="M69.9,105.53a58.7,58.7,0,0,1-17.6-2.41C45.53,100.89,44.1,98,44.1,96V43.84a1.63,1.63,0,0,1,3.26,0c0,1,1.56,2.63,6,4.07A55.2,55.2,0,0,0,69.9,50.16a55.17,55.17,0,0,0,16.57-2.25c4.4-1.44,6-3.09,6-4.07a1.63,1.63,0,1,1,3.26,0V96c0,2-1.42,4.94-8.2,7.17A58.63,58.63,0,0,1,69.9,105.53ZM47.36,48.75V96c0,1,1.56,2.63,6,4.07a55.2,55.2,0,0,0,16.58,2.25A55.17,55.17,0,0,0,86.47,100c4.4-1.44,6-3.1,6-4.07V48.75A21.44,21.44,0,0,1,87.49,51,58.63,58.63,0,0,1,69.9,53.42,58.7,58.7,0,0,1,52.3,51,21.44,21.44,0,0,1,47.36,48.75Z"/>
    <path fill="#ffffff" d="M69.9,88A29.63,29.63,0,0,1,61,86.76c-3.8-1.25-4.6-3-4.6-4.31V68.26A1.64,1.64,0,0,1,57,67a1.6,1.6,0,0,1,1.38-.3A50.56,50.56,0,0,0,70,68a50.77,50.77,0,0,0,11.41-1.25,1.62,1.62,0,0,1,2,1.59V82.45c0,1.28-.8,3.06-4.6,4.31A29.56,29.56,0,0,1,69.9,88Zm-10.25-5.6c.13.22.88.86,2.9,1.44a30.46,30.46,0,0,0,14.7,0c2-.58,2.76-1.22,2.89-1.44V70.31A54.79,54.79,0,0,1,70,71.23a55.18,55.18,0,0,1-10.35-1Z"/>
    <path fill="#ffffff" d="M66.3,47.55H58.13a4,4,0,0,1,0-8H66.3a4,4,0,0,1,0,8Zm-8.17-4.74a.74.74,0,0,0,0,1.48H66.3a.74.74,0,1,0,0-1.48Z"/>
  `  
}