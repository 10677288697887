export default {
    mount: '.icon-member',
    attrs: {
        viewBox: '0 0 41 51',
        fill: 'currentColor',
    },
    path: `
    <path fill="#5a5b5b" d="M31.38,28.78A1.17,1.17,0,0,0,30,30.68L36,35l.06,0a2.17,2.17,0,0,1,.85,2.71l-2.78,5,0,.09a2.18,2.18,0,0,1-2,1.27H10.26a2.17,2.17,0,0,1-2-1.27l0-.09-2.78-5A2.17,2.17,0,0,1,6.31,35l.07,0,6-4.27A1.17,1.17,0,0,0,11,28.78L5.05,33a4.5,4.5,0,0,0-1.69,5.68l0,.08,2.79,5a4.5,4.5,0,0,0,4.07,2.59H32.11a4.51,4.51,0,0,0,4.08-2.59l2.78-5a.56.56,0,0,0,.05-.08A4.51,4.51,0,0,0,37.32,33Z"/>
    <path fill="#5a5b5b" d="M21.19,30.69c5.81,0,10.92-6.61,10.92-14.14,0-6.9-4.59-11.91-10.92-11.91s-10.92,5-10.92,11.91C10.27,24.08,15.37,30.69,21.19,30.69Zm8.59-14.14c0,6.18-4.1,11.81-8.59,11.81S12.6,22.73,12.6,16.55C12.6,10.91,16.13,7,21.19,7S29.78,10.91,29.78,16.55Z"/>
    `
}