export default {
  mount: '.icon-point-exchange',
  attrs: {
  viewBox: '0 0 139.79 139.79' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="69.9" cy="69.9" r="69.9"/>
    <path fill="#ffffff" d="M51,101.12v-57a6.85,6.85,0,0,1,6.76-6.75H73.64a22.48,22.48,0,0,1,22.6,22.6,22.48,22.48,0,0,1-22.6,22.59H71.32a6.76,6.76,0,1,1,0-13.51h2.32a9.11,9.11,0,0,0,6.44-15.53,8.75,8.75,0,0,0-6.44-2.64H64.56v50.27a6.76,6.76,0,1,1-13.52,0Z"/>
  `  
}