export default {
    mount: '.icon-line',
    attrs: {
        viewBox: '0 0 45 45',
        fill: 'currentColor',
    },
    path: `
    <path d="M19.78,19.79h-.86c-.22,0-.31.08-.31.31v5.68a.27.27,0,0,0,.24.29h.92c.21,0,.3-.09.3-.29v-5.7C20.07,19.88,20,19.79,19.78,19.79Z"/><path class="cls-1" d="M17.52,24.66H15.14c-.12,0-.12,0-.12-.12V20.07a.24.24,0,0,0-.28-.28h-.87c-.22,0-.3.09-.3.3v5.68a.26.26,0,0,0,.29.3h3.65c.21,0,.3-.08.3-.29v-.84C17.81,24.74,17.73,24.66,17.52,24.66Z"/>
    <path d="M26.18,19.79h-.83c-.21,0-.29.08-.29.3v3.37h0L25,23.4l-.89-1.2-1.67-2.26a.31.31,0,0,0-.25-.15h-.92a.27.27,0,0,0-.27.29v5.71a.24.24,0,0,0,.28.28h.85c.24,0,.32-.08.32-.32V22.42h0l.06.07c.4.55.8,1.09,1.21,1.63l1.35,1.83a.29.29,0,0,0,.27.13h.84c.22,0,.33-.1.33-.32V20.11A.29.29,0,0,0,26.18,19.79Z"/><path class="cls-1" d="M31.36,19.79H27.68a.24.24,0,0,0-.25.17.78.78,0,0,0,0,.16v2.81h0v2.85c0,.2.09.29.3.29h3.66c.2,0,.29-.09.29-.29V25c0-.23-.1-.32-.33-.32-.79,0-1.58,0-2.37,0-.07,0-.1,0-.1-.1,0-.27,0-.55,0-.82,0-.08,0-.1.11-.1h2.39c.21,0,.3-.09.3-.31v-.78c0-.23-.1-.33-.33-.33H29c-.08,0-.1,0-.1-.11,0-.27,0-.54,0-.8,0-.09,0-.11.11-.11h2.41a.24.24,0,0,0,.28-.28V20.1C31.66,19.88,31.57,19.79,31.36,19.79Z"/><path class="cls-1" d="M22.51,0A22.51,22.51,0,1,0,45,22.51,22.51,22.51,0,0,0,22.51,0ZM34.15,27.27a18.71,18.71,0,0,1-4.29,4.54,48.18,48.18,0,0,1-6.81,4.61,4.89,4.89,0,0,1-.91.39c-.58.19-.83-.05-.73-.64s.14-.93.22-1.4c.21-1.32-.4-1.58-1.37-1.73a14.27,14.27,0,0,1-6.84-2.84,9.75,9.75,0,0,1-3.74-5.83,8.7,8.7,0,0,1,1.74-7.24,12.82,12.82,0,0,1,7.2-4.55,10.51,10.51,0,0,1,1.46-.3c.81-.1,1.62-.15,2.07-.19a14.78,14.78,0,0,1,9.47,3.05,9.63,9.63,0,0,1,3.74,5.91A8.37,8.37,0,0,1,34.15,27.27Z"/>
    `
}