import './scss/main.css'
import '@assets/init.js'
import '@/library/init'
import '@/plugins/init'
import '@/components/icon/mount'
import '@/components/icon/lib/evaluation'
import '@/events/init'
// import '@/page/pet'
// import '@/page/shop-car'
import '@/page/shop-list'
import '@/page/shop-detail'
// import '@/socket'