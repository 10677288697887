export default {
  mount: '.icon-member-pet',
  attrs: {
  viewBox: '0 0 367.63 93.19' ,
  fill: 'currentColor'
  },
  path: `
    <path d="M5.17,34.22c-2.08.28-3.82-.92-3.58-4.06.26-3.38,1-2.25,3.83-2.84C5,25.81,5,24.48,4.71,23.1c-.31-1.55-.45-3-.62-4.39-.19-1.59-.46-3-.49-4.41a42.19,42.19,0,0,1,0-4.44,34,34,0,0,1,.63-4.4c.62-2.79.37-2.21,1.5-4.13.08,0-.19.11-.11.13A16.61,16.61,0,0,1,9,4.49c.81,1,1.58,2.36,2.5,3.8.75,1.17,1.57,2.46,2.31,3.91a44.8,44.8,0,0,1,2,4.07c.53,1.35.65,2.91,1.08,4.43A43.68,43.68,0,0,1,18.13,25c.08.17.47-.17.37,0-1.17,2-1.48,1.79-2.74,3.71s-1.09,2-2.35,4-1.22,2-2.48,3.88a24.15,24.15,0,0,1-2.84,3.61c-1.2.51-2.54,1.67-3.81,1.49C3.19,41.52,2,40.47,1.52,38.5.65,35.24,2.3,35.31,5.17,34.22Z"/>
    <path d="M5.79,1.29s0,.14,0,.14A27.88,27.88,0,0,1,9.63,2.68a28.62,28.62,0,0,1,3.46,2.17,31.6,31.6,0,0,1,3.14,2.59,32.17,32.17,0,0,1,2.56,3.16c1,1.16,2,2.12,2.62,3.09a36.77,36.77,0,0,1,2.18,3.65c-.93,1.41-1,1.36-1.94,2.77s-1.1,1.3-2,2.71a22.14,22.14,0,0,1-1.42,2c-.11.15-.24.48-.32.31-.27-1.54-.63-3-1-4.43s-.92-2.93-1.45-4.27-1-3-1.61-4.24c-.74-1.44-1.46-2.79-2.21-4A39.57,39.57,0,0,0,8.77,4.68,36.6,36.6,0,0,0,5.79,1.29Z"/>
    <path d="M15.61,27.18a9.46,9.46,0,0,0-4.12-3.94"/>
    <path d="M45.11,21.43a28.38,28.38,0,0,1,1.45-4.5,38.17,38.17,0,0,1,2-4.26A45.21,45.21,0,0,1,51.42,8.9C52.41,7.48,53.11,6,54,5a20.2,20.2,0,0,1,3.39-3.17,11,11,0,0,1,1.43,3.87c.25,1.19-.13,2.72,0,4.4a35.34,35.34,0,0,1,.45,4.33c0,1.35-.06,2.81-.26,4.35-.17,1.36-.7,2.76-1,4.27.17.05.34.45.52.55,1.41.85,2.59,3,.43,4.5a2.35,2.35,0,0,1,2.33,2.48c.2,3.14-2.21,3.91-4.28,3.6-1.4,1.06-.3,2-1.1,3.55s-.17,3.21-1.9,2.94c-2.18-.34-1.12-1.9-2.3-3.76s-1.1-1.92-2.29-3.78-1.5-1.67-2.68-3.53-1.65-1.66-2.53-3.69C44.4,24.69,44.75,23.09,45.11,21.43Z"/>
    <path d="M57.27,1.69a.69.69,0,0,0,0-.07c-.29.29-1.5,1.57-3.1,3.51-.87,1.06-2,2.19-3,3.61-.84,1.22-1.74,2.54-2.53,4A38.2,38.2,0,0,0,46.78,17a27.81,27.81,0,0,0-1.36,4.48c-.36,1.66-.64,3-.87,4.18-1.72-1.8-1.32-2.09-2.66-4.19s-1.77-1.86-2.59-4.21c-.68-1.93.5-1.42,1.93-3.48a39.1,39.1,0,0,1,2.61-3.16,35.65,35.65,0,0,1,3-2.8,30.18,30.18,0,0,1,3.1-2.66A28,28,0,0,1,53.39,3,27,27,0,0,1,57.27,1.69Z"/>
    <path d="M48.11,24.72a9.46,9.46,0,0,1,4.18-3.88"/>
    <path d="M13.84,26.44C25.69,11.27,35.22,12.3,41.53,15.38c8.15,4,13,18.75,23,25.8S79.77,57,79.76,67.42c0,13.27-13.66,18.92-13.66,18.92-5.62,3.22-29.66,8.39-46.54,1.93C-7.08,75.06,2,41.6,13.84,26.44Z"/>
    <path d="M40,30.12a11.86,11.86,0,0,0-12.2-3.64c-7.6,2.15-17.41,13-19.3,35.3C7.15,77.29,13,84,16.74,86.72-6.4,72.59,2.43,41,13.84,26.44,25.69,11.27,35.22,12.3,41.53,15.38c4.18,2,7.47,6.91,11.05,12.13a11.58,11.58,0,0,0-5.49-1.87A8,8,0,0,0,40,30.12Z"/>
    <path d="M32.71,36.56a1.85,1.85,0,0,0,1.75,1.77A1.94,1.94,0,0,0,36.4,36.2a1.72,1.72,0,0,0-2-1.72,1.89,1.89,0,0,0-1.7,2.08"/>
    <path d="M42.44,38.3a2.17,2.17,0,0,0,.76-.38,6.62,6.62,0,0,0,1-1.4,1.34,1.34,0,0,0-.71-1.87,1.82,1.82,0,0,0-2.54.81,2.22,2.22,0,0,0,1.09,2.75c.08,0,.16,0,.38.09"/>
    <path d="M38.94,44.45c0,1.88,2.44,3.42,5.47,3.44s5.51-1.48,5.52-3.36-2.44-3.42-5.47-3.44-5.51,1.49-5.52,3.36"/>
    <path d="M28.89,44a1.24,1.24,0,0,0,1.34-.71,2,2,0,0,1,.58-.74,1.24,1.24,0,1,0-1.32-2.09A4.35,4.35,0,0,0,28,42.27,1.24,1.24,0,0,0,28.89,44Z"/>
    <path d="M25.41,43a1.24,1.24,0,0,0,1.17-.43l.79-1a1.24,1.24,0,1,0-1.91-1.57l-.79,1a1.24,1.24,0,0,0,.74,2Z"/>
    <path d="M20.45,61.27a8.8,8.8,0,0,1,4.17-9.15c2-1.16,4.73-1.65,8.28-.15,9,3.83,11.77,4.38,15.11,3.38A12.57,12.57,0,0,1,54,55c3.06.68,5.81,3.09,5.25,9.59-1,11.21-20.63,11.65-30.22,8C23.08,70.34,21.1,65,20.45,61.27Z"/>
    <path d="M35.1,61.64C42.76,63,48.7,67.76,50.7,73.4c-6.77,2.08-16,1.35-21.62-.8a13.25,13.25,0,0,1-8-9A21.93,21.93,0,0,1,35.1,61.64Z"/>
    <polyline points="36.52 53.46 30.81 59.91 29.96 51.71"/>
    <polyline points="49.53 56.39 51.75 62.17 54.82 55.23"/>
    <path d="M20.45,61.27a8.8,8.8,0,0,1,4.17-9.15c2-1.16,4.73-1.65,8.28-.15,9,3.83,11.77,4.38,15.11,3.38A12.57,12.57,0,0,1,54,55c3.06.68,5.81,3.09,5.25,9.59-1,11.21-20.63,11.65-30.22,8C23.08,70.34,21.1,65,20.45,61.27Z"/>
    <line x1="44.72" x2="42.23" y1="46.17" y2="55.39"/>
    <path d="M329.29,25.51s8.28-5.43,23-5.85l-4.73,25.83Z"/>
    <path d="M304.33,42.63l-.08-.22c-3.5-10-2.11-27.35-2.11-27.35,8-.57,21,10.41,21,10.41Z"/>
    <path d="M343,85.47c21.83-9.35,11.56-40.31,1.22-53.37-11-13.93-20-13.1-26-10.36-7.73,3.53-12.43,16.94-22,23.26s-14.52,14.27-14.62,23.74c-.13,12.08,12.7,17.37,12.7,17.37S319.22,95.65,343,85.47Z"/>
    <path d="M277.05,50.59s8.55.47,14.84,6"/>
    <path d="M275.56,60.62s6.22-2,15.84.82"/>
    <path d="M345.6,60.07s10.79-2.28,20.6.42"/>
    <path d="M348.93,65s6.47-.59,14.71,3.74"/>
    <path d="M322.9,42.78a1.62,1.62,0,0,1-1.6,1.49,1.7,1.7,0,0,1-1.62-1.93,1.5,1.5,0,0,1,1.81-1.43,1.65,1.65,0,0,1,1.41,1.88"/>
    <path d="M314.33,43.95a1.9,1.9,0,0,1-.65-.36,5.79,5.79,0,0,1-.84-1.27,1.17,1.17,0,0,1,.69-1.61,1.59,1.59,0,0,1,2.19.8,1.94,1.94,0,0,1-1.06,2.37l-.34.06"/>
    <path d="M328.42,50.65a1.43,1.43,0,0,1-1.29-1.2,2.28,2.28,0,0,0-.43-1,1.43,1.43,0,1,1,2.09-2A5,5,0,0,1,330,49a1.43,1.43,0,0,1-1.53,1.66Z"/>
    <path d="M332,50.56a1.43,1.43,0,0,1-1.18-.83l-.6-1.3a1.43,1.43,0,1,1,2.6-1.19l.6,1.3a1.43,1.43,0,0,1-1.42,2Z"/>
    <path d="M318.3,48.41c-1.29-1.17-2.93-.09-3.63.49-.7-.58-2.33-1.68-3.63-.52-1.7,1.52-.58,3.95,2.75,5.38h0c4.25-.51,6.18-3.81,4.49-5.35"/>
    <path d="M318.3,48.41c-1.29-1.17-2.93-.09-3.63.49-.7-.58-2.33-1.68-3.63-.52-1.7,1.52-.58,3.95,2.75,5.38h0C318.06,53.25,320,49.95,318.3,48.41Z"/>
    <path d="M304.62,53.63s-1,5.15,3.66,5.09c4.25-.06,5.51-5,5.51-5"/>
    <path d="M324.84,53.72s0,5.52-5.57,5c-4.24-.36-5.47-5-5.47-5"/>
    <path d="M310.68,58.3S310,64.87,308.31,68s-1.07,6.2,2.65,7.76,6.83.24,8-1.84.59-13.27-.57-15a8.58,8.58,0,0,1-4.53-3.62A4.68,4.68,0,0,1,310.68,58.3Z"/>
  `  
}