export default {
  mount: '.icon-step-3',
  attrs: {
  viewBox: '0 0 82.43 76.85' ,
  fill: 'currentColor'
  },
  path: `
    <path d="M79.49,54.66l-.68-.43a6.3,6.3,0,0,0-4-.94L48.74,55.91a10.36,10.36,0,0,0-1-2.41,6.23,6.23,0,0,0-4.13-3.09l-16-3.56a6.27,6.27,0,0,0-3.78.33l-6.29,2.61V46.5a1.93,1.93,0,0,0-1.93-1.92H1.93A1.92,1.92,0,0,0,0,46.5V74.92a1.92,1.92,0,0,0,1.93,1.93H15.68a1.93,1.93,0,0,0,1.93-1.93V70.74c1.41.28,13.94,3,25.47,5.44a6.58,6.58,0,0,0,1.32.14,6.09,6.09,0,0,0,1.9-.3L78,66a6.29,6.29,0,0,0,1.46-11.31ZM10.73,73V63.18a1.93,1.93,0,0,0-3.86,0V73h-3V48.43h9.89V73Zm67.7-12.21a2.45,2.45,0,0,1-1.57,1.52l-31.72,10a2.64,2.64,0,0,1-1.25.06c-25.7-5.53-25.79-5.53-26.08-5.53h-.2V54l7.76-3.23a2.46,2.46,0,0,1,1.47-.12l16,3.55a2.39,2.39,0,0,1,1.58,1.18,4.14,4.14,0,0,1,.7,2.64,2.47,2.47,0,0,1-1.67,1.49,2.42,2.42,0,0,1-1.31.12l-9.52-1.81a1.93,1.93,0,1,0-.72,3.79l9.52,1.81a6.73,6.73,0,0,0,1.18.11,6.19,6.19,0,0,0,2.25-.42,6.39,6.39,0,0,0,3.71-3.27L75.2,57.12a2.49,2.49,0,0,1,1.55.37l.68.43A2.43,2.43,0,0,1,78.43,60.78Z"/>
    <path d="M57.65,46.2a23.1,23.1,0,1,0-23.1-23.1A23.12,23.12,0,0,0,57.65,46.2Zm0-42.34A19.25,19.25,0,1,1,38.4,23.1,19.26,19.26,0,0,1,57.65,3.86Z"/>
    <path d="M50.12,33.3a10.58,10.58,0,0,0,5.6,2.92v1.41a1.93,1.93,0,0,0,3.86,0V36.22a7.65,7.65,0,0,0,4-2,7.26,7.26,0,0,0,2.25-5.49c0-3.35-1.71-5.81-5.09-7.31L57.68,20a9.35,9.35,0,0,1-2.35-1.3,2.35,2.35,0,0,1-.94-1.92A2.2,2.2,0,0,1,55.25,15a4,4,0,0,1,2.51-.73,5.79,5.79,0,0,1,3.81,1.41,1.8,1.8,0,0,0,1.4.54,2,2,0,0,0,1.42-1,2.1,2.1,0,0,0-.19-2.87l0,0A9.17,9.17,0,0,0,59.58,10V8.32a1.93,1.93,0,0,0-3.86,0V10a7.22,7.22,0,0,0-3.39,1.81,7,7,0,0,0-2.18,5.32c0,3.07,1.69,5.4,5,6.92l3.07,1.37a9.2,9.2,0,0,1,2.49,1.48A2.67,2.67,0,0,1,61.61,29a2.46,2.46,0,0,1-1,2.09,4.65,4.65,0,0,1-2.9.82,7.22,7.22,0,0,1-4.85-2,1.81,1.81,0,0,0-1.43-.6,2.06,2.06,0,0,0-1.48,1,2.22,2.22,0,0,0,.13,2.95Z"/>
  `  
}