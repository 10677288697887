export default {
  mount: '.icon-member-order-ec',
  attrs: {
  viewBox: '0 0 76.54 76.54' ,
  fill: 'currentColor'
  },
  path: `
    <path d="M60.51,38.47C64.73,34.05,69,29.7,73.28,25.34c4.12-4.21,4.41-3.74,0-8.19-3.78-3.83-3.76-3.84-7.59,0l-6.28,6.36c0-5.1,0-9.59,0-14.08,0-5.24-3-8.27-8.07-8.28q-21.64,0-43.29,0c-5,0-7.89,3.1-8,8.14,0,2,.61,2.62,2.48,2.55,3.19-.11,6.38,0,9.75,0V14.5q0,26.59,0,53.18c0,4.36,2.9,7.61,7,7.62q21.15.08,42.3,0a7.2,7.2,0,0,0,6.94-6.23c.27-1.58-.15-2.43-1.91-2.37-2.28.08-4.57,0-7,0a9,9,0,0,1-.16-1.08c0-8.33,0-16.65,0-25A3.66,3.66,0,0,1,60.51,38.47ZM69.66,17l4.1,4.39-3.21,3.24-4-4.71Zm-4.84,5.41.62.58L37.77,51.34l-.51-.7ZM38.49,55.88l-4.14,1.3,1.28-4.46ZM19.19,72.82c-2.69-.25-4.53-2.37-4.53-5.36,0-18.8,0-37.6,0-56.39a62,62,0,0,0-.89-7.34,9.73,9.73,0,0,1,1.13-.08H51c4.17,0,6,1.85,6,6.15,0,4.83.07,9.67-.05,14.5a4.81,4.81,0,0,1-1.2,2.94Q46,37.44,36,47.46a10,10,0,0,0-3,5.25c-.36,1.83-1,3.59-1.5,5.4-.41,1.64.22,2.35,1.78,1.93q3.89-1,7.72-2.3a4.79,4.79,0,0,0,1.7-1.29Q49.09,50,55.41,43.52c.4-.41.84-.78,1.48-1.37V66.52h-2.2c-9.25,0-18.49,0-27.74,0-1.48,0-2.4.19-2.63,2.08A4.75,4.75,0,0,1,19.19,72.82ZM40.93,54.52l-.86-.8L67.72,25.41l.88.79Z"/>
    <path d="M23.21,20.61c4.88,0,4.88,0,4.88-5-.07-5.82.8-5.13-5-5.14-5.61,0-4.86-.59-4.89,5.05C18.19,20.61,18.21,20.61,23.21,20.61Zm-2.47-7.69h4.84V18H20.74Z"/>
    <path d="M23.11,30.25c-5.69,0-4.84-.47-4.9,5,.1,5.75-.9,5.1,5,5.1s4.86.71,4.88-5S28.86,30.26,23.11,30.25Zm2.44,7.59H20.75V32.77h4.79Z"/>
    <path d="M28.08,55.22c.05-5,0-5-4.87-5s-5,0-5,5.06c0,5.35-.89,4.93,4.79,4.91C29,60.21,28,60.84,28.08,55.22Zm-2.46,2.42H20.69v-5h4.93Z"/>
    <path d="M51.41,19.18c.64,0,1.27-.74,1.91-1.13-.59-.42-1.18-1.17-1.79-1.2-3.07-.12-6.14-.06-9.21-.06s-6.28-.06-9.41.05c-.62,0-1.22.75-1.83,1.16.63.41,1.26,1.16,1.9,1.17Q42.19,19.32,51.41,19.18Z"/>
    <path d="M33.19,14.28c3,.13,6,.06,9,.06s6,.07,9-.05c.71,0,1.91-.65,2-1.17.25-1.43-1-1.21-1.9-1.21q-9.12,0-18.24,0c-.86,0-2.15-.21-1.9,1.21C31.27,13.63,32.48,14.25,33.19,14.28Z"/>
    <path d="M32.45,34.07q5.3.17,10.6,0c.51,0,1-.78,1.49-1.2-.49-.39-1-1.09-1.49-1.13-1.76-.14-3.53-.05-5.3-.05s-3.54-.07-5.3.05c-.48,0-.93.67-1.39,1C31.53,33.22,32,34.05,32.45,34.07Z"/>
    <path d="M35.39,36.59a21.59,21.59,0,0,0-3.13,0c-.44.06-1.09.65-1.11,1s.55,1.23.89,1.25a60,60,0,0,0,6.65,0c.39,0,.74-.88,1.1-1.35-.43-.32-.82-.87-1.28-.93A22.93,22.93,0,0,0,35.39,36.59Z"/>
  `  
}