export default {
  mount: '.icon-member-self',
  attrs: {
  viewBox: '0 0 76.54 76.54' ,
  fill: 'currentColor'
  },
  path: `
    <path d="M73.15,58.34c-1.06,2.64-3,3.51-5.84,3.5q-29-.12-58.1,0c-2.8,0-4.78-.85-5.83-3.5V24.85l1.48-.48a5.49,5.49,0,0,1,.47,1.53q0,14.95,0,29.9c0,3.37.69,4.06,4.1,4.06H67.7c2.52,0,3.38-.85,3.47-3.44.06-1.66.41-1.87,2-1.18Z"/>
    <path d="M3.38,18.34c1.07-2.64,3-3.51,5.84-3.49,17.14.08,34.29,0,51.43,0,.26,0,.58-.1.76,0a11.81,11.81,0,0,1,1.22,1c-.39.3-.78.86-1.18.87-2.79.08-5.58,0-8.36,0H51.22c0,.82,0,1.47,0,2.13C51.15,21,50,22.31,48,22.33q-9.68.09-19.36,0c-2.13,0-3.23-1.32-3.29-3.49,0-.61,0-1.21,0-2H8.76c-2.45,0-3.29.84-3.4,3.35-.07,1.68-.36,1.86-2,1.26Z"/>
    <path d="M73.15,51.83l-1.48.48a6.47,6.47,0,0,1-.48-1.68q0-14.86,0-29.71c0-3.4-.67-4.07-4.06-4.09a3,3,0,0,1-1.22-.07c-.34-.17-.76-.61-.75-.93a1.26,1.26,0,0,1,.8-.89c3.32-.8,5.6.3,7.21,3.39Z"/>
    <path d="M38.19,40.84A13.23,13.23,0,1,1,25.13,27.56,13.09,13.09,0,0,1,38.19,40.84ZM34,47.3c3.58-4.12,2.81-11.11-1.79-15.09a11.28,11.28,0,0,0-15.71,1.14c-3.6,4.15-3.78,11-.74,13.71.88-.69,1.71-1.39,2.59-2s1.75-1.16,2.64-1.74a6.18,6.18,0,0,1-.16-8.82,6,6,0,0,1,8-.17c2.65,2.36,2.68,5.51.2,8.87ZM17.6,49.08C21.32,53,29,53,32.38,49a8.4,8.4,0,0,0-7.49-4.3A8.2,8.2,0,0,0,17.6,49.08ZM28.86,38.93a3.9,3.9,0,1,0-4,3.79A4,4,0,0,0,28.86,38.93Z"/>
    <path d="M53.76,37.58c-2.84,0-5.67,0-8.51,0C43.6,37.57,43,37,43,35.37s0-3.09,0-4.64a1.7,1.7,0,0,1,1.89-1.92H62.81a1.77,1.77,0,0,1,2,2c0,1.5,0,3,0,4.49,0,1.67-.63,2.27-2.37,2.28H53.76ZM45,35.47h17.8V30.89H45Z"/>
    <path d="M54,49.44h8c.78,0,1.76,0,1.6,1.08-.06.4-1,.95-1.56,1q-8.19.1-16.39,0c-.55,0-1.09-.63-1.63-1,.53-.35,1.05-1,1.6-1C48.4,49.39,51.19,49.44,54,49.44Z"/>
    <path d="M53.9,40c2.68,0,5.37,0,8.05,0,.76,0,1.72-.14,1.69,1s-.88.94-1.62.94H45.76c-.78,0-1.76.05-1.65-1,0-.36,1-.87,1.58-.89,2.73-.09,5.47,0,8.2,0Z"/>
    <path d="M53.74,46.68c-2.69,0-5.37.05-8.05,0-.57,0-1.55-.54-1.59-.91-.1-1.06.84-1.05,1.65-1.05,5.42,0,10.85,0,16.27,0,.55,0,1.52.54,1.57.92.15,1.13-.87,1-1.63,1C59.22,46.69,56.48,46.68,53.74,46.68Z"/>
    <path d="M17.6,49.08a8.2,8.2,0,0,1,7.28-4.36A8.4,8.4,0,0,1,32.38,49C29,53,21.32,53,17.6,49.08Z"/>
    <path d="M28.86,38.93a3.92,3.92,0,0,1-4,3.79,4,4,0,1,1,4-3.79Z"/>
  `  
}