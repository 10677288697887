export default {
    mount: '.icon-logo1',
    attrs: {
        viewBox: '0 0 222.78 81.1',
        fill: 'currentColor',
    },
    path: `
   <g style="isolation:isolate;">
        <path fill="#909190" d="M34.53,15.28a2.1,2.1,0,0,1,2.19-2q1.83,0,1.47,2L35,32.64c-.39,2.13,0,3.67,1.14,4.6q2.19,1.84,6.39,1.86a12.35,12.35,0,0,0,6.25-1.63,6.6,6.6,0,0,0,3.09-4.81l3.18-17.35a2.12,2.12,0,0,1,2.19-2c1.22,0,1.71.66,1.46,2L55.54,32.67a10.41,10.41,0,0,1-4.47,7.21,16,16,0,0,1-9.13,2.49c-4.15,0-7.15-.93-9-2.77-1.61-1.55-2.13-3.88-1.56-7Z"/>
        <path fill="#909190" d="M3.23,23a10.39,10.39,0,0,1,4.11-7q3.81-2.76,10-2.74,5.36,0,8.21,2.52c1.81,1.56,2.42,4,1.83,7.22L24.23,40.36a2.08,2.08,0,0,1-2.19,2c-1.19,0-1.68-.66-1.46-2L23.75,23q.62-3.34-1.33-4.81a9.44,9.44,0,0,0-5.65-1.65,12.77,12.77,0,0,0-7.06,1.84A6.41,6.41,0,0,0,6.88,23L3.71,40.33a2.09,2.09,0,0,1-2.2,2c-1.19,0-1.68-.66-1.46-2Z"/>
        <path fill="#909190" d="M134.85,39.93c-.28,1.42-1,2.13-2.21,2.13s-1.82-.71-1.56-2.13l4.17-22.75q.6-3.28,4.48-3.27H151.2a10.32,10.32,0,0,1,6.54,1.9,5.57,5.57,0,0,1,1.86,5.75q-.91,4.82-4.46,6.72a13.78,13.78,0,0,1-6.86,1.59l-11.58,0Zm14-13.29a9,9,0,0,0,4.35-1,5.36,5.36,0,0,0,2.65-4.08,3.2,3.2,0,0,0-1.12-3.24,6.5,6.5,0,0,0-4.14-1.16H139l-1.73,9.47Z"/>
        <path fill="#909190" d="M165.57,16.92q.6-3.24,4.21-3.24l18.12,0c1.58-.14,2.26.4,2,1.61S188.86,17,187.3,17l-18.11,0-1.69,9.2,16.84,0c1.42,0,2,.54,1.84,1.6s-1,1.64-2.44,1.64l-16.83,0L165.14,39l18.12,0q2.25,0,2,1.68c-.21,1.14-1.06,1.7-2.57,1.7l-18.11,0c-2.42,0-3.42-1.08-3-3.24Z"/>
        <path fill="#909190" d="M220.86,13.8c1.45,0,2.08.58,1.88,1.64s-1.05,1.64-2.48,1.64l-9.48,0L206.5,40.44a2.1,2.1,0,0,1-2.19,2q-1.81,0-1.47-2l4.28-23.38-10.3,0c-1.47,0-2.11-.57-1.91-1.64s1-1.64,2.51-1.63Z"/>
        <path fill="#909190" d="M116.05,17.31c-6.31-3.59-9.5-7.19-13.17-12.57A10.25,10.25,0,0,0,95.58.07,14.23,14.23,0,0,0,94,0H92.85a9.8,9.8,0,0,0-2.31.26,9.62,9.62,0,0,0-4.32,2.24,12.69,12.69,0,0,0-2,2.36c-3.61,5.42-6.75,9.06-13,12.73-7,4.14-10.62,10-9.54,15.58,1.19,6.17,7.52,10.69,16.93,12.11a101.32,101.32,0,0,0,14.51,1.06h1.2A100.49,100.49,0,0,0,109,45.08c9.39-1.54,15.65-6.15,16.76-12.32C126.81,27.13,123.16,21.35,116.05,17.31Zm6.76,14.91c-.86,4.78-6.32,8.56-14.27,9.87a97.19,97.19,0,0,1-14.3,1.21H93.12a96.3,96.3,0,0,1-14.06-1c-8-1.2-13.47-4.9-14.39-9.67-.83-4.3,2.19-8.93,8.09-12.38,6.77-4,10.14-7.88,14-13.68a10.39,10.39,0,0,1,1.53-1.81,6.58,6.58,0,0,1,3-1.52,7.35,7.35,0,0,1,1.63-.17H94c.51,0,.9,0,1.24,0a7.3,7.3,0,0,1,5.12,3.37c3.93,5.75,7.36,9.61,14.18,13.49C120.49,23.33,123.58,27.91,122.81,32.22Z"/>
        <path fill="#4cc2b1" d="M100.4,25.63A1.17,1.17,0,0,0,99.53,27a5.61,5.61,0,0,1-.59,3.09,6,6,0,0,1-4.64,3.22,5.56,5.56,0,0,1-5-1.91c-.55-.64-1.11-1.32-1.62-2,2.89-1.61,5.41-3.34,5.59-3.46l.07-.06a4.75,4.75,0,0,0,1.79-2.23,4.34,4.34,0,0,0-2.32-4.73A3.73,3.73,0,0,0,89,19.5a3.73,3.73,0,0,0-3.84-.58,4.35,4.35,0,0,0-2.35,4.15,6.39,6.39,0,0,0,.14,1.3,5.75,5.75,0,0,0,.29,1.29c0,.12.08.23.11.34A10.64,10.64,0,0,0,84,27.79c.11.25.24.49.37.73a17,17,0,0,1-3.1,1.22A1.16,1.16,0,1,0,81.87,32a21,21,0,0,0,3.78-1.5c.62.88,1.29,1.69,1.92,2.43a7.77,7.77,0,0,0,6,2.77c.34,0,.68,0,1-.06A8.27,8.27,0,0,0,101,31.15a7.76,7.76,0,0,0,.79-4.65A1.17,1.17,0,0,0,100.4,25.63Zm-14,1.82c-.12-.21-.23-.43-.33-.65a12.67,12.67,0,0,1-.6-1.59,6.82,6.82,0,0,1-.41-2.14,2,2,0,0,1,1-1.9A2,2,0,0,1,88.2,22a1.1,1.1,0,0,0,1.63,0A2,2,0,0,1,92,21.17a1.78,1.78,0,0,1,.93,1.28,1.4,1.4,0,0,1-.1.88,2.07,2.07,0,0,1-.76.74c-.74.45-1.47.91-2.2,1.37Q88.18,26.49,86.45,27.45Z"/>
        <path fill="#909190" d="M69.72,8.87a2.23,2.23,0,0,0,1.5.58,3,3,0,0,0,1.86-.76A2.33,2.33,0,0,0,74,6.25a1.77,1.77,0,0,0-.18-.45,1.44,1.44,0,0,0,0-.54c-.1-.4-.49-1.09-1.83-1.09h0a2.48,2.48,0,0,0-.73.11,1.92,1.92,0,0,0-2.1.8A3.19,3.19,0,0,0,69.72,8.87Z"/>
        <path fill="#909190" d="M117.66,9.07a2.43,2.43,0,0,0,1,.66,2.15,2.15,0,0,0,.7.12,2.5,2.5,0,0,0,2-1.08,2.61,2.61,0,0,0,.3-3,3.24,3.24,0,0,0-3.09-1.52,2.55,2.55,0,0,0-2.2,1.86,2.34,2.34,0,0,0,.51,2.37A2.53,2.53,0,0,0,117.66,9.07Z"/>
        <path fill="#909190" d="M4.15,80.86H3V58.18H8.68l.89.55-2,6.57c-.16.53-.16.84,0,1a8.52,8.52,0,0,1,1.91,5.6,3.2,3.2,0,0,1-1.68,2.86,4.46,4.46,0,0,1-3.66-.08ZM8.1,59.38H4.15v14a4.07,4.07,0,0,0,3,.34A1.22,1.22,0,0,0,7.92,73l.34-1.25c0-1.39-.71-3.2-2-5.43L6.16,66l.08-.34ZM13,60.9H10.56V59.7h6.6V57.39h1.18V59.7H25.2v1.2H22.56q-.7,3.47-1.41,6h4.47v1.21H9.94V66.87h4.53C14.23,65.54,13.76,63.55,13,60.9ZM13.13,81h-1.2V71.38c0-.29.13-.42.42-.42H23.66a.38.38,0,0,1,.42.42V81H22.87V79.21H13.13Zm9.74-3V72.32a.13.13,0,0,0-.15-.15H13.31c-.13,0-.21,0-.18.18V78Zm-3-11.14.92-3.37.52-2.6H14.26c.81,3.28,1.31,5.27,1.41,6Z"/>
        <path fill="#909190" d="M69.85,61.56a76.1,76.1,0,0,1-2.09,16.29l-1.31-.21a86.57,86.57,0,0,0,2.12-16.21Zm4.51-2.8V78.08a.63.63,0,0,0,.76.82h8.59a.89.89,0,0,0,.78-.34c.4-.61.61-2.62.63-5.9L86.3,73c.1,3.72-.16,6-.76,6.6a1.77,1.77,0,0,1-1.47.55H74.59a1.4,1.4,0,0,1-.84-.23,1.67,1.67,0,0,1-.6-1.58V58.76Zm4.55-1.39a71.93,71.93,0,0,1,2.68,14.85l-1.31.23a75.83,75.83,0,0,0-2.65-14.79ZM87,60.43a82,82,0,0,1,3.4,16.69l-1.31.23a85.41,85.41,0,0,0-3.38-16.6Z"/>
        <path fill="#909190" d="M141.76,57.11H143v1.78h11.1c.18,0,.26.13.24.42a22.47,22.47,0,0,1-.66,4L152.47,63l.4-2.68c0-.13,0-.18-.16-.18H132.12c-.08,0-.1,0-.1.11v3.11h-1.21V59.12a.21.21,0,0,1,.24-.23h10.71Zm-8,6.75h-1.31v-1.2h4.35V61.09H138v1.57h4v1.2h-1.7L139.51,67h3.14v1.18H131V67h3.43Zm0,17.18h-1.21V70.41a.26.26,0,0,1,.29-.26h8.09c.21,0,.32.1.32.26v9.25a1.26,1.26,0,0,1-.58,1.12c-.52.37-1.57.42-3.12.11V79.55a3.31,3.31,0,0,0,2.15.18.62.62,0,0,0,.34-.57V77.8h-6.28ZM140,73.42v-2l-.1-.06h-6.1l-.08.06v2Zm0,3.17v-2h-6.28v2ZM138.25,67l.6-2.07.21-1h-4.13l.68,3.12Zm6.55,12c0,.16.16.31.47.44a22.34,22.34,0,0,0,4,.24,12.59,12.59,0,0,0,3.2-.26c.29-.16.47-.58.6-1.34l1.15.34c-.05,1.15-.34,1.86-.78,2.12a8.22,8.22,0,0,1-3.83.61c-2.8,0-4.47-.21-5.1-.55a1.56,1.56,0,0,1-.95-1.31V68.52c0-.23.11-.34.35-.34h7.15a.16.16,0,0,0,.18-.18V66.74l-.13-.1h-7c-.34,0-.5-.18-.5-.52V61.37h1.21v1.1h6.86v1.21H144.8v1.54c0,.14,0,.21.18.21h7.18a.28.28,0,0,1,.31.32v3.09a.48.48,0,0,1-.55.55h-7l-.08.08v1.6h7.46v1.2H144.8v1.55h7.46V75H144.8V76.7h7.46v1.2H144.8Z"/>
        <path fill="#909190" d="M199.53,81h-1.1V69.1a23.13,23.13,0,0,1-2.38,6.18L194.87,75a22.43,22.43,0,0,0,2.8-7.59h-2.51V66.3h3.27V57.5h1.1v8.8h3.33v1.1h-2.72c1,1.78,2,3.59,2.82,5.45l-1.07.57c-.6-1.46-1.41-3.11-2.36-5Zm-3.11-21.74a51.17,51.17,0,0,1,1.23,5.5l-1.18.13a25.53,25.53,0,0,0-1.23-5.4Zm6.49.21a37.35,37.35,0,0,1-1.36,5.42l-1.13-.37a33.65,33.65,0,0,0,1.34-5.34Zm7.65,18.3h-5.68v-1.1h5.68V75.07H205c-.1,0-.16,0-.16-.18v-6.6a.14.14,0,0,1,.16-.16h12.23c.13,0,.18.05.18.16v6.6c0,.13,0,.18-.18.18h-5.45v1.57h5.63v1.1h-5.63v1.55h6.76v1.1H203.75v-1.1h6.81Zm7.54-12.36v1.1H203.85v-1.1ZM216.9,64H205c-.1,0-.16,0-.16-.18V58.28a.14.14,0,0,1,.16-.15H216.9c.13,0,.18,0,.18.15v5.56C217.08,64,217,64,216.9,64Zm-1-3.46V59.28l-.06-.05h-9.74l-.05.05v1.28ZM206,61.66v1.18l.05.08h9.74l.06-.08V61.66Zm4.53,7.57h-4.48l-.05,0V71h4.53Zm0,2.88H206v1.78l.05.08h4.48Zm1.2-1.1h4.43V69.28l-.05,0h-4.38Zm0,3h4.38l.05-.08V72.11h-4.43Z"/>
   </g>
    `
}