export default {
  mount: '.icon-member-points',
  attrs: {
  viewBox: '0 0 76.54 76.54' ,
  fill: 'currentColor'
  },
  path: `
    <path d="M31.66,58.23A27.22,27.22,0,0,1,22.3,5.44a1.13,1.13,0,0,1,.78,2.13A24.95,24.95,0,1,0,39.45,7.3a1.13,1.13,0,1,1,.71-2.15,27.22,27.22,0,0,1-8.5,53.08Z"/>
    <path d="M29.66,6.13a1.13,1.13,0,0,1-.08-2.26c.69-.05,1.39-.08,2.08-.08a1.13,1.13,0,0,1,0,2.27c-.64,0-1.28,0-1.91.07Z"/>
    <path d="M41,31.85a12.56,12.56,0,0,1-8.91,3.81h-5L24.57,45.2a2.86,2.86,0,0,1-3,2.38,3.34,3.34,0,0,1-2.29-.87,2.82,2.82,0,0,1-1-2.2,3.31,3.31,0,0,1,.14-.93L25.61,16.8a3.09,3.09,0,0,1,1.22-1.6,3.15,3.15,0,0,1,1.85-.76h7.44a8.79,8.79,0,0,1,6.34,2.45A8.28,8.28,0,0,1,45,23.12,11.79,11.79,0,0,1,41,31.85ZM36.12,20.78h-5l-2.3,8.53h3.31a6.46,6.46,0,0,0,4.41-2,5.73,5.73,0,0,0,2.11-4.23Q38.66,20.78,36.12,20.78Z"/>
    <path d="M69.28,61.79,63.39,55.9,55.26,64l5.89,5.89a5.75,5.75,0,0,0,8.13-8.13Z"/>
    <path d="M65.22,72.74a6.84,6.84,0,0,1-4.87-2l-5.89-5.89a1.13,1.13,0,0,1,0-1.6l8.13-8.13a1.13,1.13,0,0,1,1.6,0L70.08,61h0a6.88,6.88,0,0,1-4.87,11.75ZM56.86,64,62,69.12a4.62,4.62,0,0,0,6.53-6.53l-5.09-5.09Z"/>
    <path d="M55.26,65.17h0a1.14,1.14,0,0,1-.8-.33l-9.68-9.68a1.13,1.13,0,0,1,0-1.6l8.13-8.13a1.17,1.17,0,0,1,1.6,0l9.68,9.68a1.13,1.13,0,0,1,0,1.6l-8.13,8.13A1.14,1.14,0,0,1,55.26,65.17ZM47.18,54.35l8.08,8.08,6.53-6.53-8.08-8.08Z"/>
    <path d="M51.17,42.13a1.13,1.13,0,0,1-1-1.65,20.41,20.41,0,0,0-3.8-23.68A1.13,1.13,0,0,1,48,15.2a22.67,22.67,0,0,1,4.22,26.31A1.13,1.13,0,0,1,51.17,42.13Z"/>
    <path d="M46,48.78a1.13,1.13,0,0,1-.74-2q.59-.51,1.15-1.07a1.13,1.13,0,1,1,1.6,1.6q-.62.62-1.28,1.18A1.13,1.13,0,0,1,46,48.78Z"/>
  `  
}