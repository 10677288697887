export default {
  mount: '.icon-bar-top',
  attrs: {
  viewBox: '0 0 109.45 109.45' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="54.72" cy="54.72" r="54.72"/>
    <path fill="#fff" d="M28,68.66q-3.37,0-3.37-3.33v-19H19.86a2.45,2.45,0,0,1-2.77-2.77,2.45,2.45,0,0,1,2.77-2.77H36.11a2.45,2.45,0,0,1,2.77,2.77,2.45,2.45,0,0,1-2.77,2.77H31.32v19Q31.32,68.66,28,68.66Z"/>
    <path fill="#fff" d="M53.45,69.18a11.8,11.8,0,0,1-9.25-3.9q-3.52-3.93-3.52-10.69T44.2,44a12,12,0,0,1,9.25-3.71A12,12,0,0,1,62.7,44q3.52,3.86,3.52,10.56t-3.5,10.69A11.84,11.84,0,0,1,53.45,69.18Zm0-5.77A4.93,4.93,0,0,0,57.8,61a11.29,11.29,0,0,0,1.61-6.44,10.86,10.86,0,0,0-1.59-6.29,5.3,5.3,0,0,0-8.71,0,10.94,10.94,0,0,0-1.57,6.31A11.47,11.47,0,0,0,49.13,61,5,5,0,0,0,53.45,63.41Z"/>
    <path fill="#fff" d="M74.76,68.66q-3.37,0-3.37-3.33V43.23a2.31,2.31,0,0,1,2.43-2.43h7.34q5.09,0,7.9,1.95,3.29,2.28,3.3,7.12a8.64,8.64,0,0,1-3.3,7.27,12.27,12.27,0,0,1-7.71,2.28H78.09v5.92Q78.09,68.66,74.76,68.66Zm3.33-14.53H81q4.83,0,4.83-4.27a3.39,3.39,0,0,0-1.31-3,6.63,6.63,0,0,0-3.71-.82H78.09v8.05Z"/>
  `  
}