export default {
    mount: '.icon-user',
    attrs: {
        viewBox: '0 0 58.42 67.77',
        fill: 'currentColor'
    },
    path: `
    <path d="M45.54,39.21a1.84,1.84,0,0,0-2.6.44,1.91,1.91,0,0,0,.43,2.64l9.56,6.94.11.07a3.57,3.57,0,0,1,1.36,4.41l-4.46,8.08a.83.83,0,0,1-.07.13A3.47,3.47,0,0,1,46.71,64h-35a3.5,3.5,0,0,1-3.17-2.06l-.06-.13L4,53.71A3.57,3.57,0,0,1,5.38,49.3l.11-.07,9.56-6.94a1.91,1.91,0,0,0,.43-2.64,1.84,1.84,0,0,0-2.6-.44l-9.52,6.9A7.39,7.39,0,0,0,.65,55.34l.07.14,4.46,8.09a7.22,7.22,0,0,0,6.53,4.2h35a7.22,7.22,0,0,0,6.53-4.2l4.46-8.09s0-.09.07-.14a7.39,7.39,0,0,0-2.71-9.23Z" />
    <path d="M29.21,42.32c9.32,0,17.49-10.74,17.49-23C46.7,8.14,39.34,0,29.21,0S11.72,8.14,11.72,19.35C11.72,31.58,19.89,42.32,29.21,42.32ZM43,19.35c0,10-6.56,19.18-13.76,19.18S15.45,29.39,15.45,19.35c0-9.16,5.66-15.56,13.76-15.56S43,10.19,43,19.35Z" />
    <path d="M45.54,39.21a1.84,1.84,0,0,0-2.6.44,1.91,1.91,0,0,0,.43,2.64l9.56,6.94.11.07a3.57,3.57,0,0,1,1.36,4.41l-4.46,8.08a.83.83,0,0,1-.07.13A3.47,3.47,0,0,1,46.71,64h-35a3.5,3.5,0,0,1-3.17-2.06l-.06-.13L4,53.71A3.57,3.57,0,0,1,5.38,49.3l.11-.07,9.56-6.94a1.91,1.91,0,0,0,.43-2.64,1.84,1.84,0,0,0-2.6-.44l-9.52,6.9A7.39,7.39,0,0,0,.65,55.34l.07.14,4.46,8.09a7.22,7.22,0,0,0,6.53,4.2h35a7.22,7.22,0,0,0,6.53-4.2l4.46-8.09s0-.09.07-.14a7.39,7.39,0,0,0-2.71-9.23Z" />
    <path d="M29.21,42.32c9.32,0,17.49-10.74,17.49-23C46.7,8.14,39.34,0,29.21,0S11.72,8.14,11.72,19.35C11.72,31.58,19.89,42.32,29.21,42.32ZM43,19.35c0,10-6.56,19.18-13.76,19.18S15.45,29.39,15.45,19.35c0-9.16,5.66-15.56,13.76-15.56S43,10.19,43,19.35Z" />`
}