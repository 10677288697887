export default {
  mount: '.icon-bar_line_2021',
  attrs: {
  viewBox: '0 0 109.4 109.4' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="54.7" cy="54.7" r="54.7"/>
    <path d="M29.6,80.5L29.6,80.5C29.6,80.5,29.6,80.5,29.6,80.5L29.6,80.5z"/>
    <path fill="#38b09c" d="M87.6,11c6.9,9.1,11,20.5,11,32.8C98.5,74,74,98.5,43.8,98.5c-12.3,0-23.7-4.1-32.8-11
		c10,13.3,25.9,21.9,43.8,21.9c30.2,0,54.7-24.5,54.7-54.7C109.4,36.8,100.9,20.9,87.6,11z"/>
    <path d="M94,93.6L94,93.6C94,93.6,94,93.6,94,93.6L94,93.6z"/>
    <path fill="#fff" d="M77,32.9c-6.9-5.4-14.8-7.8-24.5-7.9c-1.2,0.1-3.3,0.2-5.3,0.5c-1.3,0.2-2.5,0.4-3.8,0.8
			c-7.4,2-13.9,5.6-18.6,11.8c-4.2,5.5-5.9,11.8-4.5,18.7c1.2,6.3,4.7,11.2,9.7,15.1c5.2,4.1,11.2,6.3,17.7,7.3
			c2.5,0.4,4.1,1.1,3.5,4.5c-0.2,1.2-0.4,2.4-0.6,3.6c-0.3,1.5,0.4,2.2,1.9,1.7c0.8-0.3,1.6-0.6,2.3-1C61,84.4,66.9,80.4,72.4,76
			c4.2-3.4,8.2-7.1,11.1-11.7c3.1-5,4.2-10.3,3.1-16.1C85.4,41.8,82,36.8,77,32.9z M41.3,60.4c0,0.5-0.2,0.8-0.8,0.8
			c-3.1,0-6.3,0-9.4,0c-0.5,0-0.8-0.3-0.8-0.8c0-2.5,0-4.9,0-7.4c0-2.4,0-4.9,0-7.3c0-0.6,0.2-0.8,0.8-0.8c0.7,0,1.5,0,2.2,0
			c0.5,0,0.7,0.2,0.7,0.7c0,3.8,0,7.7,0,11.5c0,0.3,0,0.3,0.3,0.3c2.1,0,4.1,0,6.2,0c0.5,0,0.8,0.2,0.8,0.7
			C41.3,58.9,41.3,59.6,41.3,60.4z M47.1,60.4c0,0.5-0.2,0.8-0.8,0.8c-0.7,0-1.4,0-2.1,0c-0.1,0-0.2,0-0.2,0
			c-0.4-0.1-0.6-0.3-0.6-0.7c0-2.5,0-4.9,0-7.4c0-2.4,0-4.9,0-7.3c0-0.6,0.2-0.8,0.8-0.8c0.7,0,1.5,0,2.2,0c0.5,0,0.7,0.2,0.7,0.7
			C47.1,50.5,47.1,55.5,47.1,60.4z M63.7,60.3c0,0.6-0.3,0.8-0.8,0.8c-0.7,0-1.5,0-2.2,0c-0.3,0-0.5-0.1-0.7-0.4
			c-1.2-1.6-2.3-3.1-3.5-4.7c-1-1.4-2.1-2.8-3.1-4.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0-0.1,0c0,0.1,0,0.2,0,0.3c0,2.8,0,5.6,0,8.4
			c0,0.6-0.2,0.8-0.8,0.8c-0.7,0-1.5,0-2.2,0c-0.5,0-0.7-0.2-0.7-0.7c0-4.9,0-9.8,0-14.8c0-0.5,0.3-0.7,0.7-0.7c0.8,0,1.6,0,2.4,0
			c0.3,0,0.5,0.2,0.6,0.4c1.4,2,2.9,3.9,4.3,5.9c0.8,1,1.5,2.1,2.3,3.1c0,0.1,0.1,0.1,0.1,0.2l0.1,0c0-0.1,0-0.2,0-0.2
			c0-2.8,0-5.7,0-8.5c0-0.6,0.2-0.8,0.8-0.8c0.7,0,1.4,0,2.1,0c0.6,0,0.8,0.3,0.8,0.8C63.7,50.6,63.7,55.4,63.7,60.3z M77.1,47.8
			c0,0.5-0.2,0.7-0.7,0.7c-2.1,0-4.1,0-6.2,0c-0.2,0-0.3,0.1-0.3,0.3c0,0.7,0,1.4,0,2.1c0,0.2,0.1,0.3,0.3,0.3c2,0,4.1,0,6.1,0
			c0.6,0,0.9,0.3,0.9,0.9c0,0.7,0,1.3,0,2c0,0.6-0.2,0.8-0.8,0.8c-2.1,0-4.1,0-6.2,0c-0.2,0-0.3,0-0.3,0.3c0,0.7,0,1.4,0,2.1
			c0,0.2,0.1,0.2,0.2,0.2c2,0,4.1,0,6.1,0c0.6,0,0.8,0.2,0.8,0.8c0,0.7,0,1.4,0,2.1c0,0.5-0.2,0.7-0.7,0.7c-3.2,0-6.3,0-9.5,0
			c-0.5,0-0.8-0.2-0.8-0.8c0-2.5,0-4.9,0-7.4h0c0-2.4,0-4.8,0-7.3c0-0.1,0-0.3,0-0.4c0.1-0.3,0.3-0.4,0.7-0.4c0.9,0,1.8,0,2.7,0
			c2.3,0,4.6,0,6.8,0c0.6,0,0.8,0.2,0.8,0.8C77.1,46.4,77.1,47.1,77.1,47.8z"/>
    <path d="M46.4,44.9c-0.7,0-1.5,0-2.2,0c-0.6,0-0.8,0.2-0.8,0.8c0,2.4,0,4.9,0,7.3c0,2.5,0,4.9,0,7.4
				c0,0.4,0.2,0.7,0.6,0.7c0.1,0,0.2,0,0.2,0c0.7,0,1.4,0,2.1,0c0.5,0,0.8-0.2,0.8-0.8c0-4.9,0-9.8,0-14.7
				C47.1,45.1,46.9,44.9,46.4,44.9z"/>
    <path d="M40.5,57.5c-2.1,0-4.1,0-6.2,0c-0.3,0-0.3,0-0.3-0.3c0-3.8,0-7.7,0-11.5c0-0.5-0.2-0.7-0.7-0.7
				c-0.7,0-1.5,0-2.2,0c-0.6,0-0.8,0.2-0.8,0.8c0,2.4,0,4.9,0,7.3c0,2.5,0,4.9,0,7.4c0,0.5,0.3,0.8,0.8,0.8c3.1,0,6.3,0,9.4,0
				c0.5,0,0.8-0.2,0.8-0.8c0-0.7,0-1.4,0-2.2C41.3,57.7,41.1,57.5,40.5,57.5z"/>
    <path d="M62.9,44.9c-0.7,0-1.4,0-2.1,0c-0.6,0-0.8,0.2-0.8,0.8c0,2.8,0,5.7,0,8.5c0,0.1,0,0.2,0,0.2l-0.1,0
				c0-0.1-0.1-0.1-0.1-0.2c-0.8-1-1.5-2.1-2.3-3.1c-1.4-1.9-2.9-3.9-4.3-5.9c-0.2-0.2-0.4-0.4-0.6-0.4c-0.8,0-1.6,0-2.4,0
				c-0.5,0-0.7,0.3-0.7,0.7c0,4.9,0,9.8,0,14.8c0,0.5,0.2,0.7,0.7,0.7c0.7,0,1.5,0,2.2,0c0.6,0,0.8-0.2,0.8-0.8c0-2.8,0-5.6,0-8.4
				c0-0.1,0-0.2,0-0.3c0,0,0,0,0.1,0c0,0.1,0.1,0.1,0.1,0.2c1,1.4,2.1,2.8,3.1,4.2c1.2,1.6,2.3,3.1,3.5,4.7c0.2,0.2,0.4,0.4,0.7,0.4
				c0.7,0,1.5,0,2.2,0c0.6,0,0.8-0.3,0.8-0.8c0-4.9,0-9.7,0-14.6C63.7,45.2,63.5,44.9,62.9,44.9z"/>
    <path d="M76.3,44.9c-2.3,0-4.6,0-6.8,0c-0.9,0-1.8,0-2.7,0c-0.3,0-0.6,0.2-0.7,0.4c0,0.1,0,0.3,0,0.4
				c0,2.4,0,4.8,0,7.3h0c0,2.5,0,4.9,0,7.4c0,0.5,0.2,0.8,0.8,0.8c3.2,0,6.3,0,9.5,0c0.5,0,0.7-0.2,0.7-0.7c0-0.7,0-1.4,0-2.1
				c0-0.6-0.2-0.8-0.8-0.8c-2,0-4.1,0-6.1,0c-0.2,0-0.2-0.1-0.2-0.2c0-0.7,0-1.4,0-2.1c0-0.2,0.1-0.3,0.3-0.3c2.1,0,4.1,0,6.2,0
				c0.6,0,0.8-0.2,0.8-0.8c0-0.7,0-1.3,0-2c0-0.6-0.3-0.9-0.9-0.9c-2,0-4.1,0-6.1,0c-0.2,0-0.3-0.1-0.3-0.3c0-0.7,0-1.4,0-2.1
				c0-0.2,0-0.3,0.3-0.3c2.1,0,4.1,0,6.2,0c0.5,0,0.7-0.2,0.7-0.7c0-0.7,0-1.4,0-2.1C77.1,45.1,76.8,44.9,76.3,44.9z"/>
  `  
}