export default {
    mount: '.nav-btn',
    attrs: {
        viewBox: '0 0 54.76 42.22',
        fill: 'currentColor',
    },
    path: `
    <path d="M52.38,37.46h-50a2.38,2.38,0,0,0,0,4.76h50a2.38,2.38,0,0,0,0-4.76Z"/>
    <path d="M2.38,4.76h50a2.38,2.38,0,0,0,0-4.76h-50a2.38,2.38,0,0,0,0,4.76Z"/>
    <path d="M52.38,18.73H14.65a2.38,2.38,0,1,0,0,4.76H52.38a2.38,2.38,0,0,0,0-4.76Z"/>
    <path d="M2.76,18.76a2.37,2.37,0,0,0-2.06.67A2.34,2.34,0,0,0,0,21.49a2.4,2.4,0,0,0,2,2,2.38,2.38,0,1,0,.77-4.7Z"/>
    `
}