export default {
  mount: '.icon-dog-face',
  attrs: {
  viewBox: '0 0 88 88' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="27.72" cy="13.43" r="4.77"/>
    <circle cx="60.25" cy="13.41" r="4.77"/>
    <path d="M50.94,26l.48.2a3.89,3.89,0,0,1,1.82,5.7c-2.27,3.52-4.59,7-6.93,10.47A1.58,1.58,0,0,0,46,43.7,13,13,0,0,0,56,54.37a12.06,12.06,0,0,0,12.74-6.19,14.5,14.5,0,0,0,1.84-6.91A1.77,1.77,0,0,1,73,39.46a2,2,0,0,1,1.08,2,17.44,17.44,0,0,1-5.88,13.08,14.93,14.93,0,0,1-9.43,3.84l-.59.06c0,.27,0,.52,0,.77,0,2.34.07,4.7-.15,7-.61,6.3-5.54,11.82-11.35,12.89-7.9,1.45-15-3.86-16.52-12.35a19,19,0,0,1-.21-2.83c0-1.82,0-3.63,0-5.41-1.25-.2-2.46-.31-3.64-.59C20,56.43,15,50.61,14.16,43.66L14,42V41a2.29,2.29,0,0,1,1.11-1.46c1.22-.56,2.42.34,2.46,1.9a13.41,13.41,0,0,0,4.2,9.81c3.69,3.44,7.94,4.33,12.49,2.5s7-5.39,7.89-10.41a.94.94,0,0,0-.12-.62c-2.42-3.64-4.89-7.24-7.24-10.92a3.85,3.85,0,0,1,1.81-5.56l.53-.22ZM33.44,58q0,3,0,6a11.35,11.35,0,0,0,3.74,8.8,9.51,9.51,0,0,0,10.66,1.94,10.55,10.55,0,0,0,6.6-8.89,66.21,66.21,0,0,0,.17-7.33c0-.19,0-.38,0-.57a16.28,16.28,0,0,1-8.8-5.92c0,3.71,0,7.24,0,10.76a3.69,3.69,0,0,1-.06.82,1.76,1.76,0,0,1-2,1.42,1.86,1.86,0,0,1-1.52-1.94c0-3.43,0-6.85,0-10.27V52A16.18,16.18,0,0,1,33.44,58ZM44,39.29l6.25-9.46H37.76Z"/>
  `  
}