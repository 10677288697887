export default {
    mount: '.icon-top',
    attrs: {
        viewBox: '0 0 109.45 109.45',
        fill: 'currentColor',
    },
    path: `
    <circle cx="54.72" cy="54.72" r="54.72" transform="translate(-11.11 95.41) rotate(-76.72)"/>
    <path fill="#fff" d="M38.23,44.88a1.36,1.36,0,0,1-1.55,1.53H29V72.69a1.37,1.37,0,0,1-1.54,1.56,1.38,1.38,0,0,1-1.55-1.56V46.41H18.15a1.55,1.55,0,1,1,0-3.09H36.68A1.38,1.38,0,0,1,38.23,44.88Z"/>
    <path fill="#fff" d="M70.2,58.78A15.52,15.52,0,0,1,54.73,74.25,15.5,15.5,0,0,1,39.27,58.78,15.5,15.5,0,0,1,54.73,43.32,15.52,15.52,0,0,1,70.2,58.78Zm-3.09,0A12.39,12.39,0,0,0,54.73,46.41,12.41,12.41,0,0,0,42.35,58.78,12.41,12.41,0,0,0,54.73,71.16,12.39,12.39,0,0,0,67.11,58.78Z"/>
    <path fill="#fff" d="M74.3,72.69V44.88a1.51,1.51,0,0,1,.47-1.09,1.49,1.49,0,0,1,1.08-.47h7.72a9.28,9.28,0,0,1,6.56,15.85,9,9,0,0,1-6.57,2.72H78.92a1.37,1.37,0,0,1-1.54-1.55V72.69a1.54,1.54,0,1,1-3.08,0Zm3.08-12.35a1.36,1.36,0,0,1,1.54-1.54h4.64a6.2,6.2,0,0,0,0-12.39H77.38Z"/>
    `
}