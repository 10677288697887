export default {
    mount: '.icon-search',
    attrs: {
        viewBox: '0 0 41 51'
    },
    path: `
    <path fill="#5a5b5b" d="M36.69,39.43l-5.32-5.89a15.54,15.54,0,1,0-3.08,2.38L33.8,42a1.92,1.92,0,0,0,1.44.65,2,2,0,0,0,1.45-3.25ZM8.3,22.76A12.2,12.2,0,1,1,20.5,35,12.2,12.2,0,0,1,8.3,22.76Z"/>
    <circle fill="currentColor" cx="20.3" cy="22.7" r="12.2"/>
    `
}
