export default {
  mount: '.icon-bar_binding_2021',
  attrs: {
  viewBox: '0 0 109.4 109.4' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="54.7" cy="54.7" r="54.7"/>
    <path fill="#38b09c" d="M87.6,11c6.9,9.1,11,20.5,11,32.8C98.5,74,74,98.5,43.8,98.5c-12.3,0-23.7-4.1-32.8-11
		c10,13.3,25.9,21.9,43.8,21.9c30.2,0,54.7-24.5,54.7-54.7C109.4,36.8,100.9,20.9,87.6,11z"/>
    <path fill="#fff" d="M34.9,51.4c-1.2-1-1.3-2.1-0.2-3.5c0.9-1.4,1.5-3.1,1.8-5.1h-2.3c0.3,1.2,0.6,2.1,0.7,2.7
				c0.2,0.9-0.1,1.5-1.1,1.8c-0.6,0.2-1.1,0.2-1.4,0.1c-0.3-0.2-0.6-0.5-0.7-1.2c-0.2-1.1-0.5-2.1-0.7-2.8v4.5v2.7
				c0,1.2-0.6,1.8-1.8,1.8c-1.2,0-1.8-0.6-1.8-1.8v-5.5c-0.2,1.3-0.4,2.3-0.6,3.2c-0.2,0.6-0.4,1-0.8,1.1c-0.3,0.1-0.8,0.1-1.3-0.2
				c-0.5-0.3-0.9-0.5-1-0.8c-0.1-0.3-0.1-0.7,0-1.2c0.3-1.1,0.5-2.3,0.6-3.7c0-0.7,0.3-1.2,0.7-1.6c0.4-0.3,1-0.4,1.6-0.3l0.8,0.1
				v-2l-1.7,0.2c-1.3,0.1-2-0.5-2.2-1.8c-0.1-1.3,0.5-2,1.8-2.1h0.1c0.2-0.3,0.5-0.8,1-1.5c0.1-0.2,0.2-0.4,0.3-0.5
				c-0.7-1-1.4-2-2.3-3c-0.3-0.3-0.4-0.9-0.3-1.7c0.1-0.6,0.3-1.2,0.6-1.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.4-0.1,0.5,0l0,0
				c0.3-0.9,0.7-1.9,1.1-3.1c0.2-0.7,0.4-1.1,0.8-1.3c0.4-0.2,0.9-0.2,1.6,0c1.3,0.4,1.6,1.3,1,2.6c-0.7,1.6-1.4,3-2,4.4
				c0.1,0.1,0.3,0.3,0.5,0.6c0.4-0.7,0.8-1.6,1.3-2.6c0.5-1.1,1.3-1.4,2.4-0.9c1.2,0.5,1.5,1.3,0.8,2.5c-0.7,1.2-1.5,2.5-2.5,4
				l1.1-0.4c0.6-0.2,1,0,1.3,0.5c0.9,2,1.5,3.8,1.9,5.4h1.9c0-0.5,0-1.2,0-2.1v-0.2h-0.3c-1.3,0-2-0.7-2-2c0-1.3,0.7-2,2-2h0.3v-2.1
				h-0.7c-1.3,0-2-0.7-2-2c0-1.3,0.7-2,2-2h0.7v-1.8c0-1.3,0.6-2,1.9-2c1.3,0,1.9,0.7,1.9,2v1.8h0.5c1.3,0,2,0.7,2,2
				c0,1.4-0.7,2-2,2h-0.5v2.1H41c1.3,0,2,0.7,2,2c0,1.3-0.7,2-2,2h-0.2v0.2c0,0.9,0,1.6,0,2.1h0.6c1.3,0,2,0.7,2,2c0,1.4-0.7,2-2,2
				h-0.9c-0.3,3.3-1.2,6-2.6,8.1C37,52.2,36,52.3,34.9,51.4z M29.1,35.8l1.5-0.1c0-0.1-0.1-0.4-0.2-0.7c-0.1-0.3-0.2-0.5-0.3-0.7
				c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.2,0.3-0.3,0.4C29.5,35.2,29.3,35.5,29.1,35.8z M34.1,42.7v-2.8l-1.2,0.5
				c-0.3,0.1-0.6,0.1-0.8,0c-0.2-0.1-0.4-0.3-0.4-0.6c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.3l-0.6,0.1v2.6l1.9-0.6
				c0.5-0.1,0.8,0,0.9,0.5l0,0v0.1c0,0.1,0.1,0.2,0.1,0.4C34.1,42.5,34.1,42.6,34.1,42.7z M45.7,52.4c-1.4,0-2-0.7-2-2V25.7
				c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6h1h4.5c0.7,0,1.2,0.2,1.5,0.6c0.3,0.4,0.5,1,0.4,1.7c-0.3,2.5-0.8,5.3-1.5,8.4
				c1.3,3.1,1.9,5.5,1.9,7.2c0,2.7-0.5,4.5-1.5,5.5c-0.4,0.4-1,0.6-1.7,0.8c-0.1,0-0.3,0-0.5,0.1c-0.1,0-0.2,0-0.3,0
				c-0.4,0-0.7-0.2-0.7-0.6c0-1-0.2-2-0.6-2.9c-0.1-0.1-0.1-0.2,0-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0
				c0.3,0,0.4-0.1,0.5-0.2c0.3-0.3,0.4-1.1,0.4-2.4c0-1.6-0.5-3.8-1.6-6.5c0.3-2.5,0.6-4.8,0.9-7c0-0.4-0.1-0.6-0.6-0.6h-0.1
				c-0.4,0-0.5,0.2-0.5,0.5v22.4C47.7,51.8,47.1,52.4,45.7,52.4z"/>
    <path fill="#fff" d="M76.2,52.2c-3,0-5.4-0.3-7.2-1c-2.2-0.8-3.9-2.1-5.3-4.1c-0.6,1.4-1.4,2.7-2.3,3.8c-0.6,0.6-1.1,0.9-1.6,1
				c-0.5,0.1-1.1-0.2-1.7-0.7c-0.8-0.6-1.1-1.3-1.1-1.9c0-0.6,0.4-1.2,1.1-2c1.4-1.8,2.3-4.2,2.9-7.3c0.1-0.9,0.4-1.5,0.9-2
				c0.5-0.5,1.1-0.7,1.9-0.6c1.6,0.1,2.3,1.1,2,2.7c-0.1,0.7-0.2,1.2-0.3,1.5c0.8,2,1.9,3.4,3.4,4.3v-10h-3.9
				c-1.5,0-2.2-0.7-2.2-2.2c0-1.5,0.7-2.2,2.2-2.2h6.2h6.2c1.5,0,2.2,0.7,2.2,2.2c0,1.5-0.7,2.2-2.2,2.2h-3.8v3.3h6.1
				c1.4,0,2.2,0.7,2.2,2.2c0,1.5-0.7,2.2-2.2,2.2h-6.1v3.7c0.8,0.1,1.7,0.1,2.7,0.1c2.6,0,4.7,0,6.2,0c1.2,0,1.9,0.2,2.3,0.6
				c0.4,0.4,0.4,1.2,0.1,2.2c-0.2,0.7-0.6,1.2-1.1,1.5c-0.4,0.3-1.1,0.4-1.8,0.4H76.2z M59.9,33.7c-1.5,0-2.3-0.8-2.3-2.3v-4.3
				c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6h9.2v-0.5c0-1.6,0.8-2.5,2.5-2.5c1.6,0,2.5,0.8,2.5,2.5v0.5h9.3
				c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,0.9,0.6,1.5v4.2c0,1.6-0.8,2.4-2.4,2.4c-1.6,0-2.4-0.8-2.4-2.4v-1.9h-9.1h-8.4
				c-0.5,0-0.8,0.3-0.8,0.8v1.2C62.1,33,61.4,33.7,59.9,33.7z"/>
    <path fill="#fff" d="M40.9,86.9c-2.6,0-4.3-0.5-5.3-1.4c-0.9-0.9-1.4-2.7-1.4-5.2v-1.1l-7.2,1c-1.5,0.2-2.3-0.5-2.5-2
				c-0.2-1.5,0.5-2.4,2-2.5l7.8-1V72l-6.1,0.8c-1.4,0.2-2.3-0.4-2.5-1.9s0.4-2.3,1.9-2.5l6.7-0.9v-2.8c-2.1,0.3-4.2,0.7-6.4,1
				c-0.7,0.1-1.3,0-1.7-0.2c-0.4-0.2-0.7-0.7-0.9-1.3c-0.5-1.5,0.2-2.4,2-2.7c6.9-1,12.6-2.3,17.3-3.9c0.6-0.2,1.1-0.3,1.5-0.1
				c0.4,0.1,0.8,0.5,1.3,1c1.6,1.6,1.3,2.7-0.7,3.4c-2.5,0.8-5.1,1.4-7.6,2v3.1l8.9-1.2c1.4-0.2,2.2,0.4,2.5,1.9
				c0.2,1.5-0.4,2.3-1.8,2.5l-9.5,1.3v2.8l10.8-1.3c1.4-0.1,2.2,0.5,2.4,1.9c0.2,1.5-0.4,2.3-1.8,2.5l-11.3,1.5v1.7
				c0,0.9,0.1,1.5,0.4,1.7c0.3,0.2,0.9,0.3,1.9,0.3h5.4c0.6,0,1.1-0.1,1.3-0.3c0.7-2.5,2-3.4,3.7-2.7c0.6,0.3,1.1,0.8,1.2,1.6
				c0.2,0.7,0.2,1.4,0,2.3c-0.4,1.3-1.1,2.3-2,2.8s-2.2,0.8-3.8,0.8H44H40.9z"/>
    <path fill="#fff" d="M57.9,85.4c-0.5-1.7,0.2-2.6,2.1-2.5c0.3,0,0.5-0.2,0.5-0.5v-7.6L59.3,75c-1.5,0.3-2.3-0.3-2.6-1.8
				c-0.1-0.8-0.1-1.4,0.2-1.8c0.3-0.4,0.8-0.7,1.6-0.7c0.4-0.1,1.1-0.2,2.1-0.3v-3.3c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3
				h0.1c0.6-0.7,1.1-1.7,1.8-2.9h-4c-1.4,0-2.1-0.7-2.1-2.1c0-1.4,0.7-2.1,2.1-2.1h6.6l0.5-0.2c0.2-0.1,0.4,0,0.6,0.1l2.4,1.7
				c0.3,0.2,0.3,0.5,0.2,0.8l0,0.1h5.1v-1.9c0-1.5,0.8-2.3,2.3-2.3c1.5,0,2.3,0.8,2.3,2.3v1.9h4.4c1.4,0,2.1,0.7,2.1,2.1
				c0,1.4-0.7,2.1-2.1,2.1h-2.4H77l0.5,0.3c0.3,0.1,0.3,0.3,0.1,0.5c-1.2,1.7-2.3,3-3.2,3.9c0.1,0.1,0.4,0.4,0.7,0.6
				c0.3,0.2,0.5,0.4,0.6,0.5c1.1-1,2.1-2,2.9-3.2c0.7-1.2,1.7-1.5,3-1c1.5,0.6,1.8,1.6,0.9,3c-2.8,3.8-6.6,6.9-11.3,9.2
				c-1.3,0.6-2.3,0.4-3.1-0.5c-0.6-0.7-0.8-1.3-0.6-1.9c0.2-0.5,0.7-0.9,1.6-1.3c1-0.5,2-1,3-1.6c-0.8-0.9-1.9-1.9-3.3-3l0.1,1.3
				c0,0.3-0.1,0.6-0.2,0.8c-0.2,0.2-0.4,0.4-0.7,0.4c-0.3,0.1-0.8,0.1-1.4,0.2c-0.8,0.1-1.3,0.2-1.7,0.3v8.4c0,1.1-0.1,2-0.3,2.5
				c-0.2,0.7-0.7,1.2-1.3,1.6c-0.6,0.4-1.4,0.6-2.4,0.8C59.3,87.4,58.3,86.8,57.9,85.4z M64.9,69.7l3.4-0.5l1.3-1.5
				c0.2-0.3,0.5-0.4,0.8-0.4c0.3,0,0.6,0,0.9,0.2l0,0l0,0c0.6-0.8,1.1-1.5,1.5-2.1h-2.8c-0.5,0-0.9-0.1-1.2-0.4
				c-0.3-0.3-0.4-0.7-0.4-1.2v-0.1c-0.4,0.7-1,1.6-1.7,2.7c-0.7,0.9-1.3,1.7-1.8,2.4V69.7z M68.1,85.7c-0.6-0.8-0.8-1.5-0.5-2
				c0.2-0.5,0.8-0.9,1.8-1.3c2.1-0.9,4.1-1.9,5.9-3.1c2.2-1.5,4.1-3.1,5.5-4.8c0.8-1,1.8-1.3,3-0.7c1.4,0.7,1.7,1.7,0.7,3
				c-0.8,1-1.7,2-2.7,2.9c1.3,1,2.4,2,3.4,2.9c0.5,0.6,0.7,1.1,0.7,1.6c-0.1,0.5-0.4,1-1,1.5c-0.7,0.6-1.4,0.9-1.9,0.9
				c-0.6,0-1.2-0.4-1.7-1.1c-0.8-0.8-1.8-1.8-3.1-2.9c-2,1.4-4.3,2.7-6.7,3.8C70,87,68.9,86.8,68.1,85.7z"/>
  `  
}