export default {
    webSocket: 'ws://localhost:4000',
    api: {
        baseUrl: 'http://localhost:4000/api/',
        headers: {
            'X-Client-Version': '0.0.1'
        }
    }
}

