export default {
    mount: '.icon-coupon',
    attrs: {
        viewBox: '0 0 83.89 50.22',
        fill: 'currentColor'
    },
    path: `
    <path d="M82.05,0H1.84A1.85,1.85,0,0,0,0,1.86V15.15A1.85,1.85,0,0,0,1.84,17a8.1,8.1,0,0,1,0,16.19A1.85,1.85,0,0,0,0,35.07V48.35a1.85,1.85,0,0,0,1.84,1.87H82.05a1.86,1.86,0,0,0,1.84-1.87V35.07a1.86,1.86,0,0,0-1.84-1.87,8.1,8.1,0,0,1,0-16.19,1.85,1.85,0,0,0,1.84-1.86V1.86A1.86,1.86,0,0,0,82.05,0ZM80.21,13.42l-.49.1a11.86,11.86,0,0,0,0,23.18l.49.1v9.69H27.41v-8.1a1.84,1.84,0,1,0-3.68,0v8.1h-20V36.8l.49-.1a11.86,11.86,0,0,0,0-23.18l-.49-.1V3.73H23.73v8.1a1.84,1.84,0,1,0,3.68,0V3.73h52.8Z" />
    <path d="M23.73,21.79v6.64a1.84,1.84,0,1,0,3.68,0V21.79a1.84,1.84,0,1,0-3.68,0Z" />
    <path d="M82.05,0H1.84A1.85,1.85,0,0,0,0,1.86V15.15A1.85,1.85,0,0,0,1.84,17a8.1,8.1,0,0,1,0,16.19A1.85,1.85,0,0,0,0,35.07V48.35a1.85,1.85,0,0,0,1.84,1.87H82.05a1.86,1.86,0,0,0,1.84-1.87V35.07a1.86,1.86,0,0,0-1.84-1.87,8.1,8.1,0,0,1,0-16.19,1.85,1.85,0,0,0,1.84-1.86V1.86A1.86,1.86,0,0,0,82.05,0ZM80.21,13.42l-.49.1a11.86,11.86,0,0,0,0,23.18l.49.1v9.69H27.41v-8.1a1.84,1.84,0,1,0-3.68,0v8.1h-20V36.8l.49-.1a11.86,11.86,0,0,0,0-23.18l-.49-.1V3.73H23.73v8.1a1.84,1.84,0,1,0,3.68,0V3.73h52.8Z" />
    <path d="M23.73,21.79v6.64a1.84,1.84,0,1,0,3.68,0V21.79a1.84,1.84,0,1,0-3.68,0Z" />`
}