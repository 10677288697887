export default {
    mount: '.icon-double-arrow-right-round',
    attrs: {
        viewBox: '0 0 44 44',
        fill: 'currentColor',
    },
    path: `
    <circle cx="22" cy="22" r="22" transform="translate(-7.01 10.74) rotate(-23.79)"/>
    <path fill="#fff" d="M32.55,22l-9.76,9.35a1,1,0,1,0,1.32,1.38l10.48-10a.94.94,0,0,0,0-1.38l-10.48-10a1,1,0,0,0-.66-.27.93.93,0,0,0-.69.3.94.94,0,0,0,0,1.35Z"/><path class="cls-2" d="M22.16,22,12.4,31.35a1,1,0,0,0,0,1.35.94.94,0,0,0,1.35,0l10.48-10a.94.94,0,0,0,0-1.38l-10.48-10a.93.93,0,0,0-.66-.27,1,1,0,0,0-.69.3,1,1,0,0,0,0,1.35Z"/>
    `
}