export default {
  mount: '.icon-bar_cart_2021',
  attrs: {
  viewBox: '0 0 109.4 109.4' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="54.7" cy="54.7" r="54.7"/>
    <path fill="#38b09c" d="M87.6,11c6.9,9.1,11,20.5,11,32.8C98.5,74,74,98.5,43.8,98.5c-12.3,0-23.7-4.1-32.8-11
		c10,13.3,25.9,21.9,43.8,21.9c30.2,0,54.7-24.5,54.7-54.7C109.4,36.8,100.9,20.9,87.6,11z"/>
    <path fill="#fff" d="M86.5,63.5l7-25.4c0.2-0.7,0-1.4-0.4-2c-0.4-0.6-1.1-0.9-1.8-0.9H42.9l-1.5-6c-0.3-1-1.2-1.7-2.2-1.7h-12
			c-1.3,0-2.3,1-2.3,2.3c0,1.3,1,2.3,2.3,2.3h10.2l10.2,40.5c0.3,1,1.2,1.7,2.2,1.7h34.5c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3
			H51.6l-1.1-4.5h33.9C85.4,65.2,86.3,64.5,86.5,63.5z M74.4,53.2l-1,7.5h-4.7v-7.5H74.4z M79.6,48.6l1.2-8.8h7.6l-2.4,8.8H79.6z
			 M84.7,53.2l-2.1,7.5H78l1-7.5H84.7z M76.2,39.8L75,48.6h-6.4v-8.8H76.2z M64.1,53.2v7.5h-5.4l-1-7.5H64.1z M53.2,53.2l1,7.5h-4.9
			l-1.9-7.5H53.2z M64.1,39.8v8.8h-7L56,39.8H64.1z M52.6,48.6h-6.3L44,39.8h7.4L52.6,48.6z"/>
    <path fill="#fff" d="M18.8,49.7h19c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3h-19c-1.3,0-2.3,1-2.3,2.3
			C16.5,48.7,17.5,49.7,18.8,49.7z"/>
    <path fill="#fff" d="M40.1,55c0-1.3-1-2.3-2.3-2.3H12.9c-1.3,0-2.3,1-2.3,2.3c0,1.3,1,2.3,2.3,2.3h24.9C39,57.3,40.1,56.3,40.1,55
			z"/>
    <path fill="#fff" d="M41.3,60.3h-19c-1.3,0-2.3,1-2.3,2.3c0,1.3,1,2.3,2.3,2.3h19c1.3,0,2.3-1,2.3-2.3
			C43.6,61.4,42.5,60.3,41.3,60.3z"/>
    <circle fill="#fff" cx="53.5" cy="81.4" r="5.1"/>
    <circle fill="#fff" cx="78.8" cy="81.4" r="5.1"/>
  `  
}