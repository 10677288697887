export default {
    mount: '.icon-passion-for-pets',
    attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 410.67 31.54',
    },
    path: `
    <path fill="#4cc2b1" d="M0,30V1.58A1.5,1.5,0,0,1,.48.48,1.5,1.5,0,0,1,1.58,0H9.46a9.08,9.08,0,0,1,6.69,2.77,9.14,9.14,0,0,1,2.77,6.7,9.18,9.18,0,0,1-2.77,6.69,9.13,9.13,0,0,1-6.71,2.78H4.71a1.39,1.39,0,0,1-1.56-1.59V30a1.4,1.4,0,0,1-1.58,1.58A1.4,1.4,0,0,1,0,30Zm3.15-12.6a1.38,1.38,0,0,1,1.56-1.56H9.45a6.06,6.06,0,0,0,4.46-1.85,6.06,6.06,0,0,0,1.86-4.46A6.1,6.1,0,0,0,13.91,5,6.08,6.08,0,0,0,9.45,3.14H3.15Z"/>
    <path fill="#4cc2b1" d="M50.64,30a1.39,1.39,0,0,1-.51,1.07,1.68,1.68,0,0,1-1.14.45,1.45,1.45,0,0,1-1.38-.86L43.27,22a1.69,1.69,0,0,1-.56.08H27a1.69,1.69,0,0,1-.56-.08l-4.34,8.68a1.43,1.43,0,0,1-1.35.86,1.68,1.68,0,0,1-1.17-.46A1.39,1.39,0,0,1,19.06,30a1.61,1.61,0,0,1,.19-.76l14-28.08a1.61,1.61,0,0,1,3.1,0l14,28.1A1.47,1.47,0,0,1,50.64,30ZM41.72,18.94,34.85,5.17,28,18.94Z"/>
    <path fill="#4cc2b1" d="M71.85,22.08a9.49,9.49,0,0,1-9.46,9.46,9.5,9.5,0,0,1-4.73-1.28,8.6,8.6,0,0,1-3.47-3.45A1.79,1.79,0,0,1,54,26a1.42,1.42,0,0,1,.51-1.11,1.72,1.72,0,0,1,1.16-.46,1.7,1.7,0,0,1,1.3.81c.16.24.42.6.76,1.06a6,6,0,0,0,4.71,2.1,6.32,6.32,0,0,0,6.31-6.31,6.32,6.32,0,0,0-6.31-6.31,7.57,7.57,0,0,1-5.56-2.31,7.62,7.62,0,0,1-2.31-5.58,7.61,7.61,0,0,1,2.31-5.57A7.57,7.57,0,0,1,62.39,0a7.91,7.91,0,0,1,4,1.07A7,7,0,0,1,69.2,3.92a1.8,1.8,0,0,1,.24.85,1.45,1.45,0,0,1-.5,1.09,1.65,1.65,0,0,1-1.15.45c-.35,0-1-.53-2-1.58a4.5,4.5,0,0,0-3.43-1.59,4.56,4.56,0,0,0-3.34,1.4,4.53,4.53,0,0,0-1.39,3.34,4.55,4.55,0,0,0,1.39,3.35,4.55,4.55,0,0,0,3.34,1.39,9.12,9.12,0,0,1,6.68,2.78A9.12,9.12,0,0,1,71.85,22.08Z"/>
    <path fill="#4cc2b1" d="M94.12,22.08a9.49,9.49,0,0,1-9.46,9.46,9.5,9.5,0,0,1-4.73-1.28,8.6,8.6,0,0,1-3.47-3.45,1.79,1.79,0,0,1-.24-.82,1.42,1.42,0,0,1,.51-1.11,1.54,1.54,0,0,1,2.46.35c.16.24.42.6.76,1.06a6,6,0,0,0,4.71,2.1A6.32,6.32,0,0,0,91,22.08a6.32,6.32,0,0,0-6.31-6.31,7.57,7.57,0,0,1-5.56-2.31,7.62,7.62,0,0,1-2.31-5.58A7.61,7.61,0,0,1,79.1,2.31,7.57,7.57,0,0,1,84.66,0a7.91,7.91,0,0,1,4,1.07,7.11,7.11,0,0,1,2.85,2.85,1.8,1.8,0,0,1,.24.85,1.45,1.45,0,0,1-.5,1.09,1.67,1.67,0,0,1-1.15.45c-.35,0-1-.53-2-1.58a4.58,4.58,0,0,0-6.77-.19,4.53,4.53,0,0,0-1.39,3.34,4.55,4.55,0,0,0,1.39,3.35,4.53,4.53,0,0,0,3.34,1.39,9.12,9.12,0,0,1,6.68,2.78A9.12,9.12,0,0,1,94.12,22.08Z"/>
    <path fill="#4cc2b1" d="M103.76,30a1.57,1.57,0,1,1-3.14,0V1.58a1.57,1.57,0,1,1,3.14,0Z"/>
    <path fill="#4cc2b1" d="M141.76,15.76a15.25,15.25,0,0,1-4.63,11.16,15.74,15.74,0,0,1-22.28,0,15.21,15.21,0,0,1-4.63-11.16,15.18,15.18,0,0,1,4.63-11.14,15.74,15.74,0,0,1,22.28,0A15.22,15.22,0,0,1,141.76,15.76Zm-3.15,0a12.13,12.13,0,0,0-3.7-8.91,12.58,12.58,0,0,0-17.84,0,12.14,12.14,0,0,0-3.71,8.91,12.15,12.15,0,0,0,3.71,8.93,12.6,12.6,0,0,0,17.84,0A12.14,12.14,0,0,0,138.61,15.76Z"/>
    <path fill="#4cc2b1" d="M173.44,29.93a1.42,1.42,0,0,1-1.61,1.61,1.8,1.8,0,0,1-1.39-.82L151.36,6.16V30a1.58,1.58,0,1,1-3.15,0V1.61A1.42,1.42,0,0,1,149.82,0a1.8,1.8,0,0,1,1.39.85l19.08,24.53V1.58a1.58,1.58,0,1,1,3.15,0Z"/>
    <path fill="#4cc2b1" d="M226.45,1.58a1.38,1.38,0,0,1-1.56,1.56H210.68V14.21h11a1.39,1.39,0,0,1,1.58,1.56,1.39,1.39,0,0,1-1.58,1.58h-11V30a1.58,1.58,0,1,1-3.15,0V1.58A1.5,1.5,0,0,1,208,.48,1.52,1.52,0,0,1,209.12,0h15.77A1.39,1.39,0,0,1,226.45,1.58Z"/>
    <path fill="#4cc2b1" d="M261.3,15.76a15.25,15.25,0,0,1-4.63,11.16,15.74,15.74,0,0,1-22.28,0,15.21,15.21,0,0,1-4.63-11.16,15.18,15.18,0,0,1,4.63-11.14,15.74,15.74,0,0,1,22.28,0A15.22,15.22,0,0,1,261.3,15.76Zm-3.15,0a12.13,12.13,0,0,0-3.7-8.91,12.58,12.58,0,0,0-17.84,0,12.14,12.14,0,0,0-3.71,8.91,12.15,12.15,0,0,0,3.71,8.93,12.6,12.6,0,0,0,17.84,0A12.14,12.14,0,0,0,258.15,15.76Z"/>
    <path fill="#4cc2b1" d="M286.67,29.94A1.56,1.56,0,0,1,284,31.06L271.59,18.68a2.13,2.13,0,0,1-.69-1V30a1.58,1.58,0,1,1-3.15,0V1.56A1.38,1.38,0,0,1,269.32,0h7.89a9.09,9.09,0,0,1,6.69,2.78,9.11,9.11,0,0,1,2.77,6.69,9.17,9.17,0,0,1-2.76,6.69,9.09,9.09,0,0,1-6.69,2.78l-.91,0,9.91,9.92A1.62,1.62,0,0,1,286.67,29.94ZM283.52,9.48A6.12,6.12,0,0,0,281.67,5a6.1,6.1,0,0,0-4.47-1.87h-6.3v14c.14-.86.77-1.3,1.87-1.3h4.43a6.09,6.09,0,0,0,4.47-1.85A6.09,6.09,0,0,0,283.52,9.48Z"/>
    <path fill="#4cc2b1" d="M318.7,30V1.58a1.5,1.5,0,0,1,.48-1.1A1.52,1.52,0,0,1,320.29,0h7.87a9.08,9.08,0,0,1,6.69,2.77,9.1,9.1,0,0,1,2.77,6.7,9.15,9.15,0,0,1-2.77,6.69,9.13,9.13,0,0,1-6.7,2.78h-4.74a1.39,1.39,0,0,1-1.56-1.59V30a1.58,1.58,0,1,1-3.15,0Zm3.15-12.6a1.38,1.38,0,0,1,1.56-1.56h4.74a6.09,6.09,0,0,0,4.47-1.85,6.09,6.09,0,0,0,1.85-4.46A6.12,6.12,0,0,0,332.62,5a6.1,6.1,0,0,0-4.47-1.87h-6.3Z"/>
    <path fill="#4cc2b1" d="M363,30a1.39,1.39,0,0,1-1.56,1.58H345.68a1.52,1.52,0,0,1-1.11-.48A1.55,1.55,0,0,1,344.1,30V1.58a1.53,1.53,0,0,1,.47-1.1A1.52,1.52,0,0,1,345.68,0h15.77A1.39,1.39,0,0,1,363,1.58a1.38,1.38,0,0,1-1.56,1.56H347.24V14.21h11a1.39,1.39,0,0,1,1.59,1.56,1.4,1.4,0,0,1-1.59,1.58h-11v11h14.21A1.38,1.38,0,0,1,363,30Z"/>
    <path fill="#4cc2b1" d="M389.44,1.58a1.39,1.39,0,0,1-1.58,1.56H380V30a1.58,1.58,0,1,1-3.15,0V3.14H369a1.39,1.39,0,0,1-1.59-1.56A1.4,1.4,0,0,1,369,0h18.89A1.4,1.4,0,0,1,389.44,1.58Z"/>
    <path fill="#4cc2b1" d="M410.67,22.08a9.49,9.49,0,0,1-9.46,9.46,9.53,9.53,0,0,1-4.73-1.28A8.57,8.57,0,0,1,393,26.81a1.79,1.79,0,0,1-.24-.82,1.42,1.42,0,0,1,.51-1.11,1.7,1.7,0,0,1,1.16-.46,1.73,1.73,0,0,1,1.3.81c.16.24.41.6.76,1.06a5.93,5.93,0,0,0,4.7,2.1,6.33,6.33,0,0,0,6.32-6.31,6.33,6.33,0,0,0-6.32-6.31,7.57,7.57,0,0,1-5.56-2.31,7.62,7.62,0,0,1-2.31-5.58,7.61,7.61,0,0,1,2.31-5.57A7.57,7.57,0,0,1,401.21,0a7.86,7.86,0,0,1,4,1.07A7.07,7.07,0,0,1,408,3.92a1.91,1.91,0,0,1,.23.85,1.42,1.42,0,0,1-.5,1.09,1.64,1.64,0,0,1-1.14.45c-.35,0-1-.53-2-1.58a4.5,4.5,0,0,0-3.43-1.59,4.56,4.56,0,0,0-3.34,1.4,4.57,4.57,0,0,0-1.39,3.34,4.59,4.59,0,0,0,1.39,3.35,4.55,4.55,0,0,0,3.34,1.39,9.1,9.1,0,0,1,6.68,2.78A9.08,9.08,0,0,1,410.67,22.08Z"/>
    `
}