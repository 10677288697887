export default {
    mount: '.icon-heart-round',
    attrs: {
        viewBox: '0 0 41.09 41.09',
        fill: 'currentColor'
    },
    path: `
    <circle cx="20.55" cy="20.55" r="20.55"/>
    <path fill="#fff" d="M32.77,18.75c0-3.35-1.64-6.11-4.29-7.2s-5.43-.4-7.93,1.74c-2.51-2.14-5.42-2.79-7.94-1.74s-4.29,3.85-4.29,7.2c0,6.49,6.14,11.91,11.85,14.19a1,1,0,0,0,.38.07,1,1,0,0,0,.37-.07C26.63,30.66,32.77,25.24,32.77,18.75Z"/>
    `
}