export default {
  mount: '.icon-check-round',
  attrs: {
  viewBox: '0 0 46.38 46.38' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="23.19" cy="23.19" r="23.19"/>
    <path fill="#fff" d="M20.17,36.11a2.6,2.6,0,0,1-1.81-.73L9.54,26.56A2.5,2.5,0,0,1,13.07,23l6.76,6.75L33,11.31a2.5,2.5,0,1,1,4.07,2.91L22.2,35.07A2.5,2.5,0,0,1,20.17,36.11Z"/>
  `  
}