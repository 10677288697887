export default {
  mount: '.icon-step-5',
  attrs: {
  viewBox: '0 0 72.96 77.79' ,
  fill: 'currentColor'
  },
  path: `
    <path d="M21,77.79a8.66,8.66,0,0,1-4.54-2.71,6.73,6.73,0,0,1,.62-9.81,6.69,6.69,0,0,1,9.77.11c1.85,1.83,2.87,4,2.14,6.65a7.78,7.78,0,0,1-5.67,5.59l-.5.17Zm3.34-7.3a2.11,2.11,0,0,0-2.4-2.43,2.13,2.13,0,0,0-2.46,2.44,2.1,2.1,0,0,0,2.4,2.42C23.56,72.92,24.31,72.18,24.31,70.49Z"/>
    <path d="M52.58,77.79a8.6,8.6,0,0,1-4.83-3.06,6.69,6.69,0,0,1,.69-9.25c2.7-2.72,6.35-3.05,9.24-.74,2.25,1.8,3.62,4.1,2.92,7.07a7.66,7.66,0,0,1-5.71,5.81l-.49.17Zm.87-4.87c1.67,0,2.45-.73,2.47-2.35s-.72-2.49-2.39-2.51-2.46.74-2.47,2.36S51.79,72.91,53.45,72.92Z"/>
    <path d="M58.56,38.19l-1.62,6.67c-.33,1.35-.68,2.7-1,4A2.46,2.46,0,0,1,53.22,51q-15.69,0-31.38,0a1.22,1.22,0,0,0-1.31.83c-.6,1.33-1.29,2.62-2,4.06H60.37a2.53,2.53,0,0,1,2.68,1.57,2.42,2.42,0,0,1-2.34,3.29c-3.88,0-7.75,0-11.63,0H15c-2.36,0-3.44-1.74-2.39-3.84,1.34-2.7,2.69-5.39,4-8.09a1.46,1.46,0,0,0,.15-.91C13.8,36,10.83,24.13,7.83,12.15h-1c-1.5,0-3,0-4.48,0A2.43,2.43,0,0,1,0,9.76,2.38,2.38,0,0,1,2.28,7.3c2.53,0,5.07,0,7.6,0a2.45,2.45,0,0,1,2.29,2.07c.59,2.44,1.2,4.86,1.81,7.29,0,.1.06.19.1.35h14.8a2.45,2.45,0,1,1,0,4.86H15.27c2,8.13,4.05,16.19,6.07,24.27H51.6q.88-3.57,1.79-7.18C43.34,39,33.84,29.89,34,19,34.19,9.91,41.8,1.36,51,.16A19.77,19.77,0,0,1,72.22,14.42a18,18,0,0,1-2.06,14.74A20,20,0,0,1,58.56,38.19ZM38.94,19.42A14.19,14.19,0,0,0,53.47,34,14.19,14.19,0,0,0,68,19.46,14.19,14.19,0,0,0,53.59,4.89,14.19,14.19,0,0,0,38.94,19.42Z"/>
    <path d="M51,20.84c2.67-2.68,5.23-5.23,7.78-7.8a2.68,2.68,0,0,1,2-.94,2.48,2.48,0,0,1,2,3.94,5.37,5.37,0,0,1-.51.56l-9.18,9.17c-1.38,1.38-2.68,1.38-4.07,0s-2.94-2.93-4.4-4.4a2.49,2.49,0,1,1,3.48-3.53C49.06,18.8,50,19.79,51,20.84Z"/>
  `  
}