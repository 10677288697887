export default {
  mount: '.icon-warning',
  attrs: {
  viewBox: '0 0 46.38 46.38' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="23.19" cy="23.19" r="23.19"/>
    <path fill="#fff" d="M23.19,36.44a3.69,3.69,0,0,1-2.61-1,3.48,3.48,0,0,1,0-5,3.66,3.66,0,0,1,2.59-1,3.51,3.51,0,0,1,2.59,1,3.48,3.48,0,0,1,0,5A3.69,3.69,0,0,1,23.19,36.44Zm0-9.31c-1.23,0-1.9-.55-2-1.65L20.14,15.16l-.07-2.91a2.09,2.09,0,0,1,2.24-2.31H24.9a1.47,1.47,0,0,1,1.11.42,1.36,1.36,0,0,1,.36,1.09l-.14,3.71-1,10.32C25.09,26.58,24.44,27.13,23.22,27.13Z"/>
  `  
}