import './image/click-me.png'
import './image/click-me.min.png'
import './image/click-me.min.gif'
import './image/KV.png'
import './image/KV.min.png'
import './image/food.png'
import './image/food.min.png'
import './image/instagram_FB.jpg'
import './image/instagram_FB.min.jpg'
import './image/brand-core.jpg'
import './image/brand-core.min.jpg'
import './image/commodity.jpg'
import './image/commodity.min.jpg'
import './image/dog-photo.png'
import './image/dog-photo.min.png'
import './image/benner-01.jpg'
import './image/benner-01.min.jpg'
import './image/benner-02.jpg'
import './image/benner-02.min.jpg'
import './image/benner-03.jpg'
import './image/benner-03.min.jpg'
import './image/benner-04.jpg'
import './image/benner-04.min.jpg'
import './image/shop-cat.jpg'
import './image/shop-cat.min.jpg'
import './image/shop-dog.jpg'
import './image/shop-dog.min.jpg'
import './image/pet-photo.min.jpg'
import './image/prod-01.min.jpg'
import './image/prod-02.min.jpg'
import './image/prod-03.min.jpg'
import './image/member-card.png'
import './image/medal-01.png'
import './image/medal-02.png'
import './image/medal-03.png'
import './image/evaluation-benner.png'
import './image/fb.png'
import './image/ig.png'
import './image/line.png'
import './image/latest-offers.png'
import './image/dog-special-area.png'
import './image/cat-special-area.png'
import './image/recommend-friends.png'
import './image/popular-recommendation.png'
import './image/pet-unpacking.png'
import './image/limited-time-offer.png'
import './image/novice-early-adopters.png'
import './image/bank-013-logo.png'
import './image/tappay.png'
import './image/mastercard.png'
import './image/jcb.png'
import './image/visa.png'
import './image/pet-nutrition.png'