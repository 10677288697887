export default {
    mount: '.icon-search-one',
    attrs: {
        viewBox: '0 0 68.07 69.29',
        fill: 'currentColor'
    },
    path: `
    <path d="M65.93,56.46,54.66,44.9l-3.57-3.52.11-.2a31.55,31.55,0,0,0,3.4-12.39l0-1.05,0-1a27.69,27.69,0,0,0-3-11.61L49.07,11A27.33,27.33,0,0,0,36.72,1.7a1.68,1.68,0,0,0-1.32.06,1.7,1.7,0,0,0-.9,1,1.78,1.78,0,0,0,0,1.34,1.73,1.73,0,0,0,1,.91,24.3,24.3,0,0,1-8.27,47A24.31,24.31,0,0,1,18,5.38a1.75,1.75,0,0,0,.92-2.29,1.72,1.72,0,0,0-2.26-.94A27.19,27.19,0,0,0,4.12,13.06a31,31,0,0,0-3.72,20A28,28,0,0,0,8.51,48a27.41,27.41,0,0,0,18.74,7.56,27,27,0,0,0,13.17-3.44l.21-.11,14.8,15.15a7.24,7.24,0,0,0,5.19,2.18,7.35,7.35,0,0,0,5.27-2.22,7.59,7.59,0,0,0,0-10.61ZM43.58,50l.28-.22A27.71,27.71,0,0,0,49,44.6l.21-.29L54,49.2,48.4,54.9ZM63.45,64.6a4,4,0,0,1-2.8,1.19h0a3.84,3.84,0,0,1-2.75-1.15l-7-7.27,5.61-5.69,7,7.26a3.92,3.92,0,0,1,1.12,2.81A4.07,4.07,0,0,1,63.45,64.6Z" />
    <path d="M35,24.51l1-1V34.58h-2a1.75,1.75,0,0,0,0,3.5h7.47a1.75,1.75,0,0,0,0-3.5h-2V19.27a1.74,1.74,0,0,0-1.06-1.62,1.71,1.71,0,0,0-1.88.38l-4,4a1.77,1.77,0,0,0,0,2.47A1.71,1.71,0,0,0,35,24.51Z" />
    <path d="M10.57,25.31v5a7.65,7.65,0,1,0,15.3,0v-5a7.65,7.65,0,1,0-15.3,0Zm11.85,0v5a4.2,4.2,0,1,1-8.4,0v-5a4.2,4.2,0,1,1,8.4,0Z" />
    <path d="M27.25,3.5h.08a1.75,1.75,0,0,0,1.73-1.74,1.76,1.76,0,0,0-.5-1.24A1.68,1.68,0,0,0,27.35,0h-.1a1.74,1.74,0,0,0-1.72,1.75A1.73,1.73,0,0,0,27.25,3.5Z" />
    <path d="M65.93,56.46,54.66,44.9l-3.57-3.52.11-.2a31.55,31.55,0,0,0,3.4-12.39l0-1.05,0-1a27.69,27.69,0,0,0-3-11.61L49.07,11A27.33,27.33,0,0,0,36.72,1.7a1.68,1.68,0,0,0-1.32.06,1.7,1.7,0,0,0-.9,1,1.78,1.78,0,0,0,0,1.34,1.73,1.73,0,0,0,1,.91,24.3,24.3,0,0,1-8.27,47A24.31,24.31,0,0,1,18,5.38a1.75,1.75,0,0,0,.92-2.29,1.72,1.72,0,0,0-2.26-.94A27.19,27.19,0,0,0,4.12,13.06a31,31,0,0,0-3.72,20A28,28,0,0,0,8.51,48a27.41,27.41,0,0,0,18.74,7.56,27,27,0,0,0,13.17-3.44l.21-.11,14.8,15.15a7.24,7.24,0,0,0,5.19,2.18,7.35,7.35,0,0,0,5.27-2.22,7.59,7.59,0,0,0,0-10.61ZM43.58,50l.28-.22A27.71,27.71,0,0,0,49,44.6l.21-.29L54,49.2,48.4,54.9ZM63.45,64.6a4,4,0,0,1-2.8,1.19h0a3.84,3.84,0,0,1-2.75-1.15l-7-7.27,5.61-5.69,7,7.26a3.92,3.92,0,0,1,1.12,2.81A4.07,4.07,0,0,1,63.45,64.6Z" />
    <path d="M35,24.51l1-1V34.58h-2a1.75,1.75,0,0,0,0,3.5h7.47a1.75,1.75,0,0,0,0-3.5h-2V19.27a1.74,1.74,0,0,0-1.06-1.62,1.71,1.71,0,0,0-1.88.38l-4,4a1.77,1.77,0,0,0,0,2.47A1.71,1.71,0,0,0,35,24.51Z" />
    <path d="M10.57,25.31v5a7.65,7.65,0,1,0,15.3,0v-5a7.65,7.65,0,1,0-15.3,0Zm11.85,0v5a4.2,4.2,0,1,1-8.4,0v-5a4.2,4.2,0,1,1,8.4,0Z" />
    <path d="M27.25,3.5h.08a1.75,1.75,0,0,0,1.73-1.74,1.76,1.76,0,0,0-.5-1.24A1.68,1.68,0,0,0,27.35,0h-.1a1.74,1.74,0,0,0-1.72,1.75A1.73,1.73,0,0,0,27.25,3.5Z" />
    <path d="M65.93,56.46,54.66,44.9l-3.57-3.52.11-.2a31.55,31.55,0,0,0,3.4-12.39l0-1.05,0-1a27.69,27.69,0,0,0-3-11.61L49.07,11A27.33,27.33,0,0,0,36.72,1.7a1.68,1.68,0,0,0-1.32.06,1.7,1.7,0,0,0-.9,1,1.78,1.78,0,0,0,0,1.34,1.73,1.73,0,0,0,1,.91,24.3,24.3,0,0,1-8.27,47A24.31,24.31,0,0,1,18,5.38a1.75,1.75,0,0,0,.92-2.29,1.72,1.72,0,0,0-2.26-.94A27.19,27.19,0,0,0,4.12,13.06a31,31,0,0,0-3.72,20A28,28,0,0,0,8.51,48a27.41,27.41,0,0,0,18.74,7.56,27,27,0,0,0,13.17-3.44l.21-.11,14.8,15.15a7.24,7.24,0,0,0,5.19,2.18,7.35,7.35,0,0,0,5.27-2.22,7.59,7.59,0,0,0,0-10.61ZM43.58,50l.28-.22A27.71,27.71,0,0,0,49,44.6l.21-.29L54,49.2,48.4,54.9ZM63.45,64.6a4,4,0,0,1-2.8,1.19h0a3.84,3.84,0,0,1-2.75-1.15l-7-7.27,5.61-5.69,7,7.26a3.92,3.92,0,0,1,1.12,2.81A4.07,4.07,0,0,1,63.45,64.6Z" />
    <path d="M35,24.51l1-1V34.58h-2a1.75,1.75,0,0,0,0,3.5h7.47a1.75,1.75,0,0,0,0-3.5h-2V19.27a1.74,1.74,0,0,0-1.06-1.62,1.71,1.71,0,0,0-1.88.38l-4,4a1.77,1.77,0,0,0,0,2.47A1.71,1.71,0,0,0,35,24.51Z" />
    <path d="M10.57,25.31v5a7.65,7.65,0,1,0,15.3,0v-5a7.65,7.65,0,1,0-15.3,0Zm11.85,0v5a4.2,4.2,0,1,1-8.4,0v-5a4.2,4.2,0,1,1,8.4,0Z" />
    <path d="M27.25,3.5h.08a1.75,1.75,0,0,0,1.73-1.74,1.76,1.76,0,0,0-.5-1.24A1.68,1.68,0,0,0,27.35,0h-.1a1.74,1.74,0,0,0-1.72,1.75A1.73,1.73,0,0,0,27.25,3.5Z" />
    <path d="M65.93,56.46,54.66,44.9l-3.57-3.52.11-.2a31.55,31.55,0,0,0,3.4-12.39l0-1.05,0-1a27.69,27.69,0,0,0-3-11.61L49.07,11A27.33,27.33,0,0,0,36.72,1.7a1.68,1.68,0,0,0-1.32.06,1.7,1.7,0,0,0-.9,1,1.78,1.78,0,0,0,0,1.34,1.73,1.73,0,0,0,1,.91,24.3,24.3,0,0,1-8.27,47A24.31,24.31,0,0,1,18,5.38a1.75,1.75,0,0,0,.92-2.29,1.72,1.72,0,0,0-2.26-.94A27.19,27.19,0,0,0,4.12,13.06a31,31,0,0,0-3.72,20A28,28,0,0,0,8.51,48a27.41,27.41,0,0,0,18.74,7.56,27,27,0,0,0,13.17-3.44l.21-.11,14.8,15.15a7.24,7.24,0,0,0,5.19,2.18,7.35,7.35,0,0,0,5.27-2.22,7.59,7.59,0,0,0,0-10.61ZM43.58,50l.28-.22A27.71,27.71,0,0,0,49,44.6l.21-.29L54,49.2,48.4,54.9ZM63.45,64.6a4,4,0,0,1-2.8,1.19h0a3.84,3.84,0,0,1-2.75-1.15l-7-7.27,5.61-5.69,7,7.26a3.92,3.92,0,0,1,1.12,2.81A4.07,4.07,0,0,1,63.45,64.6Z" />
    <path d="M35,24.51l1-1V34.58h-2a1.75,1.75,0,0,0,0,3.5h7.47a1.75,1.75,0,0,0,0-3.5h-2V19.27a1.74,1.74,0,0,0-1.06-1.62,1.71,1.71,0,0,0-1.88.38l-4,4a1.77,1.77,0,0,0,0,2.47A1.71,1.71,0,0,0,35,24.51Z" />
    <path d="M10.57,25.31v5a7.65,7.65,0,1,0,15.3,0v-5a7.65,7.65,0,1,0-15.3,0Zm11.85,0v5a4.2,4.2,0,1,1-8.4,0v-5a4.2,4.2,0,1,1,8.4,0Z" />
    <path d="M27.25,3.5h.08a1.75,1.75,0,0,0,1.73-1.74,1.76,1.76,0,0,0-.5-1.24A1.68,1.68,0,0,0,27.35,0h-.1a1.74,1.74,0,0,0-1.72,1.75A1.73,1.73,0,0,0,27.25,3.5Z" />`
}