export default {
  mount: '.icon-online-service',
  attrs: {
  viewBox: '0 0 139.79 139.79' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="69.9" cy="69.9" r="69.9"/>
    <path fill="#ffffff" d="M71.1,40.18c-9.51.09-17.27,2-24.22,6.62C40.24,51.18,35.43,57,33.74,65a24.39,24.39,0,0,0,1.17,14c3,7.86,8.66,13.17,16.13,16.73a41.12,41.12,0,0,0,15,3.7,2.66,2.66,0,1,1-.33,5.29c-10.13-.6-19.25-3.84-26.86-10.7A31.81,31.81,0,0,1,28.08,73.24c-1-9.49,2.08-17.67,8.47-24.62A41.43,41.43,0,0,1,58.61,36.16c12.71-2.83,24.85-1.26,36.1,5.5,7.16,4.31,12.55,10.24,15.43,18.21,3,8.17,2.12,16.05-2,23.64-3.55,6.54-8.56,11.84-14.21,16.59a100.33,100.33,0,0,1-23.58,14.67,2.69,2.69,0,0,1-3.74-1.25,2.65,2.65,0,0,1,1.52-3.58,101.79,101.79,0,0,0,13.23-7.18A74.94,74.94,0,0,0,99.16,87.27a33,33,0,0,0,6.62-12,22.7,22.7,0,0,0-2.24-17.12,30.93,30.93,0,0,0-11-11.55,39.64,39.64,0,0,0-15.23-5.72C74.84,40.44,72.35,40.31,71.1,40.18Z"/>
    <path fill="#ffffff" d="M70.41,70.3c0,2.32,0,4.64,0,7A8.88,8.88,0,0,1,70.22,79a2,2,0,0,1-1.89,1.54,2,2,0,0,1-2-1.46,6,6,0,0,1-.21-1.69c0-4.46,0-8.91,0-13.37a6.8,6.8,0,0,1,.16-1.62,2.37,2.37,0,0,1,2-1.87,2.15,2.15,0,0,1,2.43,1.11c1.63,2.84,3.24,5.69,4.86,8.54a2.59,2.59,0,0,0,.64.81v-.76c0-2.16,0-4.32,0-6.48a10,10,0,0,1,.16-1.63A2,2,0,0,1,80.23,62a6.76,6.76,0,0,1,.18,1.62q0,6.81,0,13.61a7.94,7.94,0,0,1-.14,1.46,2.05,2.05,0,0,1-1.59,1.78,2.44,2.44,0,0,1-2.6-.79,15.12,15.12,0,0,1-1.23-1.84c-1.3-2.3-2.58-4.62-3.87-6.93-.13-.23-.28-.46-.41-.68Z"/>
    <path fill="#ffffff" d="M87.48,65v3h3.29c.91,0,1.81,0,2.71,0a2.06,2.06,0,0,1,0,4.1c-1.61,0-3.22,0-4.84,0-.35,0-.7,0-1.13,0V76h6.32c1.52,0,2.33.75,2.35,2.12s-.76,2.17-2.28,2.18q-4.14,0-8.28,0c-1.82,0-2.64-.83-2.74-2.65,0-.24,0-.49,0-.74,0-4.21,0-8.42,0-12.63A8,8,0,0,1,83,62.63a2,2,0,0,1,2-1.75c3-.06,6.06-.06,9.1,0A2,2,0,0,1,96,63,1.91,1.91,0,0,1,94,65c-1.83,0-3.67,0-5.5,0Z"/>
    <path fill="#ffffff" d="M49.86,75.85h4.08a16.79,16.79,0,0,1,1.88.06,2.09,2.09,0,0,1,1.93,2.32,2,2,0,0,1-2.13,2c-2.62,0-5.25,0-7.87,0a2.17,2.17,0,0,1-2.38-2.1A7.2,7.2,0,0,1,45.29,77c0-4.37,0-8.74,0-13.12a11,11,0,0,1,.16-1.62,2.12,2.12,0,0,1,1.83-1.74,2,2,0,0,1,2.24,1.28,6.16,6.16,0,0,1,.32,2c0,3.69,0,7.38,0,11.07Z"/>
    <path fill="#ffffff" d="M63.51,70.55c0,2.32,0,4.64,0,7A5.65,5.65,0,0,1,63.29,79a2.08,2.08,0,0,1-2.08,1.54,2,2,0,0,1-2-1.51A5.72,5.72,0,0,1,59,77.38q0-6.75,0-13.51a10.29,10.29,0,0,1,.13-1.47A2.18,2.18,0,0,1,61,60.53a2.11,2.11,0,0,1,2.33,1.53,6,6,0,0,1,.2,1.53C63.52,65.91,63.51,68.23,63.51,70.55Z"/>
  `  
}