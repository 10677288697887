export default {
    mount: '.icon-evaluation',
    attrs: {
        viewBox: '0 0 70.7 68.92',
        fill: 'currentColor',
    },
    path: `
    <path d="M58.86,27.23a4.77,4.77,0,0,0-4.78.83,4.54,4.54,0,0,0-7.57,3.79c0,4,3.72,7.29,7.16,8.67a1.15,1.15,0,0,0,.41.08,1.06,1.06,0,0,0,.4-.08c3.45-1.38,7.17-4.68,7.17-8.67A4.81,4.81,0,0,0,58.86,27.23Zm.61,4.62c0,2.69-2.68,5.26-5.39,6.48-2.71-1.22-5.39-3.79-5.39-6.48a2.69,2.69,0,0,1,1.45-2.61,2,2,0,0,1,.78-.15,3.53,3.53,0,0,1,2.39,1.18,1.12,1.12,0,0,0,1.54,0,3,3,0,0,1,3.17-1A2.69,2.69,0,0,1,59.47,31.85Z"/>
    <path d="M45,18.22a3.9,3.9,0,1,0-3.91-3.9,3.8,3.8,0,0,0,.58,2L36.45,23.6a4,4,0,0,0-3.23.71L30.1,22.54a3.4,3.4,0,0,0,0-.45,3.91,3.91,0,0,0-7.81,0,3.4,3.4,0,0,0,0,.45l-3.12,1.77a3.9,3.9,0,1,0,1.53,3.1,3.4,3.4,0,0,0,0-.45l3.12-1.77a4,4,0,0,0,4.75,0L31.72,27a3.4,3.4,0,0,0,0,.45,3.91,3.91,0,0,0,7.81,0,3.86,3.86,0,0,0-.58-2l5.19-7.24A4.12,4.12,0,0,0,45,18.22Zm-8.51,9.19a.87.87,0,1,1-.87-.86A.86.86,0,0,1,36.46,27.41Zm9.37-13.09a.87.87,0,1,1-.86-.86A.87.87,0,0,1,45.83,14.32ZM25.36,22.09a.86.86,0,1,1,.86.86A.86.86,0,0,1,25.36,22.09Zm-7.65,5.32a.86.86,0,1,1-1.72,0,.86.86,0,0,1,1.72,0Z"/>
    <path d="M57.51,50.43H3.39A.35.35,0,0,1,3,50.08V8a.35.35,0,0,1,.35-.35H8.2a1.52,1.52,0,0,0,0-3H3.39A3.39,3.39,0,0,0,0,8V50.08a3.39,3.39,0,0,0,3.39,3.39H9.93v1.75A2.82,2.82,0,0,0,12.75,58h9.4L17.71,66.7a1.53,1.53,0,0,0,.66,2,1.56,1.56,0,0,0,2.05-.66L25.57,58H36.25L41.4,68.09a1.51,1.51,0,0,0,1.35.83,1.5,1.5,0,0,0,.69-.17,1.51,1.51,0,0,0,.66-2L39.67,58h9.4a2.83,2.83,0,0,0,2.82-2.82V53.47h5.62a1.52,1.52,0,1,0,0-3Zm-8.66,3V55H13V53.47Z"/>
    <path d="M12.29,7.61H58.43a.35.35,0,0,1,.35.35v9.7a1.52,1.52,0,1,0,3,0V8a3.39,3.39,0,0,0-3.39-3.39H37.08V3.06A3.07,3.07,0,0,0,34,0H27.8a3.07,3.07,0,0,0-3.06,3.06V4.57H12.29a1.52,1.52,0,1,0,0,3Zm15.49-3V3.06l0,0,6.23,0V4.57Z"/>
    <path d="M14.78,35.53a1.53,1.53,0,0,0-1.52,1.53v9a1.52,1.52,0,0,0,1.52,1.52h4.14a1.52,1.52,0,0,0,1.52-1.52v-9a1.53,1.53,0,0,0-1.52-1.53Z"/>
    <path d="M24.15,30.32a1.52,1.52,0,0,0-1.52,1.52V46.08a1.52,1.52,0,0,0,1.52,1.52h4.14a1.53,1.53,0,0,0,1.53-1.52V31.84a1.53,1.53,0,0,0-1.53-1.52Z"/>
    <path d="M33.52,35.53A1.53,1.53,0,0,0,32,37.06v9a1.53,1.53,0,0,0,1.52,1.52h4.15a1.53,1.53,0,0,0,1.52-1.52v-9a1.53,1.53,0,0,0-1.52-1.53Z"/>
    <path d="M70.12,51.64l-5.68-9.83a14,14,0,0,0,3-5.61,13.83,13.83,0,1,0-23.74,5.6,1.48,1.48,0,0,0-.81-.24,1.53,1.53,0,0,0-1.53,1.52v3A1.53,1.53,0,0,0,42.9,47.6H47a1.52,1.52,0,0,0,1.52-1.52,1.49,1.49,0,0,0-.31-.91,14.25,14.25,0,0,0,2.24.82,13.82,13.82,0,0,0,6.36.2L62.53,56a4.39,4.39,0,0,0,3.8,2.19,4.3,4.3,0,0,0,2.18-.59,4.39,4.39,0,0,0,1.61-6ZM64.51,35.41A10.8,10.8,0,1,1,48.67,23.26a10.82,10.82,0,0,1,14.76,4A10.67,10.67,0,0,1,64.51,35.41Zm.65,19.09-5.35-9.28c.4-.18.8-.39,1.19-.61a12.73,12.73,0,0,0,1.13-.73l5.35,9.28a1.29,1.29,0,0,1,.18.67A1.36,1.36,0,0,1,67,55a1.34,1.34,0,0,1-1,.13A1.33,1.33,0,0,1,65.16,54.5Z"/>
    `
}