export default {
  mount: '.icon-step-2',
  attrs: {
  viewBox: '0 0 70.87 76.59' ,
  fill: 'currentColor'
  },
  path: `
    <path d="M56.57,60.39c.15.17.18.18.19.2A90.88,90.88,0,0,1,56.7,71a6.24,6.24,0,0,1-6.52,5.56H6.64A6.42,6.42,0,0,1,0,69.93q0-26.4,0-52.8c0-1.4,0-2.81,0-4.22A6.38,6.38,0,0,1,6.2,6.71c1.75,0,3.5,0,5.26,0,.67,0,.87.24.85.89,0,.9,0,1.81.06,2.71,0,.64-.2.91-.87.89-1.57,0-3.14,0-4.71,0s-2.31.65-2.31,2.33V69.72c0,1.75.66,2.4,2.43,2.4h43c1.77,0,2.44-.68,2.44-2.48,0-1.44,0-2.87,0-4.31a1.07,1.07,0,0,1,.18-.83Z"/>
    <path d="M28.42,13H16.62c-1.63,0-2.27-.65-2.28-2.27,0-1.3,0-2.6,0-3.91a1.85,1.85,0,0,1,2-2h.08c2.13.47,3.22-.61,4.14-2.43C22-.36,21.52,0,24.49,0s6,0,8.92,0a1.8,1.8,0,0,1,1.81,1c.55,1,1.18,2,1.73,3.05a1.13,1.13,0,0,0,1.14.67c.83,0,1.65,0,2.47,0a1.8,1.8,0,0,1,1.91,1.91c0,1.4,0,2.81,0,4.22a1.86,1.86,0,0,1-2.1,2C36.39,13,32.4,13,28.42,13Z"/>
    <path d="M68,45.79c-.16.17-.27.32-.4.45L48.53,65.36c-.32.33-.51.4-.88,0Q45.36,63,43,60.7c-.37-.35-.32-.54,0-.87q9.57-9.55,19.11-19.12c.32-.32.52-.42.89,0,1.54,1.58,3.11,3.13,4.67,4.69C67.8,45.49,67.9,45.64,68,45.79Z"/>
    <path d="M52.45,47.05a1.61,1.61,0,0,1-.1-.94q0-16.22,0-32.45a3,3,0,0,0-.23-1.48,1.9,1.9,0,0,0-1.85-1c-1.64,0-3.29,0-4.94,0-.57,0-.82-.16-.8-.77,0-1,0-2,0-3,0-.46.17-.68.65-.67,2,0,4-.09,6,0a6.35,6.35,0,0,1,5.67,6.43q0,12.24,0,24.48c0,1.51,0,3,0,4.54a1.3,1.3,0,0,1-.35,1c-1.21,1.2-2.4,2.41-3.6,3.62A.53.53,0,0,1,52.45,47.05Z"/>
    <path d="M37.54,36.41h8.53a4.22,4.22,0,0,1,.94.08A1.48,1.48,0,0,1,48.16,38,1.46,1.46,0,0,1,47,39.44a4.25,4.25,0,0,1-.95.08h-17a3.31,3.31,0,0,1-.94-.1,1.51,1.51,0,0,1,0-2.91,4.1,4.1,0,0,1,1.11-.1h8.36Z"/>
    <path d="M37.56,24.39h8.77c1.15,0,1.85.61,1.83,1.54s-.72,1.51-1.87,1.52c-1.86,0-3.72,0-5.58,0H29.15a5.19,5.19,0,0,1-.88,0,1.51,1.51,0,0,1,.12-3c.27,0,.54,0,.8,0Z"/>
    <path d="M37.67,48.49h8.69a1.7,1.7,0,0,1,1.43.54A1.44,1.44,0,0,1,48,50.6a1.52,1.52,0,0,1-1.53.94H28.72A1.54,1.54,0,0,1,27,50.25a1.51,1.51,0,0,1,1.67-1.75c3,0,6,0,9,0Z"/>
    <path d="M13.21,43a1.39,1.39,0,0,1-1.11-.5C11,41.37,10,40.26,8.93,39.12a1.25,1.25,0,0,1,0-1.9,1.27,1.27,0,0,1,1.9.18c.45.45.88.92,1.31,1.39,1,1.08,1,1.08,2.08,0l5-5.13a4.78,4.78,0,0,1,.46-.44,1.18,1.18,0,0,1,1.66.07,1.21,1.21,0,0,1,.16,1.66,3.22,3.22,0,0,1-.43.48l-6.73,6.9C14,42.67,13.68,43,13.21,43Z"/>
    <path d="M13,31a1.43,1.43,0,0,1-1-.52C11,29.3,10,28.18,8.92,27.05a1.25,1.25,0,0,1,0-1.85,1.3,1.3,0,0,1,1.91.11c.65.67,1.3,1.33,1.91,2,.35.41.56.37.92,0,1.87-2,3.78-3.89,5.67-5.83a1.79,1.79,0,0,1,.83-.6,1.23,1.23,0,0,1,1.35.44,1.19,1.19,0,0,1,.06,1.48,4.22,4.22,0,0,1-.63.71l-6.57,6.74A1.66,1.66,0,0,1,13,31Z"/>
    <path d="M21.74,46.27a1.36,1.36,0,0,1-.46,1c-2.36,2.41-4.71,4.83-7.08,7.23a1.28,1.28,0,0,1-2.13,0C11,53.42,10,52.32,9,51.2a1.28,1.28,0,0,1-.07-1.91,1.31,1.31,0,0,1,1.91.14c.6.61,1.21,1.21,1.75,1.86s.69.59,1.2,0c1.82-1.93,3.7-3.81,5.55-5.71A1.59,1.59,0,0,1,20.45,45,1.26,1.26,0,0,1,21.74,46.27Z"/>
    <path d="M18.74,62.07a3.62,3.62,0,1,1-3.64-3.63A3.62,3.62,0,0,1,18.74,62.07Z"/>
    <path d="M33.9,60.51c1.72,0,3.45,0,5.18,0,.57,0,.75.17.6.76-.57,2.37-.55,2.37-3,2.37H28.89a1.91,1.91,0,0,1-1.42-.42,1.47,1.47,0,0,1-.4-1.67,1.51,1.51,0,0,1,1.56-1c1.76,0,3.51,0,5.27,0Z"/>
    <path d="M42,61l5.4,5.34c0,.06-.12.07-.21.09L41.06,68c-.69.18-.91,0-.73-.71C40.88,65.19,41.46,63.07,42,61Z"/>
    <path d="M65.8,37.5a1.06,1.06,0,0,1,.77.36c1.3,1.3,2.6,2.59,3.88,3.89a1,1,0,0,1,0,1.67c-1.2,1.32-1.2,1.32-2.46.06s-2.58-2.6-3.89-3.88c-.35-.34-.4-.57,0-.88C64.66,38.29,65,37.55,65.8,37.5Z"/>
  `  
}