export default {
  mount: '.icon-bar_top_2021',
  attrs: {
  viewBox: '0 0 109.4 109.4' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="54.7" cy="54.7" r="54.7"/>
    <path fill="#38b09c" d="M87.6,11c6.9,9.1,11,20.5,11,32.8C98.5,74,74,98.5,43.8,98.5c-12.3,0-23.7-4.1-32.8-11
		c10,13.3,25.9,21.9,43.8,21.9c30.2,0,54.7-24.5,54.7-54.7C109.4,36.8,100.9,20.9,87.6,11z"/>
    <path fill="#fff" d="M39.7,44.9c0-1.9-1.2-3.1-3.1-3.1H18.2c-1.9,0-3.1,1.2-3.1,3.1c0,1.8,1.2,3,3.1,3h6.2v24.8
			c0,1.9,1.2,3.1,3,3.1c1.8,0,3-1.2,3-3.1V47.9h6.2C38.5,47.9,39.7,46.7,39.7,44.9z"/>
    <path fill="#fff" d="M54.7,41.8c-4.6,0-8.7,1.7-12,5c-3.3,3.3-5,7.3-5,12c0,4.7,1.7,8.7,5,12c3.3,3.3,7.3,5,12,5
			c4.6,0,8.7-1.7,12-5c3.3-3.3,5-7.3,5-12c0-4.6-1.7-8.7-5-12C63.4,43.5,59.4,41.8,54.7,41.8z M54.7,69.7c-3,0-5.5-1-7.7-3.2
			c-2.1-2.1-3.2-4.7-3.2-7.7c0-3,1-5.5,3.2-7.7c2.1-2.1,4.7-3.2,7.7-3.2c3,0,5.5,1,7.7,3.2c2.1,2.1,3.2,4.7,3.2,7.7
			c0,3-1,5.5-3.2,7.7C60.3,68.6,57.8,69.7,54.7,69.7z"/>
    <path fill="#fff" d="M91.2,45c-2.1-2.1-4.7-3.2-7.6-3.2h-7.7c-0.8,0-1.6,0.3-2.1,0.9c-0.6,0.6-0.9,1.3-0.9,2.1v27.8
			c0,1.9,1.2,3.1,3,3.1c1.8,0,3-1.2,3-3.1v-9.3c0,0,0,0,0,0h4.6c3,0,5.5-1.1,7.6-3.2c2.1-2.1,3.2-4.7,3.2-7.6
			C94.3,49.6,93.3,47.1,91.2,45z M78.9,47.9h4.7c1.3,0,2.4,0.5,3.3,1.4c0.9,0.9,1.4,2,1.4,3.3c0,1.3-0.5,2.4-1.4,3.3
			c-0.9,0.9-2,1.4-3.3,1.4h-4.6c0,0,0,0,0,0V47.9z"/>
  `  
}