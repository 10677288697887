export default {
  mount: '.icon-step-4',
  attrs: {
  viewBox: '0 0 84.34 67.63' ,
  fill: 'currentColor'
  },
  path: `
    <path d="M22.84,43.21c.1-7.29,2-13.7,6.47-19.33,7.35-9.27,28.14-12,35.91,3.83a.83.83,0,0,1,.09.16c.93,4,3.52,5,7.38,4.56a57.76,57.76,0,0,1,8.82.08c2.32.09,3,1,2.8,3.3-1.16,12.36-8.09,19.66-21,21.91-2.52.45-5.11.53-7.66.9a2.26,2.26,0,0,0-1.61,1.05c-2.76,7.41-15.39,11-22.84,4.7-4.53-3.85-6.77-8.94-7.82-14.61A64.18,64.18,0,0,1,22.84,43.21Zm33,11c5.5-.12,11.26-1.07,16.28-4.26,2.19-1.39,3.77-3.76,5.55-5.76.72-.79.53-1.45-.39-2.25a21.53,21.53,0,0,1-3.54-4.13,2,2,0,0,0-2-1.15c-1.59.08-3.19-.05-4.78,0a3.1,3.1,0,0,1-3.47-2.28,47.88,47.88,0,0,0-3.36-6.72A13.75,13.75,0,0,0,53.59,22c-8.23-3.46-18.48-.33-22.81,7.45-5,8.91-5.09,18.14-.32,27.24A14.64,14.64,0,0,0,34.1,61a11,11,0,0,0,12.61.66c3.85-2.31,4.69-6,4-10.07a61,61,0,0,0-2.42-8.41,2.12,2.12,0,0,1,.88-2.84,2,2,0,0,1,2.87.77,5.7,5.7,0,0,1,.77,1.66C53.81,46.46,54.78,50.16,55.85,54.22Z"/>
    <path d="M19.69,5.1c0,1.6,0,3.18,0,4.76a2.05,2.05,0,0,1-2.07,2.32A5.65,5.65,0,0,0,12.46,16a9.74,9.74,0,0,1-7.32,6.28c.41,3.86,4.4,7.49,8.75,7.86A25.92,25.92,0,0,0,18.46,30c1.62-.14,2.78.47,2.88,1.8s-.73,2.19-2.13,2.48C9.59,36.27,1.64,30.8.07,21.06c-.33-2,.52-3.08,2.57-3a5.35,5.35,0,0,0,5.68-3.47,10.93,10.93,0,0,1,6.15-6.23A1.81,1.81,0,0,0,15.26,7c.08-1.46-.09-2.94,0-4.41C15.38.46,16.5-.35,18.58.13a35.06,35.06,0,0,1,18.2,10.48c1.24,1.34,1.35,2.61.37,3.55s-2.24.77-3.49-.57a31.19,31.19,0,0,0-11.77-8c-.51-.2-1-.36-1.57-.51A2.52,2.52,0,0,0,19.69,5.1Z"/>
    <path d="M18.37,16.46c1.92.26,2.75,1,2.7,2.36a2,2,0,0,1-2.15,2c-1.34,0-2.31-.9-2.06-2.22C17,17.76,18,17,18.37,16.46Z"/>
    <path d="M59.59,36.67c-.79.57-1.51,1.47-2.38,1.63-1.31.25-2.2-.67-2.2-2s.86-2.29,2.19-2c.86.16,1.59,1,2.38,1.61Z"/>
  `  
}