export default {
    mount: '.icon-cat-type',
    attrs: {
        viewBox: '0 0 64.02 56.93',
        fill: 'currentColor'
    },
    path: `
    <path d="M16.31,1.21a50.12,50.12,0,0,0,.94,6.47,43.39,43.39,0,0,0-6.55,2.59c-1.23.63-2.26,1.2-3.16,1.74A37.08,37.08,0,0,1,3.8,4a44.06,44.06,0,0,1,8.4,3.39,1.13,1.13,0,0,0,1.39-1.79c-1.4-1.09-8-3.65-10.94-4.22A1.13,1.13,0,0,0,1.3,2.44c-.05,3.05,3,8.5,4.36,10.83-3.13,2.39-3.29,4.62-3.34,10.08,0,1.23,0,2.64-.08,4.28A4.67,4.67,0,0,1,1,30.8c-.18.25-.37.49-.54.75-1,1.55-.31,3.86.92,5.35C2.78,38.55,6.5,42,8.91,42.6a23.88,23.88,0,0,0,6.76.16A32.85,32.85,0,0,1,14.5,50.3a1.14,1.14,0,0,0,.7,1.44.87.87,0,0,0,.35.07,1.13,1.13,0,0,0,1.09-.76A36.3,36.3,0,0,0,18,42.5c.77-.11,1.5-.23,2.17-.35a1.13,1.13,0,0,0-.41-2.23c-3.42.63-7.93,1-10.28.47-1.44-.34-4.7-3-6.27-4.93-.81-1-1.08-2.21-.78-2.65a7.26,7.26,0,0,1,.46-.63A7,7,0,0,0,4.5,27.71c.06-1.67.07-3.1.08-4.34.06-5.25.07-6.8,3.05-8.79a.75.75,0,0,0,.13-.06l.08-.07c1-.63,2.24-1.31,3.9-2.17a41.53,41.53,0,0,1,6-2.4,17.75,17.75,0,0,0,2.67,6,1.14,1.14,0,0,0,1.79-1.4,16.94,16.94,0,0,1-2.53-6.16.68.68,0,0,0,0-.14l0-.08a51.45,51.45,0,0,1-1-5.74c2.93.41,10.24,2.28,15.05,11a1.12,1.12,0,0,0,.28.33,25.6,25.6,0,0,1,8.83,15,1.13,1.13,0,0,0,2.22-.44,27.85,27.85,0,0,0-9.45-16.14C29,.19,17.89,0,17.44,0a1.15,1.15,0,0,0-.83.36A1.16,1.16,0,0,0,16.31,1.21Z"/>
    <path d="M42.45,55.23a2,2,0,0,0,1.33-1.46,2,2,0,0,0-.52-1.88L40.43,49l1.75-1.7,4.19,4.33a.72.72,0,0,0,.53.23.77.77,0,0,0,.53-.21.76.76,0,0,0,0-1.07l-4.19-4.32,1.62-1.57L49.07,49a.75.75,0,0,0,.53.23.71.71,0,0,0,.54-.21.75.75,0,0,0,0-1.06L46,43.61l1.55-1.51,3.14,3.25a.8.8,0,0,0,.53.23.76.76,0,0,0,.54-.22.75.75,0,0,0,0-1.06L48.6,41.06l1.63-1.57,2.09,2.16a.75.75,0,1,0,1.08-1l-2.09-2.16,1.23-1.19a2.43,2.43,0,0,0,1.15.33h.12c3.48-.12,8-.46,9.8-1.4a.75.75,0,0,0,0-1.35,40,40,0,0,1-6.2-3.23,39.29,39.29,0,0,1-3-6.3.77.77,0,0,0-.65-.47.74.74,0,0,0-.71.38C52,27,51.51,31.49,51.28,35a2.44,2.44,0,0,0,.23,1.19l-1.25,1.21L48.17,35.2a.75.75,0,1,0-1.08,1l2.09,2.17L47.56,40l-3.14-3.25a.76.76,0,0,0-1.07,0,.77.77,0,0,0,0,1.07L46.48,41l-1.56,1.51L40.73,38.2a.75.75,0,0,0-1.06,0,.75.75,0,0,0,0,1.06l4.19,4.33-1.62,1.57L38,40.82a.75.75,0,0,0-1.06,0,.76.76,0,0,0,0,1.07l4.18,4.32-1.75,1.7L36.55,45a2,2,0,0,0-3.36.7c-.33.88-3.22,8.6-1.33,10.56a3.46,3.46,0,0,0,2.39.71C37.28,57,41.79,55.46,42.45,55.23ZM53.71,27.68a25.21,25.21,0,0,0,2.58,5,25.11,25.11,0,0,0,4.88,2.74,48,48,0,0,1-7.41.69.91.91,0,0,1-.71-.29,1,1,0,0,1-.27-.72A49.53,49.53,0,0,1,53.71,27.68ZM32.94,55.17c-.53-.55-.16-4.23,1.66-9a.54.54,0,0,1,.39-.34.5.5,0,0,1,.48.15l6.71,6.93a.51.51,0,0,1,.13.48.52.52,0,0,1-.35.39C37.17,55.47,33.48,55.72,32.94,55.17Z"/><rect class="cls-1" x="35.15" y="51.41" width="1.5" height="1.5" transform="translate(-16.84 87.2) rotate(-89.06)"/>
    `
}