export default {
    mount: '.icon-dog-house',
    attrs: {
        viewBox: '0 0 75.98 77.87',
    },
    path: `
    <polygon fill="#231f20" opacity="0.2" points="69.68 75.77 11.7 77.88 16.67 71.71 64.52 70.5 69.68 75.77"/>
    <polygon fill="#ee5b34" points="71.42 34.05 3.39 36.54 0 28.72 35.47 0 75.98 25.84 71.42 34.05"/>
    <polygon fill="#c93c21" points="71.45 33.98 35.61 8.4 4.31 36.51 71.42 34.05 71.45 33.98"/>
    <polygon fill="#fff" points="11.38 46.26 65.37 43.63 65.99 34.71 9.57 37.46 11.38 46.26"/>
    <polygon fill="#fff" points="13.47 56.5 64.65 54.01 65.27 45.09 11.67 47.7 13.47 56.5"/>
    <polygon fill="#fff" points="17.28 75.06 63.34 72.82 63.83 65.85 15.87 68.18 17.28 75.06"/>
    <polygon fill="#fff" points="15.57 66.74 63.93 64.39 64.55 55.47 13.77 57.94 15.57 66.74"/>
    <polygon fill="#fff" points="8.9 34.17 9.28 36.02 66.09 33.25 66.15 32.35 55.78 24.87 17.65 26.73 8.9 34.17"/>
    <polygon fill="#fff" points="36.34 10.83 19.46 25.18 53.9 23.5 36.34 10.83"/>
    <polygon fill="#d5d5d5" points="11.67 47.7 65.27 45.09 65.37 43.63 11.38 46.26 11.67 47.7"/>
    <polygon fill="#d5d5d5" points="9.57 37.46 65.99 34.71 66.09 33.25 9.28 36.02 9.57 37.46"/>
    <polygon fill="#d5d5d5" points="19.46 25.18 17.65 26.73 55.78 24.87 53.9 23.5 19.46 25.18"/>
    <polygon fill="#d5d5d5" points="13.77 57.94 64.55 55.47 64.65 54.01 13.47 56.5 13.77 57.94"/>
    <polygon fill="#d5d5d5" points="15.87 68.18 63.83 65.85 63.93 64.39 15.57 66.74 15.87 68.18"/>
    <path fill="#45484c" d="M26,74.64l-5.6-34.43S35,14.32,56,38.71L54.32,73.26Z"/>
    <path fill="#231f20" d="M24.32,42.56l4.14,32,23.13-1.13.71-32C35.78,22.25,24.32,42.56,24.32,42.56Z"/>
    `
}