export default {
  mount: '.icon-functional-powder',
  attrs: {
  viewBox: '0 0 139.79 139.79' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="69.9" cy="69.9" r="69.9"/>
    <path fill="#ffffff" d="M52,69.24a.7.7,0,1,0,0,1.4.7.7,0,1,0,0-1.4Z"/>
    <path fill="#ffffff" d="M114.29,66.39c-3.7-2.85-11.1-3.67-18.6,1.26C86,74.05,77,80.82,72.3,84.47H36a2.6,2.6,0,0,0-2.16,1.15A2.57,2.57,0,0,0,33.56,88c2,4.93,8.29,16.38,23,16.38,10.57,0,16.79-6,20.15-11A42.6,42.6,0,0,1,98.18,76.71a33.6,33.6,0,0,1,10.37-1.93c5.8,0,7.38-2.18,7.81-3.44C116.92,69.67,116.14,67.82,114.29,66.39Zm-.57,4.06c-.19.56-1.49,1.52-5.19,1.55a36.13,36.13,0,0,0-11.24,2.08A45.28,45.28,0,0,0,74.42,91.86c-3,4.46-8.51,9.79-17.85,9.79-12.79,0-18.42-9.86-20.34-14.4h37l.38-.3c4.55-3.54,13.68-10.46,23.59-17,7.32-4.82,13.26-3,15.37-1.38C113.43,69.23,113.88,70,113.72,70.45Z"/>
    <circle fill="#ffffff" cx="44.16" cy="64.1" r="1.81"/>
    <path fill="#ffffff" d="M49.16,56.37a2.2,2.2,0,1,0,2.2-2.19A2.2,2.2,0,0,0,49.16,56.37Z"/>
    <path fill="#ffffff" d="M39.91,39.76a2.2,2.2,0,1,0-2.2-2.2A2.19,2.19,0,0,0,39.91,39.76Z"/>
    <path fill="#ffffff" d="M64.49,63.87a1.58,1.58,0,1,0-1.58-1.58A1.57,1.57,0,0,0,64.49,63.87Z"/>
    <path fill="#ffffff" d="M69.26,55A2.24,2.24,0,1,0,67,52.75,2.24,2.24,0,0,0,69.26,55Z"/>
    <circle fill="#ffffff" cx="60.39" cy="43.68" r="1.96"/>
    <path fill="#ffffff" d="M70.21,42.59a1.86,1.86,0,1,0-1.86-1.86A1.86,1.86,0,0,0,70.21,42.59Z"/>
    <circle fill="#ffffff" cx="60.39" cy="55.42" r="1.81"/>
    <path fill="#ffffff" d="M40.54,49.6a1.81,1.81,0,1,0,1.81-1.81A1.81,1.81,0,0,0,40.54,49.6Z"/>
    <path fill="#ffffff" d="M48.7,44.45a1.86,1.86,0,1,0-1.86-1.86A1.87,1.87,0,0,0,48.7,44.45Z"/>
    <path fill="#ffffff" d="M58.64,68.26a5.66,5.66,0,0,0-8.09,0L37.25,83.74H71.94Zm-14.48,14a.81.81,0,1,1,.82-.81A.81.81,0,0,1,44.16,82.28Zm4.54-9.52a.94.94,0,1,1-.94.93A.93.93,0,0,1,48.7,72.76Zm.93,7.07a1.72,1.72,0,1,1,1.73-1.72A1.72,1.72,0,0,1,49.63,79.83ZM52,70.64a.7.7,0,1,1,.7-.7A.7.7,0,0,1,52,70.64Zm.7,2.43a1.15,1.15,0,1,1,1.14,1.15A1.14,1.14,0,0,1,52.66,73.07ZM55.38,82a1.34,1.34,0,1,1,1.33-1.33A1.33,1.33,0,0,1,55.38,82Z"/>
  `  
}