export default {
  mount: '.icon-handmade-snacks',
  attrs: {
  viewBox: '0 0 139.79 139.79' ,
  fill: 'currentColor'
  },
  path: `
    <circle cx="69.9" cy="69.9" r="69.9"/>
    <path fill="#ffffff" d="M75.23,81.27c0-3.53-1.74-6.44-4.53-7.6S65,73.26,62.33,75.5c-2.65-2.24-5.72-2.93-8.38-1.83s-4.53,4.07-4.53,7.6c0,6.85,6.49,12.56,12.51,15a1.06,1.06,0,0,0,.4.08,1,1,0,0,0,.39-.08C68.74,93.83,75.23,88.12,75.23,81.27ZM62.33,94.08c-5.41-2.3-10.76-7.36-10.76-12.81,0-2.64,1.23-4.79,3.21-5.61a4.66,4.66,0,0,1,1.77-.35,7.33,7.33,0,0,1,5,2.42,1.07,1.07,0,0,0,1.52,0c2.16-2.17,4.69-2.94,6.78-2.07s3.21,3,3.21,5.61C73.08,86.72,67.73,91.78,62.33,94.08Z"/>
    <path fill="#ffffff" d="M80.4,105.17H39.7V89.41a1.32,1.32,0,0,0-2.63,0v17.07a1.31,1.31,0,0,0,1.31,1.31h42a1.31,1.31,0,0,0,0-2.62Z"/>
    <path fill="#ffffff" d="M94.84,39.51h2.63a5.25,5.25,0,1,0,0-10.5h-46A6.56,6.56,0,0,0,45,35.57v.64A17.4,17.4,0,0,1,43,44a56.9,56.9,0,0,0-6,25.28V84.16a1.32,1.32,0,0,0,2.63,0V69.27a54.18,54.18,0,0,1,5.68-24.1,20,20,0,0,0,2.19-9v-.64a4,4,0,0,1,3.94-3.94H92.92a5.28,5.28,0,0,0-.7,2.63v6.23a56.46,56.46,0,0,0-7.88,28.78v37.21a1.31,1.31,0,0,0,1.31,1.31h15.76a1.31,1.31,0,0,0,1.31-1.31V82.84a1.31,1.31,0,0,0-2.62,0v20.09l-5.26-6.12V90.72a1.31,1.31,0,0,0-2.62,0V96.8L87,102.93V69.27a53.91,53.91,0,0,1,5.25-23.19V59.21a1.31,1.31,0,1,0,2.62,0V46.08a54,54,0,0,1,5.26,23.19v8.32a1.31,1.31,0,0,0,2.62,0V69.27a56.46,56.46,0,0,0-7.88-28.78Zm-1.31,59.8,5,5.86h-10Zm3.94-67.68a2.63,2.63,0,0,1,0,5.26H94.84V34.26A2.63,2.63,0,0,1,97.47,31.63Z"/>
    <path fill="#ffffff" d="M93.53,65.77a1.32,1.32,0,0,0-1.31,1.32V82.84a1.31,1.31,0,1,0,2.62,0V67.09A1.31,1.31,0,0,0,93.53,65.77Z"/>
    <path fill="#ffffff" d="M51.58,44.35,49,52.23a1.31,1.31,0,0,0,.84,1.66,1.41,1.41,0,0,0,.41.07H77.77a1.33,1.33,0,0,0,1.25-.9l2.63-7.88a1.32,1.32,0,0,0-.84-1.66,1.14,1.14,0,0,0-.41-.07H52.83A1.31,1.31,0,0,0,51.58,44.35Zm27,1.73-1.75,5.25H52l1.75-5.25Z"/>
    <path fill="#ffffff" d="M55.45,56.58a1.32,1.32,0,0,0,0,2.63H71.21a1.32,1.32,0,0,0,0-2.63Z"/>
    <path fill="#ffffff" d="M75.15,40.83a1.32,1.32,0,0,0,0-2.63H59.39a1.32,1.32,0,0,0,0,2.63Z"/>
  `  
}