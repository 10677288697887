export default {
  mount: '.icon-member-order-pet',
  attrs: {
  viewBox: '0 0 76.54 76.54' ,
  fill: 'currentColor'
  },
  path: `
    <path d="M23,24.72h0s0,.05,0,.09c0,1.28,0,2.59,0,3.87,0,1,0,2.09,0,3.14a2.27,2.27,0,0,0,.57,1.66,2.25,2.25,0,0,0,1.68.59c8.35,0,17.16,0,26.19,0h0a2.3,2.3,0,0,0,1.71-.61,2.33,2.33,0,0,0,.58-1.71c0-1,0-2,0-3,0-1.16,0-2.48,0-3.77,0-.09,0-.15,0-.19l.2-.1a9.75,9.75,0,0,0,5.75-9.49,9.81,9.81,0,0,0-6.89-9A9.54,9.54,0,0,0,47,6.11a9.93,9.93,0,0,0-8.51-4.92h-.14a10.09,10.09,0,0,0-8.71,4.92,9.07,9.07,0,0,0-4.8-.18A9.77,9.77,0,0,0,17,15.15,9.54,9.54,0,0,0,23,24.72Zm28,4c0,.92,0,1.83,0,2.74l-13.14,0H25.55c0-.92,0-1.83,0-2.77,0-1.25,0-2.53,0-3.8A2.47,2.47,0,0,0,24,22.28a6.91,6.91,0,0,1-4.36-6.92A7.17,7.17,0,0,1,25.4,8.5c1.31-.31,2.32-.21,4.48.43L31,9.25l.48-1a7.51,7.51,0,0,1,6.85-4.4h.1a7.33,7.33,0,0,1,6.7,4.45l.53,1.22L46.86,9a6.92,6.92,0,0,1,5-.27,7.13,7.13,0,0,1,5,6.64,7.1,7.1,0,0,1-4.2,6.91A2.66,2.66,0,0,0,51,25C51,26.24,51,27.49,51,28.7Z"/>
    <path d="M57.59,46.89A9.15,9.15,0,0,0,50,43.48a11,11,0,0,0-9.12,5.38,10.37,10.37,0,0,0-.73,9.51c-.63.65-1.28,1.29-1.87,1.86l-.06-.06-1.33-1.25c.18-.17.38-.36.58-.56a4.49,4.49,0,0,0,0-6.7c-2.37-2.4-4.56-4.59-6.7-6.7A4.48,4.48,0,0,0,24,45c-1.87,1.85-3.74,3.72-5.56,5.56a4.53,4.53,0,0,0,0,6.84c2.07,2.1,4.24,4.28,6.63,6.64a4.6,4.6,0,0,0,6.73,0l.41-.42,1.18,1.06-1.66,1.46c-.95.83-1.93,1.69-2.89,2.55a3,3,0,0,0-1.12,2.1,3,3,0,0,0,1,2.22c.43.45.93,1,1.46,1.47a3,3,0,0,0,2.17.89,3,3,0,0,0,2.09-1.07c1.42-1.57,2.69-3,3.88-4.35l.62.69c1.13,1.27,2.19,2.45,3.25,3.62a3,3,0,0,0,2.1,1.11h.09a3.09,3.09,0,0,0,2.12-1c.4-.38.82-.8,1.31-1.31a3.08,3.08,0,0,0,1-2.25,3.13,3.13,0,0,0-1.2-2.2c-1.84-1.66-3.25-2.92-4.59-4.1.62-.58,1.15-1.07,1.69-1.53a10.27,10.27,0,0,0,8.46-.17,11.19,11.19,0,0,0,6.13-7.29A9.71,9.71,0,0,0,57.59,46.89ZM43.27,60.72c-.75.63-1.46,1.3-2.29,2.07l-2.21,2,1.4.93.53.34.14.09a3,3,0,0,1,.42.29c1.34,1.18,2.75,2.44,4.57,4.09a2.34,2.34,0,0,1,.31.33,1.9,1.9,0,0,1-.27.34c-.46.48-.85.87-1.23,1.23a1.66,1.66,0,0,1-.29.23,1.38,1.38,0,0,1-.25-.23C43,71.28,42,70.09,40.83,68.81l-2.94-3.29L37,67.12l-.21.4a2.63,2.63,0,0,1-.19.34c-1.3,1.48-2.65,3-4.14,4.64a.86.86,0,0,1-.23.19.84.84,0,0,1-.28-.18c-.48-.44-.93-.91-1.34-1.34a1.62,1.62,0,0,1-.23-.3,1.37,1.37,0,0,1,.23-.25c1-.9,2-1.79,3-2.68l1.57-1.38.66-.61,1.45-1.33L32,59.85,30.8,61.2l0,0c-.24.28-.52.61-.82.91a2,2,0,0,1-3,0c-2.27-2.24-4.49-4.47-6.6-6.61a1.94,1.94,0,0,1,0-3.14c1.79-1.81,3.65-3.67,5.54-5.54a1.88,1.88,0,0,1,3.06,0c2.15,2.11,4.33,4.3,6.67,6.67a1.88,1.88,0,0,1,0,3c-.28.29-.58.56-.92.87L33,58.93l1.78,1.67,1.63,1.52.64.57,1.32,1.16L40,62.23c.84-.83,1.64-1.61,2.43-2.43a2.1,2.1,0,0,0,.24-2.23,7.67,7.67,0,0,1,.45-7.35,8.41,8.41,0,0,1,6.94-4.11,6.48,6.48,0,0,1,5.46,2.42,7.1,7.1,0,0,1,1.21,6.3A8.67,8.67,0,0,1,52,60.41a7.5,7.5,0,0,1-6.47.06A2.3,2.3,0,0,0,43.27,60.72Z"/>
    <path d="M53.16,35.67a2.38,2.38,0,0,0-1.93-.7c-3.09,0-6.1,0-9.43,0H39.71v-.07H34.57c-2.67,0-5.44,0-8.15,0h-.28c-1.18,0-2,0-2.6.6s-.64,1.46-.62,2.8c0,2.91,1.63,4.51,4.49,4.51H49.24a4.08,4.08,0,0,0,4.4-4.43c0-.13,0-.26,0-.39v-.06a2,2,0,0,1,0-.33A2.37,2.37,0,0,0,53.16,35.67Z"/>
    <path d="M23.59,53.14a1,1,0,0,0-1.44,0,1,1,0,0,0,0,1.44l6,6a1,1,0,1,0,1.44-1.44Z"/>
    <path d="M26,50.75a1,1,0,0,0-1.44,0,1,1,0,0,0,0,1.44l6,6A1,1,0,0,0,32,56.72Z"/>
    <path d="M34.34,55.77a1,1,0,0,0,0-1.44l-6-6a1,1,0,0,0-1.44,0,1,1,0,0,0,0,1.44l6,6a1,1,0,0,0,1.44,0Z"/>
    <path d="M48.3,30.31H28.23a1.1,1.1,0,0,1,0-2.2H48.3a1.1,1.1,0,0,1,0,2.2Z"/>
  `  
}