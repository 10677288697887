export default {
    mount: '.icon-share',
    attrs: {
        viewBox: '0 0 42.4 42.4',
        fill: 'currentColor'
    },
    path: `
    <circle cx="21.2" cy="21.2" r="21.2"/>
    <path fill="#fff" d="M28.07,24.27a5.16,5.16,0,0,0-4.3,2.3l-7.55-3.79a5.19,5.19,0,0,0,.14-1.2,5.32,5.32,0,0,0-.14-1.2l7.55-3.78A5.2,5.2,0,1,0,23,14.92l-7.55,3.79a5.17,5.17,0,1,0,0,5.74L23,28.24a5.32,5.32,0,0,0-.14,1.2,5.17,5.17,0,1,0,5.17-5.17Zm0-13.89a3.35,3.35,0,1,1-3.35,3.35A3.35,3.35,0,0,1,28.07,10.38ZM11.19,24.93a3.35,3.35,0,1,1,3.35-3.35A3.35,3.35,0,0,1,11.19,24.93Zm16.88,7.86a3.35,3.35,0,1,1,3.34-3.35A3.35,3.35,0,0,1,28.07,32.79Z"/>
    `
}