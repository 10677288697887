export default {
    mount: '.icon-btn-plus',
    attrs: {
        viewBox: '0 0 20 20',
        fill: 'currentColor',
    },
    path: `
        <rect y="8" width="20" height="5"/>
        <rect x="8" width="5" height="20"/>
    `
}