export default {
  mount: '.icon-dog',
  attrs: {
  viewBox: '0 0 88 88' ,
  fill: 'currentColor'
  },
  path: `
    <path d="M29.63,72.28a5.71,5.71,0,0,1-4.06,5.81A5.14,5.14,0,0,1,19.7,75.8a37.31,37.31,0,0,1-2.18-4.61,1.84,1.84,0,0,1,1.06-2.59,1.82,1.82,0,0,1,2.5,1c.62,1.16,1,2.46,1.73,3.55.36.55,1.24.77,1.88,1.14a5.76,5.76,0,0,0,.7-2.08c-.08-1.19-.73-2.36-.69-3.53a2.62,2.62,0,0,1,1.39-2,2.48,2.48,0,0,1,2.21,1A31.2,31.2,0,0,1,29.63,72.28Z"/>
    <path d="M80.9,32.72H74.4c-2.09,0-2.63-.49-2.83-2.54a6.4,6.4,0,0,0-6.47-6c-2.91-.07-5.83,0-8.74,0-2.41,0-3.17-.6-2.94-3,.66-7.06-3.93-10.67-11.32-9.17a12.26,12.26,0,0,0-9.64,10.28h5.22c2.59,0,5.17,0,7.76,0,1.61,0,2.49.76,2.42,2-.09,1.47-1,1.93-2.36,1.92-5,0-10,0-15,0-1.76,0-2.45-.8-2.38-2.6A16.81,16.81,0,0,1,48.64,8a11.18,11.18,0,0,1,8.46,9.48c.1.82.14,1.64.23,2.69,2.5,0,5,0,7.51,0a10.15,10.15,0,0,1,8.21,3.86,35.91,35.91,0,0,1,2.68,4.71c1.84,0,4.09,0,6.33,0,2.07,0,2.69.57,2.62,2.65a47.76,47.76,0,0,1-.51,7.38c-.85,4.37-3.67,6.75-8.13,7.09-2.41.18-4.83.06-7.25.08-.77,0-1.54,0-2.31,0-3.37.13-5.48,1.81-6.54,5A45.43,45.43,0,0,0,58,64.52c0,2.64,0,5.28.08,7.92a5.38,5.38,0,0,1-10.62,1.63c-2.33-8-8.09-12.18-16.4-12.32C23.6,61.63,17.42,64,12.65,69.89A5.07,5.07,0,0,1,6.06,71a5,5,0,0,1-2.32-6.39C6.43,59,9.37,53.42,12.22,47.86a4.52,4.52,0,0,1,.93-1.16,1.83,1.83,0,0,1,2.66-.18,1.92,1.92,0,0,1,.33,2.66C13.31,54.45,10.48,59.72,7.72,65a2.7,2.7,0,0,0-.2,2c.45,1.09,1.6,1,2.63-.23A24.65,24.65,0,0,1,19,60a26.9,26.9,0,0,1,18.07-1.46,19,19,0,0,1,14.1,13.89c.26.92.49,2,1.73,1.79S54.19,73,54.16,72c-.22-6.44-.26-12.87,1.21-19.2.06-.27.13-.53.2-.8,2-7.39,5.18-9.89,12.81-10,2.26,0,4.51.07,6.76,0,3.51-.14,5-1.44,5.51-4.91A43.29,43.29,0,0,0,80.9,32.72Z"/>
    <path d="M29.86,44c-3.08,0-6.16.07-9.23,0-5.89-.16-10.13-2.89-12.54-8.31-1.22-2.76-2.36-5.56-3.5-8.35-.59-1.44-.22-2.63,1-3,1.38-.44,2.16.28,2.66,1.51,1.1,2.7,2.22,5.38,3.36,8.06A9.39,9.39,0,0,0,20.84,40Q29.92,40,39,40a10,10,0,0,0,10.08-8.8c.25-1.71,1.08-2.58,2.29-2.4S53.2,30,53,31.72A13.89,13.89,0,0,1,39.59,43.94c-3.24.09-6.49,0-9.73,0Z"/>
    <path d="M71.12,65.77a5.4,5.4,0,0,1-4.41-2.19,1.9,1.9,0,0,1,0-2.81,1.83,1.83,0,0,1,2.89.12c.71.75,1.57,1.32,2.4.5a2.31,2.31,0,0,0,.48-2.08,7.49,7.49,0,0,0-6.43-4.73c-1.81-.18-2.58-.87-2.48-2.16s1.16-2,3-1.77a11.76,11.76,0,0,1,9.81,7.57A5.52,5.52,0,0,1,71.12,65.77Z"/>
    <path d="M65,26.76c1.27.21,2,.89,1.94,2.11A1.95,1.95,0,0,1,63,28.72,2,2,0,0,1,65,26.76Z"/>
  `  
}